import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const ApplicationServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Application Services</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>Application Services</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={8} md={12}>
                     <h2 className="mb-3">Performance, Functionality, and Security</h2>
                     <p className="lead"><em>We are a team of expert application service providers who help you optimize, deploy, and manage your applications with speed and agility.</em></p>          
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-5">
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                      <h2 className="mb-3">What we do</h2>
                      <p className="lead"><em>We offer a range of application services to help you ensure the performance, functionality, and security of your applications. Whether you need load balancing, application performance monitoring, application acceleration, autoscaling, microsegmentation, service proxy or service discovery, we can help you achieve your goals.</em></p>
                      <p className="lead"><em>Our application services include:</em></p>                      
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={4} md={6}>
                      <div className="nos">01</div>
                      <h3 className="mb-3">Load Balancing</h3>
                      <p>We distribute the traffic across multiple servers or instances to improve the availability, scalability, and responsiveness of your applications. We use intelligent algorithms and policies to balance the load based on various factors such as server capacity, network latency, and user location.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">02</div>
                      <h3 className="mb-3">App Performance</h3>
                      <p>We monitor and measure the performance of your applications from end to end. We collect and analyze various metrics such as response time, throughput, error rate, and availability. We provide real-time visibility and alerts into the health and performance of your applications.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">03</div>
                      <h3 className="mb-3">Acceleration</h3>
                      <p>We optimize the delivery of your applications to enhance the user experience and reduce the bandwidth consumption. We use various techniques such as caching, compression, content optimization, and protocol optimization to accelerate your applications.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">04</div>
                      <h3 className="mb-3">Autoscaling</h3>
                      <p>We automatically adjust the number of servers or instances based on the demand and capacity of your applications. We use predefined rules and triggers to scale up or down your applications as needed. We help you save costs and resources by scaling your applications dynamically.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">05</div>
                      <h3 className="mb-3">Microsegmentation</h3>
                      <p>We isolate and secure your applications at a granular level. We use network policies and firewalls to control the traffic flow between different segments or zones of your applications. We help you prevent unauthorized access and protect your applications from cyberattacks.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">06</div>
                      <h3 className="mb-3">Service Proxy</h3>
                      <p>We act as an intermediary between your applications and external services or APIs. We handle the communication, routing, authentication, encryption, and logging of your requests and responses. We help you simplify and secure your interactions with external services or APIs.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-5">
                      <h2 className="mb-3">Why choose us</h2>
                      <p className="lead"><em>We are not just service providers, we are solution partners. We care about your applications and your business. We strive to provide application services that are:</em></p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/testing.svg" alt="testing" />
                      </div>
                      <h3 className="mb-3">Comprehensive</h3>
                      <p>We cover all aspects of software testing from functional to non-functional, from manual to automated, from unit to integration to system testing. We ensure that your software product or project is thoroughly tested and validated.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/money-bag.svg" alt="money-bag" />
                      </div>
                      <h3 className="mb-3">Cost-Effective</h3>
                      <p>We use the best practices and tools to optimize the testing process and reduce the cost and risk of software defects. We help you save time and money by delivering high-quality software products and projects.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/flexibility.svg" alt="flexibility" />
                      </div>
                      <h3 className="mb-3">Flexible</h3>
                      <p>We adapt to your needs and preferences. We can work with any software development methodology, technology stack, or domain. We can work on-site or remotely, as part of your team or independently.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/website.svg" alt="website" />
                      </div>
                      <h3 className="mb-3">Experienced</h3>
                      <p>We have a team of skilled and certified software testers who have extensive experience in various types of software testing across different industries and domains. We have successfully completed many software testing projects for clients worldwide.</p>          
                    </Col>
                </Row>
                
                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for a skilled and certified application service provider who can help you achieve your business goals with custom software solutions, contact us today. We would love to hear from you and discuss how we can work together.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default ApplicationServices;
