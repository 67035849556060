import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const Insurance = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Insurance</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Industries</li>
                      <li>Insurance</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-4">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Our Solutions for Insurance Industries</h2>
                        <p className="lead"><em>The insurance industry is facing unprecedented challenges and opportunities in the digital era. Insurance companies need to adapt to changing customer expectations, regulatory requirements, and competitive pressures. They also need to harness the power of data, analytics, and technology to innovate their products, processes, and business models.</em></p>
                        <p className="lead"><em>At LIFO, we offer software company solutions for insurance industries that help you achieve your business goals and overcome your challenges. Our solutions cover various aspects of the insurance value chain, such as:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Claims Management</h4>
                      <p>We provide claims management software that helps you process and settle claims faster, cheaper, and more accurately. Our software enables you to automate claims workflows, integrate with external data sources, detect fraud and leakage, and enhance customer satisfaction.</p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Document Management</h4>
                      <p>We develop document management software that helps you store, organize, and access your insurance documents securely and efficiently. Our software allows you to digitize paper documents, manage document versions and revisions, comply with document retention policies, and enable document collaboration and sharing.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Insurance Marketplace</h4>
                      <p>We create insurance marketplace software that helps you offer and sell your insurance products online. Our software allows you to create and customize your own branded website or app, connect with multiple insurance carriers and aggregators, provide online quotes and comparisons, and facilitate online payments and renewals.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">CRM for Agents and Brokers</h4>
                      <p>We design CRM for agents and brokers software that helps you manage your customer relationships and sales activities. Our software enables you to capture and track leads, manage contacts and accounts, schedule appointments and follow-ups, send marketing campaigns and communications, and generate reports and insights.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">05</div>
                      <h4 className="mb-3">Insurance Analytics</h4>
                      <p>We build insurance analytics software that helps you leverage your data to gain insights and make better decisions. Our software enables you to collect and integrate data from various sources, apply advanced analytics techniques such as AI and ML, visualize and explore data using dashboards and charts, and generate actionable recommendations.</p>          
                    </Col>
                    <Col lg={12} md={12} className="mt-5">
                      <p>LIFO Technologies Pvt. Ltd., is a trusted partner for insurance industries across the globe. We have extensive experience and expertise in developing customized solutions that meet the specific needs and challenges of each industry segment, such as life insurance, property and casualty insurance, health insurance, and reinsurance. We use the latest technologies and best practices to ensure quality, reliability, and scalability of our solutions.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for software company solutions for insurance industries that can help you transform your insurance performance and competitiveness contact us today for a free consultation. We would love to hear from you!</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default Insurance;
