import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";

const Experts = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Our Experts</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>About</li>
                      <li>Experts</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={8}>
                     <h2 className="mb-3">Do you have a technical questions or would like to schedule a meeting?</h2>
                     <p className="lead"><em>Our people will gladly share their knowledge.</em></p>
                                
                  </Col>
                  <Col lg={4}>
                    <Button href="/contact-us" variant="primary" className="mt-2 float-end">Reach Out Our Expert</Button>
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-5">
                <Row className="text-light">             
                    <Col lg={12} md={12}>
                        <p>LIFO Technologies Pvt. Ltd., is an international IT consulting and software development company with experience in multiple industries, including healthcare, retail, manufacturing, banking, and telecoms. Key domains of our expertise are IoT, big data, information security, mobile and web development, collaboration and knowledge management, and more.</p>
                        <p>Our experts are always open to sharing their knowledge with reporters, bloggers, influencers, speakers, and everyone else interested in creating expert IT content.</p>                
                    </Col>
                </Row>
                <Row className="text-light">
                    {/* <Col lg={3} md={4}>
                        <div className="team-single mt-4">
                            <div className="team-img">
                                <img src="../assets/images/team/nandakumar.jpg" alt="" className="w-100"/>
                            </div>
                            <h4>Nandhakumar Mohan</h4>
                            <p>Expert in<br/>IT Infrastructure</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="team-single mt-4">
                            <div className="team-img">
                                <img src="../assets/images/team/hamesh.jpg" alt="" className="w-100"/>
                            </div>
                            <h4>Hamesh Noah</h4>
                            <p>Expert in<br/>IT Infrastructure</p>
                        </div>
                    </Col>            */}
                    <Col lg={3} md={4}>
                        <div className="team-single mt-4">
                            <div className="team-img">
                                <img src="../assets/images/team/gokul.jpg" alt="Lifo gokul" className="w-100"/>
                            </div>
                            <h4>Gokulraj R</h4>
                            <p>Expert in<br/>Software Development</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="team-single mt-4">
                            <div className="team-img">
                                <img src="../assets/images/team/manivannan.jpg" alt="lifo manivannan" className="w-100"/>
                            </div>
                            <h4>Manivannan VC</h4>
                            <p>Expert in<br/>Web and Mobile Development</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="team-single mt-4">
                            <div className="team-img">
                                <img src="../assets/images/team/sathish.jpg" alt="lifo sathish" className="w-100"/>
                            </div>
                            <h4>Sathish S</h4>
                            <p>Expert in<br/>Branding</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="team-single mt-4">
                            <div className="team-img">
                                <img src="../assets/images/team/venky.jpg" alt="lifo venky" className="w-100"/>
                            </div>
                            <h4>Venkatesh S</h4>
                            <p>Expert in<br/>UI/UX Design</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="team-single mt-4">
                            <div className="team-img">
                            <img src="../assets/images/team/bala.jpg" alt="lifo bala" className="w-100"/>
                            </div>
                            <h4>Balamurugan</h4>
                            <p>Expert in<br/>Web and Mobile Development</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="team-single mt-4">
                            <div className="team-img">
                                <img src="../assets/images/team/murugan.jpg" alt="lifo murugan" className="w-100"/>
                            </div>
                            <h4>Murugan</h4>
                            <p>Expert in<br/>Software Development</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="team-single mt-4">
                            <div className="team-img">
                                <img src="../assets/images/team/narayanan.jpg" alt="lifo narayanan" className="w-100"/>
                            </div>
                            <h4>Narayanan GK</h4>
                            <p>Expert in<br/>Animation &amp; Media Production</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="team-single mt-4">
                            <div className="team-img">
                                <img src="../assets/images/team/julius.jpg" alt="lifo julius" className="w-100"/>
                            </div>
                            <h4>Julius A</h4>
                            <p>Expert in<br/>Human Resources in IT</p>
                        </div>
                    </Col>
                    {/* <Col lg={3} md={4}>
                        <div className="team-single mt-4">
                            <div className="team-img">
                                <img src="https://placehold.co/500x600/2c73da/fff" alt="" className="w-100"/>
                            </div>
                            <h4>Tamilvanan</h4>
                            <p>Expert in<br/>QA and Testing</p>
                        </div>
                    </Col> */}
                    {/* <Col lg={3} md={4}>
                        <div className="team-single mt-4">
                            <div className="team-img">
                                <img src="../assets/images/team/kesavan.jpg" alt="lifo kesavan" className="w-100"/>
                            </div>
                            <h4>Kesavan</h4>
                            <p>Expert in<br/>QA and Testing</p>
                        </div>
                    </Col>*/}
                </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default Experts;
