import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const AnimationMedia = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Animation &amp; Media</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Industries</li>
                      <li>Animation &amp; Media</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-4">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Our Solutions for Animation and Media Production Industries</h2>
                        <p className="lead"><em>The animation and media production industry is constantly evolving and innovating, driven by the demand for captivating and immersive content across various platforms and genres. Animation and media production professionals need to create stunning visuals, realistic effects, and engaging stories that capture the attention and imagination of their audiences. They also need to leverage cutting-edge technologies that enable them to streamline their workflows, improve their productivity, and enhance their creativity.</em></p>
                        <p className="lead"><em>At LIFO, we offer software company solutions for animation and media production industries that help you achieve your artistic vision and business goals. Our solutions cover various aspects of the animation and media production pipeline, such as:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">2D/3D Modeling and Animation</h4>
                      <p>We provide 2D/3D modeling and animation software that helps you create complex characters, environments, and scenes for your animation and media projects. Our software enables you to sculpt, model, rig, animate, and render your 3D assets with ease and precision.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Visual Effects and Compositing</h4>
                      <p>We develop visual effects and compositing software that helps you add realistic and stunning effects to your animation and media projects. Our software allows you to simulate natural phenomena, such as fire, smoke, water, and explosions, as well as integrate live-action footage with 3D elements.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Video Editing and Color Grading</h4>
                      <p>We create video editing and color grading software that helps you edit and enhance your animation and media projects. Our software enables you to trim, cut, splice, merge, and arrange your video clips, as well as adjust the color, contrast, brightness, saturation, and tone of your footage.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Media Management and Collaboration</h4>
                      <p>We design media management and collaboration software that helps you organize, manage, and share your animation and media projects. Our software enables you to store, access, review, approve, and distribute your media files across different devices, platforms, and locations.</p>          
                    </Col>
                    <Col lg={12} md={12} className="mt-5">
                      <p>LIFO Technologies Pvt. Ltd., is a trusted partner for animation and media production industries across the globe. We have extensive experience and expertise in developing customized solutions that meet the specific needs and challenges of each industry segment, such as feature films, television shows, commercials, games, and web content. We use the latest technologies and best practices to ensure quality, reliability, and scalability of our solutions.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for software company solutions for animation and media production industries that can help you transform your animation and media performance and competitiveness contact us today for a free consultation. We would love to hear from you!</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default AnimationMedia;
