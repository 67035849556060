import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const Facts = () => {
return (
        <section className="section fun-facts bg-theme techpattern overflow-hidden">
            <Container>
                <Row className="text-light">
                    <Col lg={6} md={12}>                                                               
                        <h2 className="mb-3">Need Online Solutions To<br/><strong>Get Successful</strong> In Your Business?</h2>
                        <p className="mb-5 sub-title">Our employees, consultants and key partners are able to collaborate ideas and come up with dynamic solutions to enable businesses establish their presence on the World Wide Web.</p>
                        <div className="counts mb-5">
                            <Row className="text-light">
                                <Col>
                                    <div className="">
                                        <h5>15+<span>Years Expereince</span></h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="">
                                        <h5>500+<span>Happy Clients</span></h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="">
                                        <h5>1000+<span>Completed Projects</span></h5>
                                    </div>
                                </Col>
                            </Row>                     
                        </div>
                        <Row className="row-cols-auto talktoexpert">
                            <Button href="/contact-us" variant="primary" className="col" >Get a Quote</Button>
                            <span className="mx-3 d-none d-sm-block text-center or"> Or </span>
                            <div className="callus col">
                                <label>Talk to an Expert</label>
                                <span>044 4387 6222</span>
                            </div>
                        </Row>
                    </Col>
                    <Col lg={6} md={12} className="d-none d-lg-block">                                                               
                        <img src="../assets/images/facts.svg" className="w-125" alt="facts" />                    
                    </Col>
                </Row>
            </Container>
            <div className="line bottom"></div>
        </section>
    );
};

export default Facts;
