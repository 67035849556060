import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const CloudServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Cloud Services</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Technologies</li>
                      <li>Cloud Services</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-4">
                    <Col lg={12} md={12}>
                        <p className="lead"><em>Cloud services are various services delivered to businesses and customers via the internet. Instead of installing software and platforms on each of your employees computers, you can access these services via the cloud. There is a cloud service solution for everything, whether you're writing an email, creating an invoice, or collaborating on a document.</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Benefits of Cloud Services</h2>
                      <p className="lead"><em>By using cloud services, you can enjoy the following benefits:</em></p> 
                    </Col>
                    <Col lg={6} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/scalability.svg" alt="scalability" />
                      </div>
                      <h4 className="mb-3">Scalability</h4>
                      <p>You can quickly scale both infrastructure and software by utilizing cloud services. You can add or reduce storage amounts in real time based on your current needs. Cloud infrastructure scales on demand to support fluctuating workloads. You can also choose public, private, or hybrid storage offerings, depending on security needs and other considerations.</p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/money-bag.svg" alt="money-bag" />
                      </div>
                      <h4 className="mb-3">Cost-efficiency</h4>
                      <p> You'll save money because you won't have to pay for on-premises software licenses or IT staff to perform installations and upgrades. You'll only pay for what you use and when you use it. A “utility” pay structure means users only pay for the resources they use. Cloud computing also reduces the cost of building and operating data centers.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div class="sicon lg mb-3 mt-3" data-aos="fade-down">
                        <img src="../assets/images/icons/access-control.svg" alt="access-control" />
                      </div>
                      <h4 className="mb-3">Accessibility</h4>
                      <p>You can access your data and applications from anywhere, anytime, and from any device. You'll also have better collaboration and communication with your team members and customers. Cloud-based applications and data are accessible from virtually any internet-connected device. Cloud computing also supports new innovations by making it easy to test new ideas and design new applications without hardware limitations or slow procurement processes.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/security.svg" alt="security" />
                      </div>
                      <h4 className="mb-3">Security</h4>
                      <p>You'll have peace of mind knowing that your data is stored securely in the cloud. You'll also have backup and recovery options in case of any disaster or outage. Cloud service providers manage underlying infrastructure, enabling organizations to focus on application development and other priorities. Virtual private cloud, encryption, and API keys help keep data secure. Hardware failures do not result in data loss because of networked backups.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Types of Cloud Services We Offer</h2>
                      <p className="lead"><em>We offer three basic types of cloud services:</em></p> 
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Software as a Service (SaaS)</h4>
                      <p>This is a software application that you can access via the cloud rather than installing it on your device. You can use these applications to access, share, store, and secure information in the cloud. Some examples of SaaS cloud solutions we offer are Dropbox, Slack, and Microsoft 365.</p>
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Platform as a Service (PaaS)</h4>
                      <p>This is a cloud environment where you can develop and deploy applications. We provide you with the database and operating system required to develop cloud-based software. You can concentrate on your work without developing and maintaining the infrastructure that is typically required for software development processes.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Infrastructure as a Service (IaaS)</h4>
                      <p>This is the infrastructure required to manage SaaS tools, such as servers, storage, and networking resources. We provide you with the storage servers and networking hardware and may also provide additional services such as load balancing and firewalls. Some examples of IaaS services we offer are Amazon Web Services (AWS), Cisco Metapod, and Microsoft Azure.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you're interested in learning more about our cloud services or want to get started with a free trial, please contact us today. We'll be happy to assist you with any questions or concerns you may have.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default CloudServices;
