import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const SustainabilityPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Sustainability Policy</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Approach</li>
                      <li>Sustainability Policy</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={12} md={12}>
                     <h2 className="mb-3">Company Policy on Sustainability</h2>
                     <p className="lead"><em>At LIFO Technologies Pvt. Ltd., we are committed to sustainability in all aspects of our business. We believe that sustainability is not only good for the environment, but also for our customers, employees, shareholders and communities. We strive to minimize our environmental impact, maximize our social impact and optimize our economic performance.</em></p> 
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-5">
                <Row className="text-light">
                    <Col lg={4} md={4}>
                        <div className="policy mb-5">
                            <div class="sicon lg mb-3" data-aos="fade-down">
                                <img src="../assets/images/icons/go-green.svg" alt="" />
                            </div>
                            <h3>Environmental Impact</h3>
                            <p>We aim to reduce our greenhouse gas emissions, energy consumption, water consumption and waste generation by implementing best practices and innovative solutions across our operations. We also seek to increase our use of renewable energy sources and promote circular economy principles. We monitor and report our environmental performance regularly and transparently.</p>
                        </div>                                   
                    </Col>
                    <Col lg={4} md={4}>
                        <div className="policy mb-5">
                            <div class="sicon lg mb-3" data-aos="fade-down">
                                <img src="../assets/images/icons/helping.svg" alt="" />
                            </div>
                            <h3>Social Impact</h3>
                            <p>We care about the well-being of our employees, customers and communities. We respect human rights, diversity and inclusion in our workplace and supply chain. We provide safe and healthy working conditions, fair wages and benefits, and opportunities for learning and development. We also support various social causes and initiatives that align with our values and goals.</p>
                        </div>                                      
                    </Col>
                    <Col lg={4} md={4}>
                        <div className="policy mb-5">
                            <div class="sicon lg mb-3" data-aos="fade-down">
                                <img src="../assets/images/icons/line-chart.svg" alt="" />
                            </div>
                            <h3>Economic Performance</h3>
                            <p>We pursue long-term growth and profitability by delivering high-quality products and services that meet or exceed our customers’ expectations. We invest in research and development, innovation and digital transformation to enhance our competitiveness and efficiency. We also adhere to the highest standards of ethics, integrity and compliance in our business conduct.</p>
                        </div>                                      
                    </Col>
                    <Col lg={12} md={12} className="mt-3">
                        <p className="lead"><em>We invite all our stakeholders to join us in our sustainability journey and contribute to a better future for everyone.</em></p>
                    </Col>
                </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default SustainabilityPolicy;
