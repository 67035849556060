import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const SupplyChainManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Supply Chain Management</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Solutions</li>
                      <li>Supply Chain Management</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Achieve Supply Chain Excellence with SCM Software</h2>
                        <p className="lead"><em>Supply chain excellence is a key factor for business success in today's competitive and dynamic market. To achieve supply chain excellence, businesses need to manage and coordinate their suppliers, distributors, customers, and other partners effectively and efficiently. They also need to reduce their costs, risks, and waste in their supply chain.</em></p>
                        <p className="lead"><em>To achieve these objectives, businesses need SCM software.</em></p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={7} md={12}>
                        <h2 className="mb-3">What is SCM Software?</h2>
                        <p className="lead"><em>SCM software stands for Supply Chain Management software. It is a technology that enables businesses to manage and optimize their supply chain by integrating and automating their various supply chain functions. SCM software enables businesses to:</em></p>
                        <ul className="custom-list">
                            <li>Manage and access all their supply chain data in one place</li>
                            <li>Integrate and automate their various supply chain functions, such as procurement, inventory, production, logistics, distribution, and more</li>
                            <li>Plan and execute their supply chain strategies and activities</li>
                            <li>Monitor and control their supply chain performance and outcomes</li>
                            <li>Analyze and improve their supply chain efficiency and effectiveness</li>
                        </ul>
                        <p>SCM software is not just a tool for supply chain managers. It is a comprehensive solution that supports every stakeholder in the supply chain, from suppliers to customers. SCM software enables businesses to create a transparent and collaborative supply chain that drives their business success.</p>
                    </Col>
                    <Col lg={5} md={12} className="text-center">
                      <img src="../assets/images/pages/supply-chain.svg" alt="supply-chain" className="w-100"/>
                    </Col>
                    <div className="line space"></div>
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">Why businesses need SCM Software?</h2>
                        <p className="lead"><em>SCM software can help businesses achieve their business goals in many ways. Here are some of the benefits of using SCM software:</em></p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Reduce costs and waste</h4>
                      <p>SCM software enables businesses to reduce costs and waste by optimizing their inventory levels, production schedules, transportation routes, and distribution networks. They can also reduce costs and waste by avoiding overstocking, understocking, spoilage, damage, theft, and obsolescence.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Improve customer satisfaction and loyalty</h4>
                      <p>SCM software enables businesses to improve customer satisfaction and loyalty by delivering the right products, at the right time, at the right place, at the right price, and in the right condition. They can also use SCM software to enhance their customer service, communication, and feedback.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Increase productivity and quality</h4>
                      <p>SCM software enables businesses to increase productivity and quality by streamlining and standardizing their supply chain processes and workflows. They can also use SCM software to improve their quality control, compliance, and traceability.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Gain competitive advantage and growth</h4>
                      <p>SCM software enables businesses to gain competitive advantage and growth by enabling them to respond faster and better to changing market conditions, customer demands, and industry trends. They can also use SCM software to innovate new products, services, and business models.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">How to choose the best SCM Software for your business?</h2>
                        <p className="lead"><em>There are many SCM software solutions available in the market that cater to different industries, sizes, budgets, and needs. Businesses need to do some research and compare the features, benefits, pricing, reviews, etc. of different SCM software solutions before making a decision.</em></p>
                        <p className="lead"><em>Some of the factors that businesses should consider when choosing an SCM software solution are:</em></p>         
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">01</div>
                        <h4 className="mb-3">The size and nature of your business</h4>
                        <p>Businesses need to choose an SCM software solution that matches their business size and industry. For example, if they are a small business, they may need a simple and affordable SCM software solution that covers the basic functions of supply chain management. If they are a large enterprise, they may need a more complex and robust SCM software solution that supports multiple departments, locations, and currencies.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">02</div>
                        <h4 className="mb-3">The features and functions that you need</h4>
                        <p>Businesses need to choose an SCM software solution that offers the features and functions that they need for their business goals. For example, if they want to optimize their inventory management, they may need an SCM software solution that has inventory planning, forecasting, replenishment, and tracking features. If they want to optimize their logistics management, they may need an SCM software solution that has transportation planning, execution, monitoring, and optimization features.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">03</div>
                        <h4 className="mb-3">The ease of use and customization</h4>
                        <p>Businesses need to choose an SCM software solution that is easy to use and customize according to their needs. They don't want to spend too much time and effort learning how to use the system or hiring developers to customize it for them. They want an SCM software solution that has a user-friendly interface, intuitive navigation, and flexible configuration options.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">04</div>
                        <h4 className="mb-3">The security and reliability</h4>
                        <p>Businesses need to choose an SCM software solution that is secure and reliable. They don't want to risk losing or compromising their supply chain data or facing downtime or glitches in the system. They want an SCM software solution that has strong encryption, backup, recovery, and disaster recovery features. They also want an SCM software solution that has high availability, performance, and scalability.</p>
                    </Col>
                    <Col lg={12} md={12}>
                        <div className="nos">05</div>
                        <h4 className="mb-3">The support and guidance</h4>
                        <p>Businesses need to choose an SCM software solution that provides them with support and guidance throughout their SCM journey. They don't want to be left alone or frustrated when they encounter any problems or challenges with the system. They want an SCM software solution that has a dedicated support team that is available 24/7 via phone, email, chat, or web. They also want an SCM software solution that has a comprehensive knowledge base, tutorials, videos, webinars, blogs, forums, and other resources that can help them learn more about the system</p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our SCM solutions, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your animation and media needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default SupplyChainManagement;
