import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome';
const Footer = () => {
return (
	<footer>
		<Container fluid="md" className="text-light">
			<Row>				
				<Col lg={4} md={6}>
					<h4 className="mb-4">About Us</h4>
					<Row>
						<Col lg="5" md="6" xs="6">
							<h6 className="mb-3">Company</h6>
							<ul className="footer-links list">
								<li><Link to="/about/company" className="">About Company</Link></li>
								<li><Link to="/about/leadership" className="">Leadership</Link></li>
								<li><Link to="/about/experts" className="">Experts</Link></li>
								<li><Link to="/about/clients" className="">Clients</Link></li>
								<li><Link to="/about/partners" className="">Our Partners</Link></li>
							</ul>
							<h6 className="mb-3">Recognition</h6>
							<ul className="footer-links list">
								<li><Link to="/recognition/testimonials" className="">Testimonials</Link></li>
								<li><Link to="/recognition/press-media" className="">Press & Media</Link></li>
							</ul>
						</Col>
						<Col lg="7" md="6" xs="6">
							<h6 className="mb-3">Approach</h6>
							<ul className="footer-links list">
								<li><Link to="/approach/technology-partnership" className="">Technology Partnership</Link></li>
								<li><Link to="/approach/development-process" className="">Development Process</Link></li>
								<li><Link to="/approach/sustainability-policy" className="">Sustainability Policy</Link></li>
								<li><Link to="/approach/privacy-policy" className="">Privacy Policy</Link></li>
								<li><Link to="/approach/terms-of-use" className="">Terms of Use</Link></li>
							</ul>
							<h6 className="mb-3">Join Us</h6>
							<ul className="footer-links list">
								<li><Link to="/join-us/careers" className="">Careers</Link></li>
								<li><Link to="/join-us/refferal-program" className="">Referral Program</Link></li>
								<li><Link to="/contact-us" className="">Contact Us</Link></li>
							</ul>
						</Col>
					</Row>
					{/* <h4 className="mb-4">Resources</h4>
					<Row>
						<Col lg="5" md="6" xs="6">
							<ul className="footer-links list">
								<li><Link to="/blog" className="">Blog</Link></li>
								<li><Link to="/" className="">Case Studies</Link></li>
							</ul>
						</Col>
					</Row> */}
				</Col>
				<Col lg={4} md={6}>
					<h4 className="mb-4">Services</h4>
					<Row>
						<Col lg="6" md="6" xs="6">
							<ul className="footer-links list">
								<li><Link to="/services/software-development" className="">Software Development</Link></li>
								<li><Link to="/services/ui-ux-design" className="">UI/UX Design</Link></li>
								<li><Link to="/services/software-testing" className="">Testing and QA</Link></li>
								<li><Link to="/services/application-services" className="">Application Services</Link></li>
								<li><Link to="/services/infrastructure-services" className="">Infrastructure Services</Link></li>
								<li><Link to="/services/data-analytics" className="">Data Analytics</Link></li>
								<li><Link to="/services/cloud-migration" className="">Cloud Migration</Link></li>
							</ul>
						</Col>
						<Col lg="6" md="6" xs="6">
							<ul className="footer-links list">
								<li><Link to="/services/digital-transformation" className="">Digital Transformation</Link></li>
								<li><Link to="/services/managed-it-services" className="">Managed IT Services</Link></li>
								<li><Link to="/services/process-automation" className="">Process Automation</Link></li>
								<li><Link to="/services/data-extraction-migration" className="">Data Extraction & Migration</Link></li>
								<li><Link to="/services/devOps" className="">DevOps</Link></li>
								<li><Link to="/services/cybersecurity" className="">Cybersecurity</Link></li>
								<li><Link to="/services/animation-media-production" className="">Animation &amp; Media Production</Link></li>
							</ul>
						</Col>
					</Row>
					<h4 className="mb-4">Technologies</h4>
					<Row>
						<Col lg="6" md="6" xs="6">
							<h6 className="mb-3">Trending</h6>
							<ul className="footer-links list">
								<li><Link to="/technologies/cloud-services" className="">Cloud Services</Link></li>
								<li><Link to="/technologies/artificial-intelligence" className="">Artificial Intelligence</Link></li>
								<li><Link to="/technologies/internet-of-things" className="">Internet of Things</Link></li>
								<li><Link to="/technologies/data-science" className="">Data Science</Link></li>
								<li><Link to="/technologies/big-data" className="">Big Data</Link></li>
								<li><Link to="/technologies/blockchain" className="">Blockchain</Link></li>
								<li><Link to="/technologies/virtual-reality" className="">Virtual Reality</Link></li>
								<li><Link to="/technologies/augmented-reality" className="">Augmented Reality</Link></li>
							</ul>
						</Col>
						<Col lg="6" md="6" xs="6">
							<h6 className="mb-3">Platforms</h6>
							<ul className="footer-links list">
								<li><Link to="/platforms/microsoft" className="">Microsoft</Link></li>
								<li><Link to="/platforms/aws" className="">Amazon Web Services</Link></li>
								<li><Link to="/platforms/google-cloud-platform" className="">Google Cloud Platform</Link></li>
							</ul>
						</Col>
					</Row>
				</Col>
				<Col lg={4} md={12}>
					<h4 className="mb-4">Solutions</h4>
					<Row>
						<Col lg="6" md="6" xs="6">
							<ul className="footer-links list">
								<li><Link to="/solutions/crm" className="">CRM</Link></li>
								<li><Link to="/solutions/erp" className="">ERP</Link></li>
								<li><Link to="/solutions/human-resources" className="">Human Resources</Link></li>
								<li><Link to="/solutions/supply-chain-management" className="">Supply Chain Management</Link></li>
								<li><Link to="/solutions/fleet-management" className="">Fleet Management</Link></li>
								<li><Link to="/solutions/kiosk-software" className="">Kiosk Software</Link></li>
								<li><Link to="/solutions/operations-management" className="">Operations Management</Link></li>
								<li><Link to="/solutions/asset-management" className="">Asset Management</Link></li>
								<li><Link to="/solutions/project-management" className="">Project Management</Link></li>
								<li><Link to="/solutions/ecommerce" className="">Ecommerce</Link></li>
							</ul>
						</Col>
						<Col lg="6" md="6" xs="6">
							<h6 className="mb-3">Industries</h6>
							<ul className="footer-links list">
								<li><Link to="/industries/oil-gas" className="">Oil &amp; Gas</Link></li>	
								<li><Link to="/industries/healthcare" className="">Healthcare</Link></li>
								<li><Link to="/industries/manufacturing" className="">Manufacturing</Link></li>
								<li><Link to="/industries/professional-services" className="">Professional Services</Link></li>
								<li><Link to="/industries/animation-media" className="">Animation & Media</Link></li>
								<li><Link to="/industries/insurance" className="">Insurance</Link></li>
								<li><Link to="/industries/retail" className="">Retail</Link></li>
								<li><Link to="/industries/transportation-logistics" className="">Transportation and Logistics</Link></li>
								<li><Link to="/industries/telecommunications" className="">Telecommunications</Link></li>								
							</ul>
						</Col>
					</Row>
					<h4 className="mb-4">Products</h4>
					<Row>
						<Col lg="6" md="6" xs="6">
							<ul className="footer-links list">
								<li><Link to="/products/lpg-vending-machine" className="">LPG Vending Machine</Link></li>
							</ul>
						</Col>
					</Row>
				</Col>
			</Row>
			<div className="line mt-3"></div>
			<Row className="mt-5">
				<Col lg={4} md={4} className="footer-logo">
					<img src="../assets/images/logo-light.svg" className="" alt="LIFO logo" />					
				</Col>
				<Col lg={4} md={4} className="text-center">
					<ul className="social-media-links">
						<li><Link to="https://www.facebook.com/LIFOTechnologiesPvtLtd/" target="_blank" rel="noopener noreferrer" title="Follow Our FaceBook" className=""><FontAwesome name="facebook"/></Link></li>
						<li><Link to="https://www.linkedin.com/company/lifo-technologies-private-limited/" target="_blank" rel="noopener noreferrer" title="Follow Our LinkedIn" className=""><FontAwesome name="linkedin"/></Link></li>
						<li><Link to="https://twitter.com/lifotech" target="_blank" rel="noopener noreferrer" title="Follow Our Twitter" className=""><FontAwesome name="twitter"/></Link></li>
					</ul>										
				</Col>
				<Col lg={4} md={4} className="">
					<p className="text-end sub-title copyright">Copyright {new Date().getFullYear()} @ LIFO. All Rights Reserved.</p>										
				</Col>
			</Row>
		</Container>
	</footer>
);
};

export default Footer;
