import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const DevelopmentProcess = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Development Process</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Approach</li>
                      <li>Development Process</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={12} md={12}>
                     <h2 className="mb-3">At LIFO Technologies Pvt. Ltd.</h2>
                     <p className="lead"><em>The software development process is a structured process to enhance and build products, projects, and designs together1. It involves conceiving, specifying, designing, programming, documenting, testing, and bug fixing involved in creating and maintaining applications, frameworks, or other software components2. It is performed by software developers who use various tools, techniques, and methodologies to deliver software solutions that meet the needs and expectations of the stakeholders.</em></p> 
                     <p><em>The software development process can be divided into different phases or stages, depending on the chosen methodology. Some of the common methodologies are waterfall, agile, scrum, lean, and devops. Each methodology has its own advantages and disadvantages, and may suit different types of projects and teams.</em></p>        
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-4">
                <div class="sicon lg" data-aos="fade-down">
                    <img src="../assets/images/icons/process.svg" alt="" />
                </div>
                <Row className="text-light justify-content-center">
                    <Col lg={12} md={12}>
                        <div class="timeline">
                            <div class="outer">
                                <div class="tcard">
                                    <div class="info">
                                        <h3 class="title">Requirement analysis</h3>
                                        <p>This stage involves gathering and analyzing the needs and expectations of the stakeholders, such as customers, users, or managers. It also involves defining the scope, objectives, features, and specifications of the software product. Additionally, it involves estimating the time, cost, and resources required for the project.</p>
                                    </div>
                                </div>
                                <div class="tcard">
                                    <div class="info">
                                        <h3 class="title">UI/UX Design</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                                    </div>
                                </div>
                                <div class="tcard">
                                    <div class="info">
                                        <h3 class="title">Coding</h3>
                                        <p>This stage involves writing the source code that implements the functionality and features of the software product. It may involve using one or more programming languages, frameworks, libraries, tools, and standards. The coding should follow the design and specifications defined in the previous stages. It should also adhere to coding best practices, such as readability, modularity, documentation, and testing.</p>
                                    </div>
                                </div>
                                <div class="tcard">
                                    <div class="info">
                                        <h3 class="title">QA &amp; Testing</h3>
                                        <p>We perform various types of testing, such as unit testing, integration testing, functional testing, usability testing, and performance testing to ensure that your software is bug-free and meets the specifications.</p>
                                    </div>
                                </div>
                                <div class="tcard">
                                    <div class="info">
                                        <h3 class="title">Deployment</h3>
                                        <p>This stage involves delivering and installing the software product to the intended users or customers. It may involve transferring the software product to a server or a device, configuring it to run properly, and ensuring its compatibility with different environments and platforms. Deployment can be done in different ways, such as on-premise deployment, cloud deployment, or hybrid deployment.</p>
                                    </div>
                                </div>
                                <div class="tcard">
                                    <div class="info">
                                        <h3 class="title">Maintenance</h3>
                                        <p>This stage involves providing ongoing support and improvement for the software product after it is deployed. It may involve fixing bugs, adding new features, enhancing performance, updating security, or complying with new standards or regulations. Maintenance can be done periodically or continuously depending on the needs and expectations of the stakeholders.</p>
                                    </div>
                                </div>
                            </div>
                        </div>                      
                    </Col>
                </Row>
                <div class="sicon lg pull-right" data-aos="fade-down">
                    <img src="../assets/images/icons/programming.svg" alt="" />
                </div>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default DevelopmentProcess;
