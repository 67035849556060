import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const DataScience = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Data Science</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Technologies</li>
                      <li>Data Science</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <p className="lead mb-5"><em>Data science is an interdisciplinary field that uses scientific methods, processes, algorithms, and systems to extract knowledge and insights from large and complex data sets. Data science can help solve problems and make decisions in various domains, such as business, health, education, and social sciences.</em></p>
                        <h2 className="mb-3">What Data Science can do?</h2>
                        <p className="lead"><em>Data science can perform a wide range of tasks that require data analysis and interpretation, such as:</em></p>                       
                    </Col>
                    <Col lg={7} md={12}>
                        <Row className="text-light">
                            <Col lg={6} md={6}>
                              <div className="nos">01</div>
                              <h4 className="mb-3">Predictive Analytics</h4>
                              <p>Data science can use statistical models and machine learning techniques to forecast future outcomes and trends based on historical data, such as customer behavior, market demand, or disease spread.</p>
                            </Col>
                            <Col lg={6} md={6}>
                              <div className="nos">02</div>
                              <h4 className="mb-3">Artificial Intelligence</h4>
                              <p>Data science can use artificial neural networks and deep learning methods to create systems that can perform tasks that normally require human intelligence, such as image recognition, natural language processing, or speech synthesis.</p>          
                            </Col>
                            <Col lg={6} md={6}>
                              <div className="nos">03</div>
                              <h4 className="mb-3">Big Data Analytics</h4>
                              <p>Data science can use distributed and parallel computing platforms and tools to process and analyze massive amounts of structured and unstructured data, such as web logs, social media, or sensor data.</p>          
                            </Col>
                            <Col lg={6} md={6}>
                              <div className="nos">04</div>
                              <h4 className="mb-3">Data Visualization</h4>
                              <p>Data science can use graphical and interactive techniques to present and communicate data in an effective and engaging way, such as charts, maps, dashboards, or infographics.</p>          
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={5} md={12}>
                      <img src="../assets/images/pages/data-science.svg" alt="data-science" className="w-100"/>
                    </Col>
                </Row>
                
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Benefits of Data Science Technology</h2>
                      <p className="lead"><em>Data science technology offers many benefits for businesses and individuals, such as:</em></p> 
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/line-chart.svg" alt="line-chart" />
                      </div>
                      <h4 className="mb-3">Improved Efficiency</h4>
                      <p>AI can help developers accelerate development with quick deployments and testing of new ideas and applications.</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/mission.svg" alt="mission" />
                      </div>
                      <h4 className="mb-3">Enhanced Accuracy</h4>
                      <p>Data science technology can help reduce human errors and biases that may affect data quality and reliability, such as data entry errors, sampling errors, or confirmation bias.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/ai.svg" alt="ai" />
                      </div>
                      <h4 className="mb-3">Better Insights</h4>
                      <p>Data science technology can help discover hidden patterns and relationships within data that may not be obvious or intuitive, such as customer segments, market opportunities, or causal factors.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/vision.svg" alt="Increased Innovation" />
                      </div>
                      <h4 className="mb-3">Increased Innovation</h4>
                      <p>Data science technology can help generate new ideas and solutions based on data-driven evidence and experimentation, such as product features, business models, or social impact.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Types of Data Science Technology</h2>
                      <p className="lead"><em>There are different types of data science technology based on the level of functionality and complexity they provide:</em></p> 
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Data Ingestion</h4>
                      <p>This is the type of data science technology that collects and imports data from various sources and formats into a common storage system or platform. Examples of data ingestion tools include Apache Kafka, Apache Flume, or Amazon Kinesis.</p>
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Data Storage</h4>
                      <p>This is the type of data science technology that stores and organizes data in a structured or unstructured way. Examples of data storage systems include relational databases, NoSQL databases, or data warehouses.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Data Processing</h4>
                      <p>This is the type of data science technology that transforms and manipulates data according to certain rules or logic. Examples of data processing tools include Apache Spark, Apache Hadoop, or Apache Pig.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Data Analysis</h4>
                      <p>This is the type of data science technology that applies statistical methods and machine learning algorithms to explore and model data. Examples of data analysis tools include Python, R, or MATLAB.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">05</div>
                      <h4 className="mb-3">Data Visualization</h4>
                      <p>This is the type of data science technology that creates graphical representations of data using various elements and techniques. Examples of data visualization tools include Tableau, Power BI, or D3.js.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you're interested in learning more about our data science technology or want to get started with a free trial, please contact us today. We'll be happy to assist you with any questions or concerns you may have.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default DataScience;
