import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const KioskSoftware = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Kiosk Software</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Solutions</li>
                      <li>Kiosk Software</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Transform Your Customer Experience with Self-Service Kiosks</h2>
                        <p className="lead"><em>Do you want to transform your customer experience and increase your sales and revenue? Do you want to provide fast and convenient service, information, and transactions to your customers? Do you want to reduce your operational costs and increase your efficiency?</em></p>
                        <p className="lead"><em><span className="text-light">If you answered yes to any of these questions,</span> then you need kiosk software.</em></p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={7} md={12}>
                        <h2 className="mb-3">What is Kiosk Software?</h2>
                        <p className="lead"><em>Kiosk software is a technology that helps you create and manage self-service kiosks for your business. Kiosk software allows you to:</em></p>
                        <ul className="custom-list">
                            <li>Create and customize your kiosk interface and content</li>
                            <li>Manage and update your kiosk remotely and securely</li>
                            <li>Integrate your kiosk with your other systems and devices</li>
                            <li>Monitor and analyze your kiosk performance and usage</li>
                        </ul>
                        <p>Kiosk software is not just a tool for creating kiosks. It is a comprehensive solution that supports every aspect of your kiosk project, from design to deployment to maintenance. Kiosk software helps you create a seamless and engaging customer experience that drives your business success.</p>
                    </Col>
                    <Col lg={5} md={12} className="text-center mt-4">
                      <img src="../assets/images/pages/kiosk.svg" alt="kiosk" className="w-75"/>
                    </Col>
                    <div className="line space"></div>
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">Why you need Kiosk Software for your business?</h2>
                        <p className="lead"><em>Kiosk software can help you achieve your business goals in many ways. Here are some of the benefits of using kiosk software:</em></p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Increase customer satisfaction and loyalty</h4>
                      <p>Kiosk software helps you increase customer satisfaction and loyalty by providing fast and convenient service, information, and transactions to your customers. You can also use kiosk software to personalize your kiosk content and offers based on your customer preferences and behavior.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Increase sales and revenue</h4>
                      <p>Kiosk software helps you increase sales and revenue by attracting more customers, increasing foot traffic, and reducing wait times. You can also use kiosk software to upsell and cross-sell your products and services, generate leads, and collect payments.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Reduce operational costs and increase efficiency</h4>
                      <p>Kiosk software helps you reduce operational costs and increase efficiency by automating and simplifying your processes and tasks. You can also use kiosk software to reduce labor costs, errors, waste, and maintenance.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Reduce environmental impact</h4>
                      <p>FM software helps you reduce environmental impact by reducing fuel consumption, emissions, and waste. You can also use FM software to comply with environmental regulations and standards.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">How to choose the best Kiosk Software for your business?</h2>
                        <p className="lead"><em>There are many kiosk software solutions available in the market that cater to different industries, sizes, budgets, and needs. You need to do some research and compare the features, benefits, pricing, reviews, etc. of different kiosk software solutions before making a decision.</em></p>
                        <p className="lead"><em>Some of the factors that you should consider when choosing a kiosk software solution are:</em></p>         
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">01</div>
                        <h4 className="mb-3">The size and nature of your business</h4>
                        <p>You need to choose a kiosk software solution that matches your business size and industry. For example, if you are a small business, you may need a simple and affordable kiosk software solution that covers the basic functions of kiosk creation and management. If you are a large enterprise, you may need a more complex and robust kiosk software solution that supports multiple kiosks, locations, and features.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">02</div>
                        <h4 className="mb-3">The features and functions that you need</h4>
                        <p>You need to choose a kiosk software solution that offers the features and functions that you need for your business goals. For example, if you want to create an interactive kiosk for information and entertainment, you may need a kiosk software solution that has multimedia, touchscreen, and gesture features. If you want to create a transactional kiosk for payments and orders, you may need a kiosk software solution that has barcode, scanner, printer, and card reader features.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">03</div>
                        <h4 className="mb-3">The ease of use and customization</h4>
                        <p>You need to choose a kiosk software solution that is easy to use and customize according to your needs. You don't want to spend too much time and effort learning how to use the system or hiring developers to customize it for you. You want a kiosk software solution that has a user-friendly interface, intuitive navigation, and flexible configuration options.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">04</div>
                        <h4 className="mb-3">The security and reliability</h4>
                        <p>You need to choose a kiosk software solution that is secure and reliable. You don't want to risk losing or compromising your kiosk data or facing downtime or glitches in the system. You want a kiosk software solution that has strong encryption, backup, recovery, and disaster recovery features. You also want a kiosk software solution that has high availability, performance, and scalability.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">05</div>
                        <h4 className="mb-3">The support and guidance</h4>
                        <p>You need to choose a kiosk software solution that provides you with support and guidance throughout your kiosk project. You don't want to be left alone or frustrated when you encounter any problems or challenges with the system. You want a kiosk software solution that has a dedicated support team that is available 24/7 via phone, email, chat, or web. You also want a kiosk software solution that has a comprehensive knowledge base, tutorials, videos, webinars, blogs, forums, and other resources that can help you learn more about the system.</p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our kiosk software solutions, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your animation and media needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default KioskSoftware;
