import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const AssetsManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Asset Management</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Solutions</li>
                      <li>Asset Management</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Manage Your Assets with Asset Management Software</h2>
                        <p className="lead"><em>Do you want to manage your assets more effectively and efficiently? Do you want to track and monitor your assets, their location, condition, and value? Do you want to reduce your asset-related costs, risks, and waste?</em></p>
                        <p className="lead"><em><span className="text-light">If you answered yes to any of these questions,</span> then you need Asset Management Software.</em></p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={7} md={12}>
                        <h2 className="mb-3">What is Asset Management Software?</h2>
                        <p className="lead"><em>It is a technology that helps you manage and optimize your assets by integrating and automating your various asset management functions. Asset Management Software allows you to:</em></p>
                        <ul className="custom-list">
                            <li>Manage and access all your asset data in one place</li>
                            <li>Track and monitor your assets, their location, condition, and value</li>
                            <li>Plan and optimize your asset lifecycle, from acquisition to disposal</li>
                            <li>Manage your asset maintenance, repair, and replacement</li>
                            <li>Analyze and improve your asset performance and efficiency</li>
                        </ul>
                        <p>Asset Management Software is not just a tool for asset managers. It is a comprehensive solution that supports every stakeholder in your asset management, from owners to operators. Asset Management Software helps you create a reliable and sustainable asset management that drives your business success.</p>
                    </Col>
                    <Col lg={5} md={12} className="text-center mt-4">
                      <img src="../assets/images/pages/asset-management.svg" alt="asset-management" className="w-75"/>
                    </Col>
                    <div className="line space"></div>
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">Why you need Asset Management Software for your business?</h2>
                        <p className="lead"><em>Asset Management Software can help you achieve your business goals in many ways. Here are some of the benefits of using Asset Management Software:</em></p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Save time and money</h4>
                      <p>Asset Management Software helps you save time and money by automating and simplifying your asset management processes and reducing errors and paperwork. You can also save money by reducing asset-related costs, such as depreciation, maintenance, repair, replacement, and disposal.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Improve asset reliability and availability</h4>
                      <p>Asset Management Software helps you improve asset reliability and availability by tracking and monitoring your assets, their location, condition, and value. You can also use Asset Management Software to plan and optimize your asset lifecycle, from acquisition to disposal.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Increase asset utilization and productivity</h4>
                      <p>Asset Management Software helps you increase asset utilization and productivity by streamlining and standardizing your asset management processes and workflows. You can also use Asset Management Software to improve your asset maintenance, repair, and replacement.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Gain competitive advantage and growth</h4>
                      <p>Asset Management Software helps you gain competitive advantage and growth by enabling you to manage your assets more effectively and efficiently. You can also use Asset Management Software to analyze and improve your asset performance and efficiency.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">How to choose the best Asset Management Software for your business?</h2>
                        <p className="lead"><em>There are many Asset Management Software solutions available in the market that cater to different industries, sizes, budgets, and needs. You need to do some research and compare the features, benefits, pricing, reviews, etc. of different Asset Management Software solutions before making a decision.</em></p>
                        <p className="lead"><em>Some of the factors that you should consider when choosing an Asset Management Software solution are:</em></p>         
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">01</div>
                        <h4 className="mb-3">The size and nature of your business</h4>
                        <p>You need to choose an Asset Management Software solution that matches your business size and industry. For example, if you are a small business, you may need a simple and affordable Asset Management Software solution that covers the basic functions of asset management. If you are a large enterprise, you may need a more complex and robust Asset Management Software solution that supports multiple assets, types, and locations.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">02</div>
                        <h4 className="mb-3">The features and functions that you need</h4>
                        <p>You need to choose an Asset Management Software solution that offers the features and functions that you need for your business goals. For example, if you want to track and monitor your assets, you may need an Asset Management Software solution that has barcode, RFID, GPS, or IoT features. If you want to plan and optimize your asset lifecycle, you may need an Asset Management Software solution that has asset lifecycle management, asset depreciation, asset disposal, or asset valuation features.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">03</div>
                        <h4 className="mb-3">The ease of use and customization</h4>
                        <p>You need to choose an Asset Management Software solution that is easy to use and customize according to your needs. You don't want to spend too much time and effort learning how to use the system or hiring developers to customize it for you. You want an Asset Management Software solution that has a user-friendly interface, intuitive navigation, and flexible configuration options.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">04</div>
                        <h4 className="mb-3">The security and reliability</h4>
                        <p>You need to choose an Asset Management Software solution that is secure and reliable. You don't want to risk losing or compromising your asset data or facing downtime or glitches in the system. You want an Asset Management Software solution that has strong encryption, backup, recovery, and disaster recovery features. You also want an Asset Management Software solution that has high availability, performance, and scalability.</p>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="nos">05</div>
                        <h4 className="mb-3">The support and guidance</h4>
                        <p>You need to choose an Asset Management Software solution that provides you with support and guidance throughout your AM journey. You don't want to be left alone or frustrated when you encounter any problems or challenges with the system. You want an Asset Management Software solution that has a dedicated support team that is available 24/7 via phone, email, chat, or web. You also want an Asset Management Software solution that has a comprehensive knowledge base, tutorials, videos, webinars, blogs, forums, and other resources that can help you learn more about the system.</p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our Asset Management software, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your animation and media needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default AssetsManagement;
