import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const AugmentedReality = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Augmented Reality</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Technologies</li>
                      <li>Augmented Reality</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <p className="lead"><em>Augmented reality technology is a type of computer technology that overlays digital content onto the real world, enhancing the user's perception and interaction. Augmented reality technology can provide various types of information and experiences, such as sounds, videos, graphics, or haptic feedback.</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-3">
                      <h2 className="mb-3">What Augmented Reality Technology Can Do</h2>
                      <p className="lead"><em>Augmented reality technology can enable various applications that require realistic and engaging simulations, such as:</em></p>                       
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/game.svg" alt="game" />
                      </div>
                      <h4 className="mb-3">Gaming</h4>
                      <p>Augmented reality technology can enhance the entertainment and immersion of video games by allowing the user to interact with the game elements in their physical surroundings.</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/education.svg" alt="education" />
                      </div>
                      <h4 className="mb-3">Education</h4>
                      <p>Augmented reality technology can facilitate learning and training by providing interactive and immersive scenarios that simulate real-world situations, such as anatomy, astronomy, or history.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/shopping-bag.svg" alt="Shopping" />
                      </div>
                      <h4 className="mb-3">Shopping</h4>
                      <p>Augmented reality technology can improve the customer experience and satisfaction by allowing the user to try on or preview products before buying them, such as clothes, furniture, or cosmetics.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/airplane.svg" alt="Tourism" />
                      </div>
                      <h4 className="mb-3">Tourism</h4>
                      <p>Augmented reality technology can enrich the travel and exploration of different places and cultures by providing additional information and guidance, such as maps, landmarks, or stories.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Benefits of Augmented Reality Technology</h2>
                      <p className="lead"><em>Augmented reality technology offers many benefits for businesses and individuals, such as:</em></p> 
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/money-bag.svg" alt="Cost-effectiveness" />
                      </div>
                      <h4 className="mb-3">Cost-effectiveness</h4>
                      <p>Augmented reality technology can reduce the expenses and risks associated with physical activities, such as travel, equipment, or safety measures.</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/access-control.svg" alt="Accessibility" />
                      </div>
                      <h4 className="mb-3">Accessibility</h4>
                      <p>Augmented reality technology can provide access to information and experiences that may otherwise be unavailable or inaccessible due to distance, time, or disability.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/vision.svg" alt="Creativity" />
                      </div>
                      <h4 className="mb-3">Creativity</h4>
                      <p>Augmented reality technology can stimulate imagination and innovation by allowing the user to create and explore new worlds and possibilities.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/helping.svg" alt="Empathy" />
                      </div>
                      <h4 className="mb-3">Empathy</h4>
                      <p>Augmented reality technology can foster understanding and compassion by allowing the user to see and feel different perspectives and emotions.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Types of Augmented Reality Technology</h2>
                      <p className="lead"><em>There are different types of augmented reality technology based on the level of immersion and interaction they provide:</em></p> 
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Marker-based augmented reality</h4>
                      <p>This is the type of augmented reality technology that uses predefined markers or images to trigger the digital content. The user needs to scan or view the marker with a camera or a device to see the augmented content. Examples of marker-based augmented reality include QR codes, AR cards, or AR books.</p>
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Marker-less augmented reality</h4>
                      <p>This is the type of augmented reality technology that uses location-based data or sensors to trigger the digital content. The user does not need to scan or view any marker to see the augmented content. Examples of marker-less augmented reality include Google Maps, Snapchat filters, or IKEA Place.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Projection-based augmented reality</h4>
                      <p>This is the type of augmented reality technology that uses light or laser projections to display the digital content onto physical surfaces. The user can interact with the projected content using touch or gestures. Examples of projection-based augmented reality include SixthSense, Lightform, or Hololamp.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you're interested in learning more about our augmented reality technology or want to get started with a free trial, please contact us today. We'll be happy to assist you with any questions or concerns you may have.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default AugmentedReality;
