import React,{useEffect} from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from "react-scroll-to-top";
import {BrowserRouter as Router, Routes, Route, Navigate,} from 'react-router-dom';

import Home from './Pages/Home';
import LpgVendingMachine from './Pages/Products/LpgVendingMachine';
import Company from './Pages/About/Company';
import Leadership from './Pages/About/Leadership';
import Experts from './Pages/About/Experts';
import Clients from './Pages/About/Clients';
import Partners from './Pages/About/Partners';
import Testimonials from './Pages/Recognition/Testimonials';
import PressMedia from './Pages/Recognition/PressMedia';
import TechnologyPartnership from './Pages/Approach/Technology-Partnership';
import DevelopmentProcess from './Pages/Approach/Development-Process';
import SustainabilityPolicy from './Pages/Approach/Sustainability-Policy';
import PrivacyPolicy from './Pages/Approach/Privacy-Policy';
import Terms from './Pages/Approach/Terms';
import Careers from './Pages/Join-Us/Careers';
import RefferalProgram from './Pages/Join-Us/Refferal-Program';
import SoftwareDevelopment from './Pages/Services/Software-Development';
import UIDesign from './Pages/Services/UI-UX-Design';
import SoftwareTesting from './Pages/Services/Software-Testing';
import ApplicationServices from './Pages/Services/Application-Services';
import InfrastructureServices from './Pages/Services/Infrastructure-Services';
import DataAnalytics from './Pages/Services/Data-Analytics';
import CloudMigration from './Pages/Services/Cloud-Migration';
import DigitalTransformation from './Pages/Services/Digital-Transformation';
import ManagedITServices from './Pages/Services/Managed-IT-Services';
import ProcessAutomation from './Pages/Services/Process-Automation';
import DataExtractionMigration from './Pages/Services/Data-Extraction-Migration';
import DevOps from './Pages/Services/DevOps';
import Cybersecurity from './Pages/Services/Cybersecurity';
import AnimationMediaProduction from './Pages/Services/Animation-Media-Production';
import CRM from './Pages/Solutions/CRM';
import ERP from './Pages/Solutions/ERP';
import HumanResources from './Pages/Solutions/Human-Resources';
import SupplyChainManagement from './Pages/Solutions/Supply-Chain-Management';
import FleetManagement from './Pages/Solutions/Fleet-Management';
import KioskSoftware from './Pages/Solutions/Kiosk-Software';
import OperationsManagement from './Pages/Solutions/Operations-Management';
import AssetsManagement from './Pages/Solutions/Assets-Management';
import ProjectManagement from './Pages/Solutions/Project-Management';
import Ecommerce from './Pages/Solutions/Ecommerce';
import Healthcare from './Pages/Industries/Healthcare';
import Manufacturing from './Pages/Industries/Manufacturing';
import ProfessionalServices from './Pages/Industries/Professional-Services';
import AnimationMedia from './Pages/Industries/Animation-Media';
import Insurance from './Pages/Industries/Insurance';
import Retail from './Pages/Industries/Retail';
import TransportationLogistics from './Pages/Industries/Transportation-Logistics';
import Telecommunications from './Pages/Industries/Telecommunications';
import OilGas from './Pages/Industries/Oil-Gas';
import CloudServices from './Pages/Technologies/Cloud-Services';
import ArtificialIntelligence from './Pages/Technologies/Artificial-Intelligence';
import InternetOfThings from './Pages/Technologies/Internet-Of-Things';
import DataScience from './Pages/Technologies/Data-Science';
import BigData from './Pages/Technologies/Big-Data';
import Blockchain from './Pages/Technologies/Block-Chain';
import VirtualReality from './Pages/Technologies/Virtual-Reality';
import AugmentedReality from './Pages/Technologies/Augmented-Reality';
import Microsoft from './Pages/Platforms/Microsoft';
import AWS from './Pages/Platforms/AWS';
import GoogleCloud from './Pages/Platforms/Google-Cloud';
import ContactUs from './Pages/Contact-Us';

function App() {
  window.addEventListener('scroll', function() {
    var body = document.querySelector('body');
    var scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
      body.classList.add('scrolled');
    } else {
      body.classList.remove('scrolled');
    }
  });
  useEffect(() => {
    AOS.init({duration : 1000});
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="/about/company" element={<Company />} />
            <Route path="/products/lpg-vending-machine" element={<LpgVendingMachine />} />
            <Route path="/about/leadership" element={<Leadership />} />
            <Route path="/about/experts" element={<Experts />} />
            <Route path="/about/clients" element={<Clients />} />
            <Route path="/about/partners" element={<Partners />} />
            <Route path="/recognition/testimonials" element={<Testimonials />} />
            <Route path="/recognition/press-media" element={<PressMedia />} />
            <Route path="/approach/technology-partnership" element={<TechnologyPartnership />} />
            <Route path="/approach/development-process" element={<DevelopmentProcess />} />
            <Route path="/approach/sustainability-policy" element={<SustainabilityPolicy />} />
            <Route path="/approach/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/approach/terms-of-use" element={<Terms />} />
            <Route path="/join-us/careers" element={<Careers />} />
            <Route path="/join-us/refferal-program" element={<RefferalProgram />} />            
            <Route path="/services/software-development" element={<SoftwareDevelopment />} />           
            <Route path="/services/ui-ux-design" element={<UIDesign />} />          
            <Route path="/services/software-testing" element={<SoftwareTesting />} />        
            <Route path="/services/application-services" element={<ApplicationServices />} />       
            <Route path="/services/infrastructure-services" element={<InfrastructureServices />} />      
            <Route path="/services/data-analytics" element={<DataAnalytics />} />    
            <Route path="/services/cloud-migration" element={<CloudMigration />} />    
            <Route path="/services/digital-transformation" element={<DigitalTransformation />} />    
            <Route path="/services/managed-it-services" element={<ManagedITServices />} />    
            <Route path="/services/process-automation" element={<ProcessAutomation />} />  
            <Route path="/services/data-extraction-migration" element={<DataExtractionMigration />} /> 
            <Route path="/services/devOps" element={<DevOps />} />
            <Route path="/services/cybersecurity" element={<Cybersecurity />} />
            <Route path="/services/animation-media-production" element={<AnimationMediaProduction />} />
            <Route path="/solutions/crm" element={<CRM />} />
            <Route path="/solutions/erp" element={<ERP />} />
            <Route path="/solutions/human-resources" element={<HumanResources />} />
            <Route path="/solutions/supply-chain-management" element={<SupplyChainManagement />} />
            <Route path="/solutions/fleet-management" element={<FleetManagement />} />
            <Route path="/solutions/kiosk-software" element={<KioskSoftware />} />
            <Route path="/solutions/operations-management" element={<OperationsManagement />} />
            <Route path="/solutions/asset-management" element={<AssetsManagement />} />
            <Route path="/solutions/project-management" element={<ProjectManagement />} />
            <Route path="/solutions/ecommerce" element={<Ecommerce />} />
            <Route path="/industries/healthcare" element={<Healthcare />} />
            <Route path="/industries/manufacturing" element={<Manufacturing />} />
            <Route path="/industries/professional-services" element={<ProfessionalServices />} />
            <Route path="/industries/animation-media" element={<AnimationMedia />} />
            <Route path="/industries/insurance" element={<Insurance />} />
            <Route path="/industries/retail" element={<Retail />} />
            <Route path="/industries/transportation-logistics" element={<TransportationLogistics />} />
            <Route path="/industries/telecommunications" element={<Telecommunications />} />
            <Route path="/industries/oil-gas" element={<OilGas />} />
            <Route path="/technologies/cloud-services" element={<CloudServices />} />
            <Route path="/technologies/artificial-intelligence" element={<ArtificialIntelligence />} />
            <Route path="/technologies/internet-of-things" element={<InternetOfThings />} />
            <Route path="/technologies/data-science" element={<DataScience />} />
            <Route path="/technologies/big-data" element={<BigData />} />
            <Route path="/technologies/blockchain" element={<Blockchain />} />
            <Route path="/technologies/virtual-reality" element={<VirtualReality />} />
            <Route path="/technologies/augmented-reality" element={<AugmentedReality />} />
            <Route path="/platforms/microsoft" element={<Microsoft />} />
            <Route path="/platforms/aws" element={<AWS />} />
            <Route path="/platforms/google-cloud-platform" element={<GoogleCloud />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </Router>
        <ScrollToTop smooth component={<img src="assets/images/top.svg" alt=""/>} />
      </>
  );
}

export default App;
