import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const Cybersecurity = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Cybersecurity</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>Cybersecurity</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-4">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">We protect your IT assets and data from cyber threats</h2>
                        <p className="lead"><em>Are you looking for a way to protect your IT assets and data from cyber threats? Do you want to improve your cybersecurity posture and resilience? Do you need to comply with any regulatory and industry standards and best practices?</em></p>
                        <p className="lead"><em><span className="text-light">If you answered yes to any of these questions,</span> then you have come to the right place. We are a cybersecurity service provider that can help you secure your IT assets and data.<br/><br/>We offer a range of cybersecurity services that include:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h3 className="mb-3">Cybersecurity Assessment</h3>
                      <p>We can help you evaluate your current cybersecurity maturity and capabilities and identify the risks and vulnerabilities in your IT environment. We can also help you define your cybersecurity vision and goals and provide a roadmap and plan for your cybersecurity journey.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h3 className="mb-3">Cybersecurity Implementation</h3>
                      <p>We can help you implement cybersecurity solutions and technologies using various tools and techniques, such as antivirus, firewall, encryption, backup, etc. We can also help you integrate cybersecurity solutions with your existing systems and processes using APIs, microservices, and cloud platforms.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h3 className="mb-3">Cybersecurity Optimization</h3>
                      <p>We can help you optimize your cybersecurity performance, quality, and security using best practices and standards. We can also help you measure and monitor your cybersecurity outcomes and impacts using analytics tools and dashboards. We can also help you improve and innovate your cybersecurity solutions based on feedback and data.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h3 className="mb-3">Consulting &amp; Training</h3>
                      <p>We can help you design and develop your cybersecurity architecture and governance using frameworks and models. We can also help you comply with any regulatory and industry standards and best practices using compliance tools and audits. We can also help you train your staff on how to use the cybersecurity solutions effectively using workshops and e-learning modules.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mt-4 mb-4">
                      <p className="lead"><em>We have worked with clients from various industries and domains, such as healthcare, education, finance, retail, e-commerce, etc. We have delivered successful cybersecurity projects that have helped our clients protect their IT assets and data from cyber threats.</em></p>
                      <p className="lead"><em>Some of our projects include:</em></p>         
                    </Col>
                    <Col lg={6} md={12}>
                      <Row>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/health.svg" alt="health" />
                          </div>
                          <p>Conducting a cybersecurity assessment for a healthcare provider that identifies the risks and vulnerabilities in their IT environment and provides recommendations for improvement.</p>
                        </Col>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/education.svg" alt="education" />
                          </div>
                          <p>Implementing a cybersecurity solution for an education provider that encrypts their data in transit and at rest using encryption tools and techniques.</p>
                        </Col>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/database.svg" alt="database" />
                          </div>
                          <p>Optimizing the cybersecurity performance for a finance provider that reduces the number of cyber incidents and breaches using security monitoring and response tools and techniques.</p>
                        </Col>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/ai.svg" alt="ai" />
                          </div>
                          <p>Designing a cybersecurity architecture for a retail provider that aligns with their business strategy and objectives using frameworks and models.</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={12} className="text-center">
                      <img src="../assets/images/pages/thumbsup.svg" alt="thumbsup" className="w-100"/>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our cybersecurity services, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your cybersecurity needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default Cybersecurity;
