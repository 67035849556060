import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";

const Leadership = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Leadership</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>About</li>
                      <li>Leadership</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light mb-5">             
                  <Col lg={10}>
                    <h2 className="mb-3">At <strong>LIFO Technologies Pvt. Ltd.,</strong><br/>we are proud to have a team of visionary and experienced leaders</h2>
                    <p className="sub-title">Our leadership team is committed to creating a culture of excellence, innovation and collaboration at LIFO Technologies Pvt. Ltd., They inspire and empower our employees, customers and partners to achieve their goals and make a difference in the world.</p>                    
                  </Col>
              </Row>
              <Row className="justify-content-center">
                  <Col lg={3} md={4}>
                    <div className="team-single">
                        <div className="team-img">
                            <img src="../assets/images/team/leaders/nanda.jpg" alt="lifo nandakumar" className="w-100"/>
                        </div>
                        <h4>Nandakumar Mohan</h4>
                        <p>Founder &amp; Managing Director</p>
                    </div>
                  </Col>
                  <Col lg={3} md={4}>
                    <div className="team-single">
                        <div className="team-img">
                            <img src="../assets/images/team/leaders/hamesh.jpg" alt="lifo Hamesh" className="w-100"/>
                        </div>
                        <h4>Hamesh Noah</h4>
                        <p>CHIEF EXECUTIVE OFFICER</p>
                    </div>
                  </Col>
                  <Col lg={3} md={4}>
                    <div className="team-single">
                        <div className="team-img">
                            <img src="../assets/images/team/leaders/harini.jpg" alt="lifo Harini" className="w-100"/>
                        </div>
                        <h4>Harini S</h4>
                        <p>Director - Strategy</p>
                    </div>
                  </Col>
                  <Col lg={3} md={4}>
                    <div className="team-single">
                        <div className="team-img">
                            <img src="../assets/images/team/leaders/vasanth.jpg" alt="lifo vasanth" className="w-100"/>
                        </div>
                        <h4>Jayavasanth J</h4>
                        <p>Director - Tech &amp; Engineering</p>
                    </div>
                  </Col>
                  <Col lg={3} md={4}>
                    <div className="team-single">
                        <div className="team-img">
                            <img src="../assets/images/team/leaders/vivek.jpg" alt="lifo vivek" className="w-100"/>
                        </div>
                        <h4>Vivek Beri</h4>
                        <p>Director - BD / PR</p>
                    </div>
                  </Col>
                  <Col lg={3} md={4}>
                    <div className="team-single">
                        <div className="team-img">
                            <img src="../assets/images/team/leaders/vijay.jpg" alt="lifo vijay" className="w-100"/>
                        </div>
                        <h4>Vijay Hariharan</h4>
                        <p>GM - Finance</p>
                    </div>
                  </Col>           
                  {/*<Col lg={12} md={6}>
                    <Row>
                    </Row>                    
                  </Col>
                   <Col lg={{ span: 6, offset:0 }} md={6}>
                    <div className="team-single mt-5">
                        <h1>Nandhakumar<br/>Mohan</h1>
                        <p>Founder &amp; Managing Director</p>
                    </div>
                    <p>As the Founder of LIFO Technologies Pvt. Ltd., I would like to express my sincere gratitude for your interest in our company.<br/><br/>LIFO Technologies Pvt. Ltd., is a company that provides cutting-edge solutions for the IT sector, such as cloud computing, cybersecurity, artificial intelligence and data analytics. We are passionate about creating and delivering solutions that help our clients solve their IT challenges and achieve their business objectives, such as increasing efficiency, reducing costs, enhancing security and gaining insights.<br/><br/>We are also committed to being a socially and environmentally responsible company that supports the development of the IT industry and society, by adhering to ethical standards, promoting diversity and inclusion, and minimizing our environmental footprint. We have a skilled and diverse team of employees, clients and partners who share our passion and commitment, and who bring their expertise, experience and creativity to every project. Together, we are shaping the future of IT by providing innovative, reliable and cost-effective solutions that meet the needs and expectations of our clients and stakeholders.</p>
                    
                  </Col> */}
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default Leadership;
