import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const TechnologyPartnership = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Technology Partnership</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Approach</li>
                      <li>Technology Partnership</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={12} md={12}>
                     <h2 className="mb-3">Collaboration and Integration</h2>
                     <p className="lead"><em>We believe in the power of collaboration and integration to create the best solutions for our customers. That's why we partner with leading software companies that complement our products and services. Our technology partners offer integrations that enhance our functionality, extend our reach, and deliver more value to our customers.</em></p>          
                  </Col>
              </Row>
              <div className="line space"></div>
            </Container>
            <Container className="text-light mt-4">
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <h3 className="mb-3">Benefits of Becoming a Technology Partner</h3>
                      <p className="lead mb-4"><em>As a technology partner, you can enjoy the following benefits:</em></p>
                      <ul className="custom-list">
                        <li>Access to our API and documentation</li>
                        <li>Co-marketing opportunities and exposure on our website and social media</li>
                        <li>Dedicated support and guidance from our partner team</li>
                        <li>Joint customer success stories and case studies</li>
                        <li>Potential revenue sharing and referral incentives</li>
                      </ul>
                    </Col>
                    <Col lg={6} md={6}>
                      <h3 className="mb-3">How to become a Technology Partner?</h3>
                      <p className="lead"><em>If you are interested in becoming a technology partner</em></p>
                      <p className="lead mt-4"><em>please fill out this form and we will get back to you as soon as possible. We will review your application and evaluate the fit and feasibility of the integration. If approved, we will send you a technology partnership agreement1 that outlines the terms and conditions of the partnership. Once the agreement is signed, we will provide you with the necessary resources and support to launch and maintain the integration.</em></p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12}>
                      <h3 className="mb-3">Our Technology Partners</h3>
                      <p className="lead mb-5"><em>We are proud to showcase some of our technology partners that offer integrations with our products and services. These integrations help our customers streamline their workflows, automate their processes, and optimize their outcomes.</em></p>
                    </Col>
                    <Col lg={4} md={4} className="mb-4">
                        <Card className="card-custom partner h-100">
                            <Card.Img variant="top" src="../assets/images/pages/partners/g2.png" className="w-50 mx-auto" />
                            <Card.Body>
                                <Card.Title>G2</Card.Title>
                                <Card.Text>
                                G2 is the world's largest software marketplace that helps more than 60 million software buyers annually make smarter software decisions based on authentic peer reviews. Our integration with G2 allows our customers to access G2 ratings and reviews directly from our platform and compare different software options based on their needs.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} md={4} className="mb-4">
                        <Card className="card-custom partner h-100">
                            <Card.Img variant="top" src="../assets/images/pages/partners/crossbeam.png" className="w-50 mx-auto" />
                            <Card.Body>
                                <Card.Title>Crossbeam</Card.Title>
                                <Card.Text>
                                  Crossbeam is a partner ecosystem platform that helps companies find overlapping customers and prospects with their partners. Our integration with Crossbeam allows our customers to connect with us on Crossbeam and start sharing data overlaps, which enables faster partner vetting and onboarding.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} md={4} className="mb-4">
                        <Card className="card-custom partner h-100">
                            <Card.Img variant="top" src="../assets/images/pages/partners/pandium.png" className="w-50 mx-auto" />
                            <Card.Body>
                                <Card.Title>Pandium</Card.Title>
                                <Card.Text>
                                  Pandium is an integration platform specifically for building SaaS marketplaces. Our integration with Pandium allows our customers to easily discover, install, and manage integrations with other SaaS products that work with ours.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>  
                </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default TechnologyPartnership;
