import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const Telecommunications = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Telecommunications</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Industries</li>
                      <li>Telecommunications</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-4">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Our Solutions for Telecommunications Industries</h2>
                        <p className="lead"><em>The telecommunications industry is facing unprecedented challenges and opportunities in the digital age. Telecommunications companies need to deliver faster, cheaper, and more reliable services that meet the evolving needs of customers, regulators, and partners. They also need to leverage data, analytics, and technology to optimize their operations, improve their efficiency, and enhance their competitiveness.</em></p>
                        <p className="lead"><em>At LIFO, we offer software company solutions for telecommunications industries that help you achieve your business goals and overcome your challenges. Our solutions cover various aspects of the telecommunications value chain, such as:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Network Management Software</h4>
                      <p>We provide network management software that helps you monitor and manage your network performance, availability, and security. Our network management software enables you to troubleshoot and resolve network issues, optimize network capacity and utilization, and ensure network compliance and quality.</p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Customer Experience Software</h4>
                      <p>We develop customer experience software that helps you deliver personalized, seamless, and omnichannel customer experiences. Our customer experience software allows you to segment and target customers, provide self-service and assisted service options, manage customer feedback and loyalty, and measure customer satisfaction and retention.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Billing and Revenue Management Software</h4>
                      <p>We create billing and revenue management software that helps you process and manage your billing and revenue cycles. Our billing and revenue management software enables you to automate billing processes, integrate with payment gateways and systems, manage revenue recognition and reporting, and prevent revenue leakage and fraud.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Cloud Computing Software</h4>
                      <p>We design cloud computing software that helps you access and use our solutions on-demand, over the internet. Our cloud computing software offers various benefits, such as scalability, flexibility, security, reliability, and cost-effectiveness.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">05</div>
                      <h4 className="mb-3">IoT Software</h4>
                      <p>We build IoT software that helps you connect and manage your devices and sensors across various industries and applications. Our IoT software enables you to collect and analyze data from your devices, apply AI and ML to generate insights and actions, and create new value and opportunities.</p>          
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                      <p>LIFO Technologies Pvt. Ltd., is a trusted partner for telecommunications industries across the globe. We have extensive experience and expertise in developing customized solutions that meet the specific needs and challenges of each industry segment, such as wireless, wireline, cable, satellite, and internet service providers. We use the latest technologies and best practices to ensure quality, reliability, and scalability of our solutions.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for software company solutions for telecommunications industries that can help you transform your telecommunications performance and competitiveness contact us today for a free consultation. We would love to hear from you!</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default Telecommunications;
