import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Masonry from 'react-masonry-css';
import Card from 'react-bootstrap/Card';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const PressMedia = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    820: 2,
    500: 1
  };

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Press and Media</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Recognition</li>
                      <li>Press and Media</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={12}>
                     <h2 className="mb-3">Press and Media Release of LIFO Technologies Pvt. Ltd.</h2>
                     <p className="lead"><em>We are proud to share some of the Press, Media Release and recognition we have received for our software products and services.<br/>These awards reflect our commitment to excellence, innovation, and customer satisfaction.</em></p>          
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-5">
                <Masonry breakpointCols={breakpointColumnsObj} className="masonry_grids"  columnClassName="masonry_grids_column">
                    <Card className="card-custom press">
                        <Card.Img variant="top" src="../assets/images/press/virtual-cop-lifo.jpg" alt="virtual-cop-lifo" />
                        <Card.Body>
                            <Card.Subtitle className="mb-3">10th Nov 2020</Card.Subtitle>
                            <Card.Title className="mb-2">LIFO Technologies proudly announces launch of its premier Mobile Application named Virtual COP</Card.Title>
                            <Card.Text>As a brief introduction, this app acts as a two-way communication platform between police and general public. Police announcements, Public complaints, Hall of Fame and Volunteering registration are part of initial release. In summary, another important milestone and feather in LIFO's cap.</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="card-custom press">
                        <Card.Img variant="top" src="../assets/images/press/siliconindia-lifo.jpg" alt="siliconindia-lifo" />
                        <Card.Body>
                            <Card.Subtitle className="mb-3">02nd Feb 2017</Card.Subtitle>
                            <Card.Title className="mb-2">LIFO Technologies: Convert Your Visitors into Customers With E-Commerce Solutions</Card.Title>
                            <Card.Text>With a clear-cut understanding of the corporate world, Lifo is continuously striving to provide cutting-edge solutions and develop innovative business strategies for its customers..</Card.Text>
                            <Card.Link href="https://consultants.siliconindia.com/vendor/lifo-technologies-convert-your-visitors-into-customers-with-ecommerce-solutions-cid-821.html" target="_blank">Source Link</Card.Link>
                        </Card.Body>
                    </Card>
                    <Card className="card-custom press">
                        <Card.Img variant="top" src="../assets/images/press/kiliki-lifo.jpg" alt="kiliki-lifo" />
                        <Card.Body>
                            <Card.Subtitle className="mb-3">21st Feb 2020</Card.Subtitle>
                            <Card.Title className="mb-2">Director Rajamouli launching an 'Official' website of the Kiliki Language - www.kiliki.in, Developed by LIFO</Card.Title>
                            <Card.Text>The quirky 'Kiliki' language spoken by Kaalakeya tribes in the film Baahubali received wide-spread appreciation. It was popular lyricist and screenwriter Madan Karky who created this functionl language.</Card.Text>                          
                        </Card.Body>
                    </Card>
                    <Card className="card-custom press">
                        <Card.Img variant="top" src="../assets/images/press/ceoportal-lifo.jpg" alt="ceoportal-lifo" />
                        <Card.Body>
                            <Card.Subtitle className="mb-3">3rd June 2017</Card.Subtitle>
                            <Card.Title className="mb-2">Hindu Religious and Charitable Minister Mr.Sevoor S. Ramachandran launching an Mobile Application for Government School Teachers, Developed by LIFO</Card.Title>                            
                        </Card.Body>
                    </Card>
                    <Card className="card-custom press">
                        <Card.Img variant="top" src="../assets/images/press/ict-mission-lifo.jpg" alt="ict-mission-lifo" />
                        <Card.Body>
                            <Card.Subtitle className="mb-3">3rd June 2017</Card.Subtitle>
                            <Card.Title className="mb-2">Mr. Nandakumar, Managing Director of LIFO. Honored in Mangaldeep Green Temple Programme Conducted by ITC.</Card.Title>                            
                        </Card.Body>
                    </Card>
                    <Card className="card-custom press">
                        <Card.Img variant="top" src="../assets/images/press/pondy-cm-lifo.jpg" alt="pondy-cm-lifo" />
                        <Card.Body>
                            <Card.Subtitle className="mb-3">3rd June 2017</Card.Subtitle>
                            <Card.Title className="mb-2">V Narayanasamy, Chief Minister of Puducherry Appreciated LIFO Team for their achievements.</Card.Title>                            
                        </Card.Body>
                    </Card>
                    <Card className="card-custom press">
                        <Card.Img variant="top" src="../assets/images/press/exnora-lifo.jpg" alt="exnora-lifo" />
                        <Card.Body>
                            <Card.Subtitle className="mb-3">6th Nov 2017</Card.Subtitle>
                            <Card.Title className="mb-2">State wide launch of ExNoRa MustQuitO Website@SBOA_Chennai by Mr.MB Nirmal, Mr.Radhakrishnan IAS and Mr.Arya Actor.</Card.Title>                            
                        </Card.Body>
                    </Card>
                </Masonry>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default PressMedia;
