import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const HumanResources = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Human Resources</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Solutions</li>
                      <li>Human Resources</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Outsource Your HR Functions and Focus on Your Core Business</h2>
                        <p className="lead"><em>Do you want to outsource your HR functions and focus on your core business?<br/>Do you want to save time and money on managing your HR tasks and compliance?<br/>Do you want to access expert HR advice and support whenever you need it?</em></p>
                        <p className="lead"><em><span className="text-light">If you answered yes to any of these questions,</span> then you need Human Resource Solutions.</em></p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={7} md={12}>
                        <h2 className="mb-3">What is Human Resource Service?</h2>
                        <p className="lead"><em>Human Resource Service is a professional service that helps you outsource your HR functions and responsibilities. Human Resource Service allows you to:</em></p>
                        <ul className="custom-list">
                            <li>Delegate your HR tasks and compliance to a team of qualified and experienced HR professionals</li>
                            <li>Access a range of HR services, such as payroll, benefits, recruitment, training, performance, policies, and more</li>
                            <li>Get expert HR advice and support on any HR issue or challenge</li>
                            <li>Stay updated on the latest HR laws and regulations</li>
                            <li>Reduce your HR risks and liabilities</li>
                        </ul>
                        <p>Human Resource Service is not just a service for small businesses. It is a flexible and scalable solution that supports businesses of all sizes and industries. Human Resource Service helps you create a hassle-free and compliant HR environment that supports your business growth.</p>
                    </Col>
                    <Col lg={5} md={12} className="text-center mt-4">
                      <img src="../assets/images/pages/human-resources.svg" alt="human-resources" className="w-75"/>
                    </Col>
                    <div className="line space"></div>
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">Why you need Human Resource Service for your business?</h2>
                        <p className="lead"><em>Human Resource Service can help you achieve your business goals in many ways.<br/>Here are some of the benefits of using Human Resource Service:</em></p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Save time and<br/>money</h4>
                      <p>Human Resource Service helps you save time and money by taking care of your HR tasks and compliance for you. You can also save money by avoiding penalties, fines, lawsuits, and turnover costs.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Focus on your core business</h4>
                      <p>Human Resource Service helps you focus on your core business by freeing up your time and resources from HR distractions. You can also focus on your strategic goals and objectives without worrying about HR issues or challenges.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Access expert HR advice and support</h4>
                      <p>Human Resource Service helps you access expert HR advice and support whenever you need it. You can also access a range of HR services that suit your specific needs and budget.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">04</div>                      
                      <h4 className="mb-3">Improve employee satisfaction</h4>
                      <p>Human Resource Service helps you improve employee satisfaction and retention by providing them with professional and timely Human Resource service.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">How to choose the best Human Resource Service for your business?</h2>
                        <p className="lead"><em>There are many Human Resource Service providers available in the market that cater to different industries, sizes, budgets, and needs. You need to do some research and compare the features, benefits, pricing, reviews, etc. of different Human Resource Service providers before making a decision.</em></p>
                        <p className="lead"><em>Some of the factors that you should consider when choosing a Human Resource Service provider are:</em></p>         
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">01</div> 
                        <h4 className="mb-3">The size and nature of your business</h4>
                        <p>You need to choose a Human Resource Service provider that matches your business size and industry. For example, if you are a small business, you may need a simple and affordable Human Resource Service provider that covers the basic functions of human resources management. If you are a large enterprise, you may need a more complex and robust Human Resource Service provider that supports multiple departments, locations, and languages.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">02</div>
                        <h4 className="mb-3">The features and functions that you need</h4>
                        <p>You need to choose a Human Resource Service provider that offers the features and functions that you need for your business goals. For example, if you want to outsource your payroll function, you may need a Human Resource Service provider that has payroll processing, taxation, compliance, and reporting features. If you want to outsource your recruitment function, you may need a Human Resource Service provider that has applicant tracking, screening, interviewing, and hiring features.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">03</div>
                        <h4 className="mb-3">The ease of use and customization</h4>
                        <p>You need to choose a Human Resource Service provider that is easy to use and communicate with. You don’t want to deal with complicated contracts or processes or have difficulty reaching or understanding your Human Resource Service provider. You want a Human Resource Service provider that has a clear and transparent service agreement, simple and streamlined processes, and responsive and friendly communication.</p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                        <h4 className="mb-3">The quality and reliability</h4>
                        <p>You need to choose a Human Resource Service provider that is quality-driven and reliable. You don’t want to compromise on the quality or timeliness of your HR service or face any errors or issues with your Human Resource Service provider. You want a Human Resource Service provider that has a team of qualified and experienced HR professionals, a proven track record of delivering high-quality service, and a strong reputation in the market.</p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">05</div>
                        <h4 className="mb-3">The support and guidance</h4>
                        <p>You need to choose a Human Resource Service provider that provides you with support and guidance throughout your HR journey. You don’t want to be left alone or frustrated when you encounter any problems or challenges with your HR service or have any questions or doubts about your HR issues or compliance. You want a Human Resource Service provider that has a dedicated support team that is available 24/7 via phone, email, chat, or web. You also want a Human Resource Service provider that has a comprehensive knowledge base, tutorials, videos, webinars, blogs, forums, and other resources that can help you learn more about the service</p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our CRM solutions, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your animation and media needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default HumanResources;
