import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Privacy Policy</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Approach</li>
                      <li>Privacy Policy</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={12} md={12}>
                     <h2 className="mb-3">Company Privacy Policy</h2>
                     <p className="lead"><em>This privacy policy explains how LIFO Technologies Pvt. Ltd., ("we", "us", "our") collects, uses, shares and protects your personal information when you visit our website, use our products or services, or interact with us in any other way. Please read this policy carefully and contact us if you have any questions or concerns.</em></p> 
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-4">
                <Row className="text-light">
                    <Col lg={12} md={12}>
                        <h3>What information do we collect?</h3>
                        <p className="lead"><em>We may collect the following types of information from you:</em></p>
                        <p>Information that you provide to us voluntarily, such as your name, email address, phone number, payment details, feedback, preferences and other information that you submit through our website, forms, surveys, applications or other channels.</p>
                        <p>Information that we collect automatically, such as your IP address, browser type, device type, operating system, location data, cookies and other identifiers, browsing history, usage data and other information that we obtain through our website, products or services or third-party tools.</p>
                        <p>Information that we obtain from other sources, such as our partners, affiliates, vendors, social media platforms, public databases and other third-party providers.</p>
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>How do we use your information?</h3>
                        <p className="lead"><em>We may use your information for the following purposes:</em></p>
                        <p>To provide you with our website, products or services and to fulfill your requests, orders, inquiries and transactions.</p>
                        <p>To communicate with you about our website, products or services and to send you updates, newsletters, promotions and other marketing materials that may interest you.</p>
                        <p>To improve our website, products or services and to develop new features, functionalities and offerings.</p>
                        <p>To personalize your experience and to tailor our website, products or services to your preferences and needs.</p>
                        <p>To analyze our website performance, user behavior, market trends and customer satisfaction.</p>
                        <p>To protect our website, products or services and to prevent fraud, abuse, unauthorized access and other illegal activities.</p>
                        <p>To comply with our legal obligations, regulations and policies and to enforce our terms of service and other agreements.</p>
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>How do we share your information?</h3>
                        <p className="lead"><em>We may share your information with the following parties:</em></p>
                        <p>Our affiliates and subsidiaries that provide us with support or services related to our website, products or services.</p>
                        <p>Our partners and vendors that help us with our website operations, product development, marketing campaigns, payment processing and other business functions.</p>
                        <p>Our customers and users that you interact with through our website, products or services or that you authorize us to share your information with.</p>
                        <p>Other third parties that we collaborate with for research, analysis or other purposes that are consistent with this policy.</p>
                        <p>Other third parties that we are required or permitted to share your information with by law or for legal reasons.</p>                        
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>How do we protect your information?</h3>
                        <p>We take reasonable measures to protect your information from unauthorized access, use, disclosure and destruction. We use encryption, firewalls, passwords, and other security technologies to safeguard your information. We also limit access to your information to our employees, contractors, and agents who need it to perform their duties. However, we cannot guarantee that your information is completely secure and free from risks. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account.</p>                      
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>How do we store your information?</h3>
                        <p>We store your information on servers located in [Country Name] or in other countries where we or our service providers operate. We may transfer your information across borders and subject it to different laws and jurisdictions. By using our website, products or services, you consent to the transfer and storage of your information as described in this policy.</p>                      
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>How long do we keep your information?</h3>
                        <p>We keep your information for as long as necessary to fulfill the purposes for which we collected it or as required by law. We may retain some of your information even after you stop using our website, products or services for legal, tax, audit, or regulatory purposes. We will delete or anonymize your information when it is no longer needed or when you request us to do so.</p>                      
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>What are your choices and rights?</h3>
                        <p className="lead"><em>You have the following choices and rights regarding your information:</em></p>
                        <ul className="custom-list">
                            <li>You can access, update, correct, or delete your information by logging into your account or by contacting us.</li>
                            <li>You can opt out of receiving marketing emails from us by clicking on the unsubscribe link in the email or by contacting us.</li>
                            <li>You can manage your cookie preferences by adjusting your browser settings or by using our cookie consent tool.</li>
                            <li>You can request a copy of your information or ask us to transfer it to another party by contacting us.</li>
                            <li>You can object to or restrict our processing of your information for certain purposes by contacting us.</li>
                            <li>You can withdraw your consent to our use of your information at any time by contacting us.</li>
                        </ul>                    
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>How do we update this policy?</h3>
                        <p>We may update this policy from time to time to reflect changes in our practices, laws, or regulations. We will notify you of any material changes by posting a notice on our website or by sending you an email. Your continued use of our website, products or services after the update constitutes your acceptance of the revised policy.</p>                  
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>How do you contact us?</h3>
                        <p><strong>LIFO Technologies Pvt. Ltd.,</strong><br/>12/7, 1st Main Road, SIDCO Nagar,<br/>Villivakkam, Chennai - 600049.<br/>+91 44 4387 6222</p>                  
                    </Col>
                </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Footer />
    </div>
  );
};

export default PrivacyPolicy;
