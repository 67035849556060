import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Masonry from 'react-masonry-css';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const Testimonials = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    820: 1,
    500: 1
  };
  
  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Testimonials</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Recognition</li>
                      <li>Testimonials</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={8} md={8}>
                     <h2 className="mb-3">Don't just take our word for it. </h2>
                     <p className="lead"><em>See what our happy customers have to say about our products and services</em></p>          
                  </Col>
                  <Col lg={5} md={4}>
                    
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-5">
                <Row className="text-light">
                  <Col lg={12} md={12}>
                    <Masonry breakpointCols={breakpointColumnsObj} className="masonry_grids"  columnClassName="masonry_grids_column">
                      {/* <div className="testimonial" data-aos="fade-down">
                        <div className="testi_quote">
                            <p>LIFO Staff and Support are second to none.<br/>Very clear approach and straight forward, easy to work with.<br/>Provides valuable suggestions based on our budgets to complete projects on time.</p>
                            <div className="triangle"></div>
                        </div>
                        <div className="testi_img">
                          <img src="../assets/images/client/photos/jayavasanth.png" alt="" />
                          <h4>- J. Jayavasanth, <span>Business Owner @<br/>Third Eye Info Technology,India</span></h4>
                        </div>
                      </div> */}
                      <div className="testimonial" data-aos="fade-down">
                        <div className="testi_quote">
                            <p>We are thrilled to share our heartfelt testimonial for the  reliable information technology team that brought our e-commerce website and content management system to life. Their dedication, expertise and unwavering commitment to excellence have  transformed our online presence. Their thoughtful approach allowed them to create a customized solution that perfectly aligned with our vision. <br></br><br></br>The content management system they developed for us is a game-changer. It empowers our team to effortlessly update product listings, blog posts, and other content, ensuring that our website remains fresh and relevant. Communication with the team was exceptional throughout the entire project. They were always responsive to our inquiries, receptive to feedback, and proactive in addressing any challenges that arose. This level of collaboration fostered a sense of trust and partnership, making the entire process enjoyable and stress-free. Thanks to their hard work and expertise, our e-commerce website  has become a powerful tool in driving our business forward.</p>
                            <div className="triangle"></div>
                        </div>
                        <div className="testi_img">
                          <img src="../assets/images/client/photos/user.png" alt="" />
                          <h4>- Sam, <span>President @<br/>NYB Distributors,USA</span></h4>
                        </div>
                      </div>
                      <div className="testimonial" data-aos="fade-down">
                        <div className="testi_quote">
                            <p>LIFO Technologies has been supporting our business for the past 9 years in both the creation and implementation of new and tailored software, both in mobile and web applications. The developers have been a great pleasure to work with, and we are extremely happy with their work. They are reliable, thorough, smart, available, extremely good communicators, and very friendly! If you are looking for an overseas partner to work with on a software development project, I would highly recommend LIFO Team. We are and will continue to work with them for the long term.</p>
                            <div className="triangle"></div>
                        </div>
                        <div className="testi_img">
                          <img src="../assets/images/client/photos/user.png" alt="" />
                          <h4>- Madan Narayanamurthy, <span>Digital Strategist @<br/>NetConstructs Digital,Australia</span></h4>
                        </div>
                      </div>
                    </Masonry>
                  </Col>
                </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default Testimonials;
