import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const DevOps = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>DevOps</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>DevOps</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Improve your collaboration, quality, and reliability</h2>
                        <p className="lead"><em>Are you looking for a way to accelerate your software development and delivery? Do you want to improve your collaboration, quality, and reliability? Do you need to adopt the best practices and tools for DevOps?</em></p>
                        <p className="lead"><em><span className="text-light">If you answered yes to any of these questions,</span> then you have come to the right place. We are a DevOps service provider that can help you implement DevOps in your organization.<br/><br/>We offer a range of DevOps services that include:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h3 className="mb-3">DevOps Assessment</h3>
                      <p>We can help you assess your current DevOps maturity and capabilities and identify the gaps and opportunities for improvement. We can also help you define your DevOps vision and goals and provide a roadmap and plan for your DevOps journey.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h3 className="mb-3">DevOps Implementation</h3>
                      <p>We can help you implement DevOps in your organization using various tools and technologies, such as Git, Jenkins, Docker, Kubernetes, etc. We can also help you integrate DevOps with your existing systems and processes using APIs, microservices, and cloud platforms.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h3 className="mb-3">DevOps Optimization</h3>
                      <p>We can help you optimize your DevOps performance, quality, and security using best practices and standards. We can also help you measure and monitor your DevOps outcomes and impacts using analytics tools and dashboards. We can also help you improve and innovate your DevOps solutions based on feedback and data.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h3 className="mb-3">Consulting &amp; Training</h3>
                      <p>We can help you design and develop your DevOps architecture and governance using frameworks and models. We can also help you comply with any regulatory and industry standards and best practices using compliance tools and audits. We can also help you train your staff on how to use the DevOps tools and techniques effectively using workshops and e-learning modules.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mt-4 mb-4">
                      <p className="lead"><em>We have worked with clients from various industries and domains, such as healthcare, education, finance, retail, e-commerce, etc. We have delivered successful DevOps projects that have helped our clients accelerate their software development and delivery. </em></p>
                      <p className="lead"><em>Some of our projects include:</em></p>         
                    </Col>
                    <Col lg={6} md={12}>
                      <Row>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/health.svg" alt="health" />
                          </div>
                          <p>Implementing DevOps for a healthcare provider that enables faster deployment and testing of new features and functionalities for their web application.</p>
                        </Col>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/education.svg" alt="education" />
                          </div>
                          <p>Implementing DevOps for an education provider that enables better collaboration and communication between their developers and instructors for their online learning platform.</p>
                        </Col>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/database.svg" alt="database" />
                          </div>
                          <p>Implementing DevOps for a finance provider that enables higher quality and reliability of their software products and services for their customers.</p>
                        </Col>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/ai.svg" alt="ai" />
                          </div>
                          <p>Implementing DevOps for a retail provider that enables more frequent and consistent delivery of their software updates and enhancements for their e-commerce website.</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={12} className="text-center">
                      <img src="../assets/images/pages/thumbsup.svg" alt="thumbsup" className="w-100"/>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our DevOps services, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your DevOps needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default DevOps;
