import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const ArtificialIntelligence = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Artificial Intelligence</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Technologies</li>
                      <li>Artificial Intelligence</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Artificial Intelligence Technology</h2>
                        <p className="lead"><em>Artificial intelligence (AI) is the ability of a digital computer or computer-controlled robot to perform tasks commonly associated with intelligent beings. AI can perceive, synthesize, and infer information from various sources and domains. AI can also learn from past experience and adapt to new situations</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={8} md={12} className="">
                      <h2 className="mb-3">What AI can do?</h2>
                      <p className="lead"><em>AI can perform a wide range of tasks that require human intelligence, such as:</em></p> 
                      <ul className="custom-list m-0">
                        <li><strong>Speech recognition:</strong> AI can understand and generate natural language speech, such as Siri and Alexa.</li>
                        <li><strong>Computer vision: </strong>AI can process and analyze visual information, such as face recognition, object detection, and self-driving cars.</li>
                        <li><strong>Translation: </strong>AI can translate between different natural languages, such as Google Translate.</li>
                        <li><strong>Game playing: </strong>AI can compete at the highest level in strategic games, such as chess and Go.</li>
                        <li><strong>Generative or creative tools: </strong>AI can create new content, such as ChatGPT and AI art.</li>
                      </ul>
                    </Col>
                    <Col lg={4} md={12} className="text-center">
                      <img src="../assets/images/pages/artificial-intelligence.svg" alt="artificial-intelligence" className="w-75"/>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Benefits of AI Technology</h2>
                      <p className="lead"><em>AI technology offers many benefits for businesses and individuals, such as:</em></p> 
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/rocket.svg" alt="rocket" />
                      </div>
                      <h4 className="mb-3">Faster time to market</h4>
                      <p>AI can help developers accelerate development with quick deployments and testing of new ideas and applications.</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/line-chart.svg" alt="line-chart" />
                      </div>
                      <h4 className="mb-3">Improved Efficiency</h4>
                      <p>AI can automate repetitive and tedious tasks, such as data entry, customer service, and quality control.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/mission.svg" alt="mission" />
                      </div>
                      <h4 className="mb-3">Enhanced Accuracy</h4>
                      <p>AI can reduce human errors and biases, such as medical diagnosis, fraud detection, and credit scoring.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/ai.svg" alt="ai" />
                      </div>
                      <h4 className="mb-3">Better Insights</h4>
                      <p>AI can analyze large and complex data sets, such as big data analytics, sentiment analysis, and recommendation systems.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Types of AI Technology</h2>
                      <p className="lead"><em>There are different types of AI technology based on the level of intelligence and functionality they exhibit:</em></p> 
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Narrow AI</h4>
                      <p>This is the most common type of AI technology that can perform specific tasks within a limited domain, such as image recognition or speech recognition. Narrow AI is also known as weak AI or applied AI.</p>
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">General AI</h4>
                      <p>This is the hypothetical type of AI technology that can perform any intellectual task that a human can do, such as reasoning, learning, planning, and problem-solving. General AI is also known as strong AI or human-level AI.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Super AI</h4>
                      <p>This is the hypothetical type of AI technology that can surpass human intelligence and capabilities in all domains, such as creativity, wisdom, and social skills. Super AI is also known as artificial superintelligence (ASI) or superhuman AI.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you're interested in learning more about our artificial intelligence technology or want to get started with a free trial, please contact us today. We'll be happy to assist you with any questions or concerns you may have.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default ArtificialIntelligence;
