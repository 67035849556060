import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const ManagedITServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Managed IT Services</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>Managed IT Services</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Focus on your core business</h2>
                        <p className="lead"><em>Are you looking for a way to reduce your IT costs, improve your IT performance, and focus on your core business? Do you want to outsource your IT operations to a trusted and experienced partner? Do you need to access the latest technologies and solutions without investing in expensive hardware and software?</em></p>
                        <p className="lead"><em>If you answered yes to any of these questions, then you have come to the right place. We are a managed IT service provider that can help you manage your IT infrastructure and operations. We offer a range of managed IT services that include:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={4} md={6}>
                      <div className="nos">01</div>
                      <h3 className="mb-3">Managed Network</h3>
                      <p>We can help you design, install, configure, monitor, and maintain your network devices, such as routers, switches, firewalls, etc. We can also help you secure your network from cyberattacks and ensure its availability and performance.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">02</div>
                      <h3 className="mb-3">Managed Cloud</h3>
                      <p>We can help you migrate your applications and data to the cloud platform of your choice, such as AWS, Azure, Google Cloud, etc. We can also help you manage your cloud resources and operations using cloud management tools and practices.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">03</div>
                      <h3 className="mb-3">Managed Security</h3>
                      <p>We can help you protect your IT assets and data from cyber threats using security solutions, such as antivirus, firewall, encryption, backup, etc. We can also help you comply with any regulatory and industry standards and best practices using security audits and assessments.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">04</div>
                      <h3 className="mb-3">Managed Support</h3>
                      <p>We can help you troubleshoot and resolve any IT issues that may arise using remote or onsite support. We can also help you update and maintain your IT systems and applications using patch management and maintenance services.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">05</div>
                      <h3 className="mb-3">Consulting &amp; Training</h3>
                      <p>We can help you plan and implement your IT projects and initiatives using project management tools and methodologies. We can also help you train your staff on how to use the IT solutions effectively using workshops and e-learning modules.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-4">
                      <p className="lead"><em>We have worked with clients from various industries and domains, such as healthcare, education, finance, retail, e-commerce, etc. We have delivered successful managed IT projects that have helped our clients reduce their IT costs, improve their IT performance, and focus on their core business. </em></p>
                      <p className="lead"><em>Some of our projects include:</em></p>         
                    </Col>
                    <Col lg={6} md={12}>
                      <Row>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/health.svg" alt="healthcare" />
                          </div>
                          <p>Managing the network infrastructure for a healthcare provider that connects multiple clinics, hospitals, and labs across the country.</p>
                        </Col>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/education.svg" alt="education" />
                          </div>
                          <p>Migrating the applications and data for an education provider to the cloud platform that enables online learning and collaboration for students and instructors.</p>
                        </Col>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/database.svg" alt="database" />
                          </div>
                          <p>Protecting the IT assets and data for a finance provider from cyberattacks using security solutions that comply with PCI-DSS standards.</p>
                        </Col>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/ai.svg" alt="ai" />
                          </div>
                          <p>Planning and implementing an IT project for an e-commerce provider that upgrades their IT systems and applications using agile methods.</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={12} className="text-center">
                      <img src="../assets/images/pages/thumbsup.svg" alt="thumbsup" className="w-100"/>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our managed IT services, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your cloud needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default ManagedITServices;
