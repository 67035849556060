import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Values = () => {
return (
    <section className="section bg-theme">
        <Container>
            <Row className="text-light text-center justify-content-center">
                <Col lg={8} md={8}>                                                               
                    <h2 className="mb-3">At <strong>LIFO Technologies Pvt. Ltd.,</strong><br/>we are driven by these core values</h2>
                    <p className="mb-5 sub-title">At LIFO Technologies Pvt. Ltd., We put our customers at the center of everything we do.<br/>We listen to their needs, expectations, and feedback and deliver solutions that exceed them. We build long-term relationships with our customers based on trust, respect, and mutual benefit.</p>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={3} md={6} className="values text-light">
                    <div class="icon" data-aos="fade-down" data-aos-duration="600">
                    <img src="../assets/images/innovation.svg" className="vicon" alt="innovation" />
                    </div>
                    <h3>Innovation</h3>
                    <p className="sub-title">Encouraging creativity, continuous improvement, and the development of new ideas in IT.</p>
                </Col>
                <Col lg={3} md={6} className="values text-light">
                <div class="icon" data-aos="fade-down" data-aos-duration="800">
                    <img src="../assets/images/collaboration.svg" className="vicon" alt="collaboration" />
                    </div>
                    <h3>Collaboration</h3>
                    <p className="sub-title">Promoting teamwork, open communication, and cooperation across the organization.</p>
                </Col>
                <Col lg={3} md={6} className="values text-light">
                <div class="icon" data-aos="fade-down" data-aos-duration="1000">
                    <img src="../assets/images/excellence.svg" className="vicon" alt="excellence" />
                    </div>
                    <h3>Excellence</h3>
                    <p className="sub-title">Providing world-class services and achieving the expected quality each time.</p>
                </Col>
                <Col lg={3} md={6} className="values text-light">
                    <div class="icon" data-aos="fade-down" data-aos-duration="1200">
                    <img src="../assets/images/credibility.svg" className="vicon" alt="credibility" />
                    </div>
                    <h3>Credibility</h3>
                    <p className="sub-title">Demonstrating honesty, transparency, and ethical behavior in all actions and decisions.</p>
                </Col>
            </Row>
        </Container>
        <div className="line bottom"></div>
    </section>
    );
};

export default Values;
