import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const CRM = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>CRM</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Solutions</li>
                      <li>CRM</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Grow Your Business Faster and Smarter with<br/>Customer Relationship Management</h2>
                        <p className="lead"><em>Do you want to grow your business faster and smarter?<br/>Do you want to attract more customers, increase sales, and improve customer satisfaction?<br/>Do you want to streamline your business processes and save time and money?</em></p>
                        <p className="lead"><em><span className="text-light">If you answered yes to any of these questions,</span> then you need CRM</em></p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={8} md={12}>
                        <h2 className="mb-3">What is CRM?</h2>
                        <p className="lead"><em>CRM stands for Customer Relationship Management. It is a technology that helps you manage all your interactions with your current and potential customers. CRM software allows you to store and access customer data, track sales opportunities, automate marketing campaigns, provide customer service, and generate insights and reports.</em></p>
                        <p className="lead"><em>CRM software is not just a tool for salespeople. It is a comprehensive solution that supports every department in your organization, from marketing to service to finance. CRM software helps you create a unified view of your customers and deliver personalized experiences across every touchpoint.</em></p>
                    </Col>
                    <Col lg={4} md={12} className="text-center mt-2">
                      <img src="../assets/images/pages/crm.svg" alt="crm" className="w-75"/>
                    </Col>
                    <div className="line space"></div>
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">Why you need CRM for your business?</h2>
                        <p className="lead"><em>CRM software can help you achieve your business goals in many ways. Here are some of the benefits of using CRM software:</em></p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Increase customer loyalty and retention</h4>
                      <p>CRM software helps you understand your customers better and anticipate their needs and preferences. You can also use CRM software to segment your customers based on various criteria and send them targeted offers and messages. By providing relevant and timely communication, you can build trust and loyalty with your customers and reduce churn.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Boost sales performance and revenue</h4>
                      <p>CRM software helps you manage your sales pipeline and optimize your sales process. You can use CRM software to track leads, prospects, and opportunities, assign tasks and follow-ups, schedule appointments and calls, send quotes and proposals, and close deals faster. You can also use CRM software to upsell and cross-sell to existing customers and generate referrals.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Improve marketing effectiveness and ROI</h4>
                      <p>CRM software helps you plan and execute your marketing campaigns across multiple channels, such as email, social media, web, and mobile. You can use CRM software to create landing pages, forms, surveys, newsletters, and more. You can also use CRM software to measure the performance of your campaigns and optimize them based on real-time feedback and analytics.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Enhance customer service and satisfaction</h4>
                      <p>CRM software helps you provide fast and efficient customer service across multiple channels, such as phone, email, chat, web, and social media. You can use CRM software to create a knowledge base, FAQs, forums, and other self-service options for your customers. You can also use CRM software to manage tickets, cases, complaints, feedback, and reviews. By resolving customer issues quickly and effectively, you can increase customer satisfaction and loyalty.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">05</div>
                      <h4 className="mb-3">Save time and money</h4>
                      <p>CRM software helps you automate repetitive tasks and workflows that otherwise would take a lot of time and resources. You can use CRM software to send automated emails, reminders, notifications, alerts, invoices, receipts, etc. You can also use CRM software to integrate with other tools and systems that you use in your business, such as accounting, billing, inventory, e-commerce, etc. By automating and integrating your business processes, you can save time and money and avoid errors.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">How to choose the best CRM Software for your business?</h2>
                        <p className="lead"><em>There are many CRM software solutions available in the market that cater to different industries, sizes, budgets, and needs. You need to do some research and compare the features, benefits, pricing, reviews, etc. of different CRM software solutions before making a decision.</em></p>
                        <p className="lead"><em>Some of the factors that you should consider when choosing a CRM software solution are::</em></p>         
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">01</div>
                        <h4 className="mb-3">The size and nature of your business</h4>
                        <p>You need to choose a CRM software solution that matches your business size and industry. For example, if you are a small business, you may need a simple and affordable CRM software solution that covers the basic functions of customer relationship management. If you are a large enterprise, you may need a more complex and robust CRM software solution that supports multiple departments, locations, and currencies.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">02</div>
                        <h4 className="mb-3">The features and functions that you need</h4>
                        <p>You need to choose a CRM software solution that offers the features and functions that you need for your business goals. For example, if you want to improve your marketing campaigns, you may need a CRM software solution that has email marketing, social media marketing, web analytics, and landing page creation features. If you want to provide better customer service, you may need a CRM software solution that has ticketing system, knowledge base, chatbot, and survey features.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">03</div>
                        <h4 className="mb-3">The ease of use and customization</h4>
                        <p>You need to choose a CRM software solution that is easy to use and customize according to your needs. You don't want to spend too much time and effort learning how to use the system or hiring developers to customize it for you. You want a CRM software solution that has a user-friendly interface, intuitive navigation, and flexible configuration options.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">04</div>
                        <h4 className="mb-3">The security and reliability</h4>
                        <p>You need to choose a CRM software solution that is secure and reliable. You don't want to risk losing or compromising your customer data or facing downtime or glitches in the system. You want a CRM software solution that has strong encryption, backup, recovery, and disaster recovery features. You also want a CRM software solution that has high availability, performance, and scalability.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">05</div>
                        <h4 className="mb-3">The support and guidance</h4>
                        <p>You need to choose a CRM software solution that provides you with support and guidance throughout your CRM journey. You don't want to be left alone or frustrated when you encounter any problems or challenges with the system. You want a CRM software solution that has a dedicated support team that is available 24/7 via phone, email, chat, or web. You also want a CRM software solution that has a comprehensive knowledge base, tutorials, videos, webinars, blogs, forums, and other resources that can help you learn more about the system</p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our CRM solutions, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your animation and media needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default CRM;
