import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const SoftwareDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Software Development</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>Software Development</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={8} md={12}>
                     <h2 className="mb-3">Deliver high-quality software solutions</h2>
                     <p className="lead"><em>We are a team of experienced and passionate software developers who can help you turn your ideas into reality. Whether you need a custom web application, a mobile app, a desktop software, or a cloud solution, we have the skills and expertise to deliver high-quality software solutions that meet your requirements and exceed your expectations.</em></p>          
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-5">
                <Row className="text-light">
                    <Col lg={5} md={12} className="">
                      <img src="../assets/images/pages/software-developement.svg" alt="software-developement" className="w-100 mob-mb-4"/>
                    </Col>
                    <Col lg={7} md={12}>
                      <h2 className="mb-4">Why Choose Us?</h2>
                      <Row className="text-light">
                        <Col lg={4} md={6}>
                            <div className="nos">01</div>
                            <p>We follow the best practices of software development, such as agile methodology, code reviews, testing, and documentation.</p>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="nos">02</div>
                            <p>We use the latest technologies and tools to create software that is fast, secure, scalable, and user-friendly.</p>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="nos">03</div>
                            <p>We communicate with you throughout the project to ensure that we understand your needs and deliver the software that you want.</p>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="nos">04</div>
                            <p>We offer cost effective/competitive pricing and flexible payment options to suit your budget and timeline.</p>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="nos">05</div>
                            <p>We provide ongoing support and maintenance to ensure that your software runs smoothly and stays up-to-date.</p>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="nos">06</div>
                            <p>We also offer enhancements and upgrades to add new features or improve existing one with new technologies.</p>
                        </Col>
                      </Row>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                      <h2 className="mb-3">What We Offer?</h2>
                      <p className="lead"><em>We offer a wide range of software development services, including:</em></p>          
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={4} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/website.svg" alt="website" />
                      </div>
                      <h3 className="mb-3">Web Development</h3>
                      <p>We can create stunning and responsive websites and web applications using HTML, CSS, JavaScript, React, Angular, Node.js, PHP, Laravel, Django, Ruby on Rails, and more.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/app.svg" alt="mobile app" />
                      </div>
                      <h3 className="mb-3">Mobile Development</h3>
                      <p>We can develop native and cross-platform mobile apps for iOS and Android using Swift, Kotlin, Java, React Native, Flutter, Ionic, and more.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/cloud.svg" alt="cloud" />
                      </div>
                      <h3 className="mb-3">Cloud Development</h3>
                      <p>We can leverage the power of cloud computing to create scalable and reliable software solutions using AWS, Azure, Google Cloud Platform, Firebase, Heroku, and more.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <h2 className="mb-3">How We Work?</h2>
                <p className="lead"><em>We follow a simple and transparent process to deliver software development services:</em></p>
                <div class="sicon lg" data-aos="fade-down">
                    <img src="../assets/images/icons/vision.svg" alt="vision" />
                </div>
                <Row className="justify-content-center">
                  <Col lg={12} md={12}>
                    <div class="timeline">
                        <div class="outer">
                            <div class="tcard">
                                <div class="info">
                                    <h3 class="title">Discovery</h3>
                                    <p>We start by understanding your business goals, target audience, and project scope. We conduct research and analysis to define the features and functionalities of your software solution.</p>
                                </div>
                            </div>
                            <div class="tcard">
                                <div class="info">
                                    <h3 class="title">UI/UX Design</h3>
                                    <p>We create wireframes and mockups to show you how your software will look and feel. We incorporate your feedback and suggestions to refine the design until you are satisfied.</p>
                                </div>
                            </div>
                            <div class="tcard">
                                <div class="info">
                                    <h3 class="title">Development</h3>
                                    <p>We write clean and efficient code to implement the design and functionality of your software. We use version control and code review tools to ensure the quality of our code.</p>
                                </div>
                            </div>
                            <div class="tcard">
                                <div class="info">
                                    <h3 class="title">QA &amp; Testing</h3>
                                    <p>We perform various types of testing, such as unit testing, integration testing, functional testing, usability testing, and performance testing to ensure that your software is bug-free and meets the specifications.</p>
                                </div>
                            </div>
                            <div class="tcard">
                                <div class="info">
                                    <h3 class="title">Deployment</h3>
                                    <p>We deploy your software to the production environment using the best practices of continuous integration and continuous delivery. We monitor and troubleshoot any issues that may arise during or after the deployment.</p>
                                </div>
                            </div>
                            <div class="tcard">
                                <div class="info">
                                    <h3 class="title">Support</h3>
                                    <p>We provide ongoing support and maintenance to keep your software running smoothly and securely. We also offer enhancements and upgrades to add new features or improve existing ones.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                  </Col>
                </Row>
                <div class="sicon lg pull-right" data-aos="fade-down">
                    <img src="../assets/images/cyber-security.svg" alt="cyber-security" />
                </div>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for a reliable and professional software development partner who can help you achieve your business goals with custom software solutions, contact us today. We would love to hear from you and discuss how we can work together.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default SoftwareDevelopment;
