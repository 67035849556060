import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Careers</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/home" className="link-icon">Home</Link></li>
                      <li>Join Us</li>
                      <li>Careers</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={8} md={12}>
                    <h2 className="mb-3">Careers at LIFO Technologies Pvt. Ltd.</h2>
                    <p className="lead"><em>Are you passionate about software development and innovation? Do you want to work in a dynamic and collaborative environment where you can learn, grow and make an impact?<br/>If yes, then you might be the perfect fit for our team!</em></p>                     
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-4">
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-5">
                      <p>At LIFO Technologies Pvt. Ltd., we are a leading software company that provides cutting-edge solutions for various industries and sectors. We work with clients from all over the world and deliver high-quality products and services that meet or exceed their expectations. We use the latest technologies and methodologies to create software that is scalable, secure, reliable and user-friendly.</p>
                      <p>We are looking for talented and motivated individuals who share our vision and values and who are eager to join our diverse and inclusive team. We offer competitive compensation and benefits, flexible work arrangements, professional development opportunities, and a fun and friendly work culture. Whether you are a software engineer, a designer, a tester, a project manager, or a salesperson, we have a role for you!</p>
                    </Col>
                    <Col lg={4} md={6} className="mb-4">
                      <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/web-development.svg" alt="" />
                      </div>
                      <h3>Software Developer</h3>
                      <p>You will be responsible for designing, developing, testing, debugging, and maintaining software applications and systems using various programming languages and frameworks. You will also collaborate with other engineers, designers, testers, and project managers to ensure the quality and functionality of the software products.</p>
                    </Col>
                    <Col lg={4} md={6} className="mb-4">
                      <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/graphic-design.svg" alt="" />
                      </div>
                      <h3>Software Designer</h3>
                      <p>You will be responsible for creating and implementing user interface designs and user experience strategies for software applications and systems. You will also conduct user research, usability testing, prototyping, and wireframing to ensure the best possible user experience for the software products.</p>
                    </Col>
                    <Col lg={4} md={6} className="mb-4">
                      <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/testing.svg" alt="" />
                      </div>
                      <h3>Software Tester</h3>
                      <p>You will be responsible for planning, executing, and reporting on software testing activities for software applications and systems. You will also perform functional, non-functional, integration, regression, performance, security, and compatibility testing using various tools and techniques to ensure the quality and reliability of the software products.</p>
                    </Col>
                    <Col lg={4} md={6} className="mb-4">
                      <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/innovation.svg" alt="" />
                      </div>
                      <h3>Software Project Manager</h3>
                      <p>You will be responsible for managing the scope, schedule, budget, resources, risks, issues, and deliverables of software projects. You will also coordinate with clients, stakeholders, vendors, and team members to ensure the successful completion of the software projects.</p>
                    </Col>
                    <Col lg={4} md={6} className="mb-4">
                      <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/sales.svg" alt="" />
                      </div>
                      <h3>Software Salesperson</h3>
                      <p>You will be responsible for identifying, qualifying, negotiating, and closing sales opportunities for software products and services. You will also build and maintain relationships with existing and potential clients, provide product demonstrations and presentations, and generate sales reports and forecasts.</p>
                    </Col>
                    <Col lg={12} md={12}>
                      <h2 className="mt-4 mb-3">How to Apply?</h2>
                      <p>If you are interested in any of these positions, please send your resume and cover letter to <a href="mailto:careers@lifotechnologies.com">careers@lifotechnologies.com</a> with the position title in the subject line. Please include your availability, salary expectations, and relevant skills and experience in your application.</p>
                      <p>We thank all applicants for their interest, but only those selected for an interview will be contacted.</p>
                      <p>LIFO Technologies Pvt. Ltd., is an equal opportunity employer that values diversity and inclusion. We do not discriminate on the basis of race, color, religion, gender, sexual orientation, age, disability, or any other protected status. We welcome applications from all qualified candidates and encourage you to apply today!</p>
                    </Col>
                </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Footer />
    </div>
  );
};

export default Careers;
