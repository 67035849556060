import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const ProjectManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Project Management</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Solutions</li>
                      <li>Project Management</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Manage Your Projects with Project Management Software</h2>
                        <p className="lead"><em>Do you want to manage your projects more effectively and efficiently? Do you want to plan and execute your projects on time, on budget, and on scope? Do you want to collaborate and communicate with your project team and stakeholders?</em></p>
                        <p className="lead"><em><span className="text-light">If you answered yes to any of these questions,</span> then you need Project Management Software.</em></p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={7} md={12}>
                        <h2 className="mb-3">What is Project Management Software?</h2>
                        <p className="lead"><em>It is a technology that helps you manage and optimize your projects by integrating and automating your various project management functions. Project Management Software allows you to:</em></p>
                        <ul className="custom-list">
                            <li>Manage and access all your project data in one place</li>
                            <li>Plan and execute your project tasks, milestones, and deliverables</li>
                            <li>Track and monitor your project progress, performance, and outcomes</li>
                            <li>Collaborate and communicate with your project team and stakeholders</li>
                            <li>Analyze and improve your project efficiency and effectiveness</li>
                        </ul>
                        <p>Project Management Software is not just a tool for project managers. It is a comprehensive solution that supports every stakeholder in your project, from sponsors to customers. Project Management Software helps you create a successful and satisfying project experience that drives your business success.</p>
                    </Col>
                    <Col lg={5} md={12} className="text-center mt-5">
                      <img src="../assets/images/pages/project-management.svg" alt="project-management" className="w-75"/>
                    </Col>
                    <div className="line space"></div>
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">Why you need Project Management Software for your business?</h2>
                        <p className="lead"><em>Project Management Software can help you achieve your business goals in many ways. Here are some of the benefits of using Project Management Software:</em></p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Save time and money</h4>
                      <p>Project Management Software helps you save time and money by automating and simplifying your project management processes and reducing errors and paperwork. You can also save money by avoiding delays, rework, scope creep, and cost overrun.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Improve project quality and customer satisfaction</h4>
                      <p>Project Management Software helps you improve project quality and customer satisfaction by delivering on time, on budget, and on scope. You can also use Project Management Software to enhance your project quality control, compliance, and feedback.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Increase team productivity and collaboration</h4>
                      <p>Project Management Software helps you increase team productivity and collaboration by streamlining and standardizing your project management processes and workflows. You can also use Project Management Software to improve your team communication, coordination, and motivation.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Gain competitive advantage and growth</h4>
                      <p>Project Management Software helps you gain competitive advantage and growth by enabling you to manage your projects more effectively and efficiently. You can also use Project Management Software to innovate new products, services, and business models.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">How to choose the best Project Management Software for your business?</h2>
                        <p className="lead"><em>There are many Project Management Software solutions available in the market that cater to different industries, sizes, budgets, and needs. You need to do some research and compare the features, benefits, pricing, reviews, etc. of different Project Management Software solutions before making a decision.</em></p>
                        <p className="lead"><em>Some of the factors that you should consider when choosing a Project Management Software solution are:</em></p>         
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">01</div>
                        <h4 className="mb-3">The size and nature of your business</h4>
                        <p>You need to choose a Project Management Software solution that matches your business size and industry. For example, if you are a small business, you may need a simple and affordable Project Management Software solution that covers the basic functions of project management. If you are a large enterprise, you may need a more complex and robust Project Management Software solution that supports multiple projects, teams, and features.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">02</div>
                        <h4 className="mb-3">The features and functions that you need</h4>
                        <p>You need to choose a Project Management Software solution that offers the features and functions that you need for your business goals. For example, if you want to plan and execute your projects, you may need a Project Management Software solution that has task management, milestone management, and Gantt chart features. If you want to track and monitor your projects, you may need a Project Management Software solution that has time tracking, budget tracking, and reporting features.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">03</div>
                        <h4 className="mb-3">The ease of use and customization</h4>
                        <p>You need to choose a Project Management Software solution that is easy to use and customize according to your needs. You don't want to spend too much time and effort learning how to use the system or hiring developers to customize it for you. You want a Project Management Software solution that has a user-friendly interface, intuitive navigation, and flexible configuration options.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">04</div>
                        <h4 className="mb-3">The security and reliability</h4>
                        <p>You need to choose a Project Management Software solution that is secure and reliable. You don't want to risk losing or compromising your project data or facing downtime or glitches in the system. You want a Project Management Software solution that has strong encryption, backup, recovery, and disaster recovery features. You also want a Project Management Software solution that has high availability, performance, and scalability.</p>
                    </Col>
                    <Col lg={12} md={12}>
                        <div className="nos">05</div>
                        <h4 className="mb-3">The support and guidance</h4>
                        <p>You need to choose a Project Management Software solution that provides you with support and guidance throughout your PM journey. You don't want to be left alone or frustrated when you encounter any problems or challenges with the system. You want a Project Management Software solution that has a dedicated support team that is available 24/7 via phone, email, chat, or web. You also want a Project Management Software solution that has a comprehensive knowledge base, tutorials, videos, webinars, blogs, forums, and other resources that can help you learn more about the system</p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our Project Management software, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your animation and media needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default ProjectManagement;
