import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const SoftwareTesting = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Testing and QA</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>Testing and QA</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={8} md={12}>
                     <h2 className="mb-3">Software Testing</h2>
                     <p className="lead"><em>We are a team of professional software testers who provide quality assurance and testing services for your software products and projects.</em></p>          
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-5">
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                      <h2 className="mb-3">What we do</h2>
                      <p className="lead"><em>We offer a range of software testing services to help you ensure the quality, functionality, performance, and security of your software products and projects. Whether you need manual testing, automated testing, or both, we can help you achieve your goals.</em></p>
                      <p className="lead"><em>Our software testing services include:</em></p>                      
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={3} md={6}>
                      <div className="nos">01</div>
                      <h3 className="mb-3">Functional Testing</h3>
                      <p>We test the functionality of your software product or project according to the requirements and specifications. We verify that the software works as expected and meets the user needs.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">02</div>
                      <h3 className="mb-3">Non-Functional Testing</h3>
                      <p>We test the non-functional aspects of your software product or project such as performance, usability, reliability, compatibility, security, and more. We ensure that the software meets the quality standards and expectations.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">03</div>
                      <h3 className="mb-3">Regression Testing</h3>
                      <p>We test the software product or project after any changes or updates to ensure that the existing functionality is not affected or broken. We detect and prevent any bugs or errors that may arise due to the changes.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">04</div>
                      <h3 className="mb-3">Test Automation</h3>
                      <p>We use various tools and frameworks to automate the testing process and reduce the time and effort required for testing. We create and execute automated test scripts that can run repeatedly and consistently.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-5">
                      <h2 className="mb-3">Why choose us</h2>
                      <p className="lead"><em>We are not just testers, we are quality advocates. We care about your software products and projects.<br/>We strive to provide testing solutions that are:</em></p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/testing.svg" alt="testing" />
                      </div>
                      <h3 className="mb-3">Comprehensive</h3>
                      <p>We cover all aspects of software testing from functional to non-functional, from manual to automated, from unit to integration to system testing. We ensure that your software product or project is thoroughly tested and validated.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/money-bag.svg" alt="money-bag" />
                      </div>
                      <h3 className="mb-3">Cost-Effective</h3>
                      <p>We use the best practices and tools to optimize the testing process and reduce the cost and risk of software defects. We help you save time and money by delivering high-quality software products and projects.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/flexibility.svg" alt="flexibility" />
                      </div>
                      <h3 className="mb-3">Flexible</h3>
                      <p>We adapt to your needs and preferences. We can work with any software development methodology, technology stack, or domain. We can work on-site or remotely, as part of your team or independently.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/website.svg" alt="website" />
                      </div>
                      <h3 className="mb-3">Experienced</h3>
                      <p>We have a team of skilled and certified software testers who have extensive experience in various types of software testing across different industries and domains. We have successfully completed many software testing projects for clients worldwide.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for a skilled and certified software testing partner who can help you achieve your business goals with custom software solutions, contact us today. We would love to hear from you and discuss how we can work together.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default SoftwareTesting;
