import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const FleetManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Fleet Management</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Solutions</li>
                      <li>Fleet Management</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Manage Your Fleet of Vehicles with FM Software</h2>
                        <p className="lead"><em>Do you want to manage your fleet of vehicles more effectively and efficiently? Do you want to track and monitor your vehicles, drivers, and assets in real-time? Do you want to reduce your fuel consumption, maintenance costs, and emissions?</em></p>
                        <p className="lead"><em><span className="text-light">If you answered yes to any of these questions,</span> then you need FM software.</em></p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={7} md={12}>
                        <h2 className="mb-3">What is FM Software?</h2>
                        <p className="lead"><em>FM software stands for Fleet Management software. It is a technology that helps you manage and optimize your fleet of vehicles by integrating and automating your various fleet management functions. FM software allows you to:</em></p>
                        <ul className="custom-list">
                            <li>Manage and access all your fleet data in one place</li>
                            <li>Track and monitor your vehicles, drivers, and assets in real-time</li>
                            <li>Plan and optimize your routes, schedules, and deliveries</li>
                            <li>Manage your fuel consumption, maintenance, and compliance</li>
                            <li>Analyze and improve your fleet performance and efficiency</li>
                        </ul>
                        <p>FM software is not just a tool for fleet managers. It is a comprehensive solution that supports every stakeholder in your fleet, from drivers to customers. FM software helps you create a safe and sustainable fleet that drives your business success.</p>
                    </Col>
                    <Col lg={5} md={12} className="text-center mt-4">
                      <img src="../assets/images/pages/fleet.svg" alt="fleet" className="w-75"/>
                    </Col>
                    <div className="line space"></div>
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">Why You Need FM Software for Your Business</h2>
                        <p className="lead"><em>FM software can help you achieve your business goals in many ways. Here are some of the benefits of using FM software:</em></p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Save time and money</h4>
                      <p>FM software helps you save time and money by automating and simplifying your fleet management processes and reducing errors and paperwork. You can also save money by reducing fuel consumption, maintenance costs, insurance premiums, and fines.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Improve customer satisfaction and loyalty</h4>
                      <p>FM software helps you improve customer satisfaction and loyalty by delivering faster and better service, quality, and reliability. You can also use FM software to enhance your customer communication, feedback, and retention.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Increase safety and security</h4>
                      <p>FM software helps you increase safety and security by tracking and monitoring your vehicles, drivers, and assets in real-time. You can also use FM software to prevent theft, accidents, breakdowns, and violations.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Reduce environmental impact</h4>
                      <p>FM software helps you reduce environmental impact by reducing fuel consumption, emissions, and waste. You can also use FM software to comply with environmental regulations and standards.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">How to choose the best FM Software for your business?</h2>
                        <p className="lead"><em>There are many FM software solutions available in the market that cater to different industries, sizes, budgets, and needs. You need to do some research and compare the features, benefits, pricing, reviews, etc. of different FM software solutions before making a decision.</em></p>
                        <p className="lead"><em>Some of the factors that you should consider when choosing an FM software solution are:</em></p>         
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">01</div>
                        <h4 className="mb-3">The size and nature of your business</h4>
                        <p>You need to choose an FM software solution that matches your business size and industry. For example, if you are a small business, you may need a simple and affordable FM software solution that covers the basic functions of fleet management. If you are a large enterprise, you may need a more complex and robust FM software solution that supports multiple vehicles, drivers, and assets.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">02</div>
                        <h4 className="mb-3">The features and functions that you need</h4>
                        <p>You need to choose an FM software solution that offers the features and functions that you need for your business goals. For example, if you want to track and monitor your vehicles in real-time, you may need an FM software solution that has GPS tracking, telematics, and geofencing features. If you want to manage your fuel consumption, you may need an FM software solution that has fuel management, reporting, and optimization features.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">03</div>
                        <h4 className="mb-3">The ease of use and customization</h4>
                        <p>You need to choose an FM software solution that is easy to use and customize according to your needs. You don’t want to spend too much time and effort learning how to use the system or hiring developers to customize it for you. You want an FM software solution that has a user-friendly interface, intuitive navigation, and flexible configuration options.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">04</div>
                        <h4 className="mb-3">The security and reliability</h4>
                        <p>You need to choose an FM software solution that is secure and reliable. You don’t want to risk losing or compromising your fleet data or facing downtime or glitches in the system. You want an FM software solution that has strong encryption, backup, recovery, and disaster recovery features. You also want an FM software solution that has high availability, performance, and scalability.</p>
                    </Col>
                    <Col lg={12} md={12}>
                        <div className="nos">05</div>
                        <h4 className="mb-3">The support and guidance</h4>
                        <p>You need to choose an FM software solution that provides you with support and guidance throughout your FM journey. You don’t want to be left alone or frustrated when you encounter any problems or challenges with the system. You want an FM software solution that has a dedicated support team that is available 24/7 via phone, email, chat, or web. You also want an FM software solution that has a comprehensive knowledge base, tutorials, videos, webinars, blogs, forums, and other resources that can help you learn more about the system</p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our SCM solutions, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your animation and media needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col">Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default FleetManagement;
