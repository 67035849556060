import React from "react";
import {Nav, Navbar, Row, Col, NavDropdown,	Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = () => {
	return (		
		<header className="header">
			<Navbar fixed="top" expand="lg">
				<Container fluid="md">
					<Link to="/" className="navbar-brand"><img src="../assets/images/logo-light.svg" alt="LIFO logo" /></Link>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />					
					<Navbar.Collapse className="justify-content-end">
						<Nav className="">
							<Link to="/" className="nav-link">Home</Link>
							<NavDropdown title="About Us +" id="" renderMenuOnMount={true}>
								<Container fluid="md" className="text-light">
									<Row className="align-items-center">
										<Col lg={4} md={12} className="d-none d-lg-block">
											{/* <img src="../assets/images/menu-about.svg" className="menu-img" alt="" /> */}
											<h2>About</h2>
											<h4 className="mb-4">LIFO Technologies Pvt. Ltd.</h4>
											<p>We are LIFO, a software development company that specializes in custom web and mobile applications. We provide innovative and scalable solutions for various industries, such as education, healthcare, finance, and entertainment.</p>
											{/* <Button href="/about/company" variant="primary" className="mt-3">Know More</Button> */}
										</Col>	
										<Col lg={{span: 6, offset: 2}} md={12}>
											<Row>
												<Col lg="5" md="6" xs="6">
													<h6 className="mb-3">Company</h6>
													<ul className="footer-links list m-0">
														<li><Link to="/about/company" className="">About Company</Link></li>
														<li><Link to="/about/leadership" className="">Leadership</Link></li>
														<li><Link to="/about/experts" className="">Experts</Link></li>
														<li><Link to="/about/clients" className="">Clients</Link></li>
														<li><Link to="/about/partners" className="">Our Partners</Link></li>
													</ul>
													<h6 className="mb-3 mt-3">Recognition</h6>
													<ul className="footer-links list m-0">
														<li><Link to="/recognition/testimonials" className="">Testimonials</Link></li>
														<li><Link to="/recognition/press-media" className="">Press & Media</Link></li>
													</ul>
												</Col>
												<Col lg="7" md="6" xs="6">
													<h6 className="mb-3">Approach</h6>
													<ul className="footer-links list m-0">
														<li><Link to="/approach/technology-partnership" className="">Technology Partnership</Link></li>
														<li><Link to="/approach/development-process" className="">Development Process</Link></li>
														<li><Link to="/approach/sustainability-policy" className="">Sustainability Policy</Link></li>
														<li><Link to="/approach/privacy-policy" className="">Privacy Policy</Link></li>
														<li><Link to="/approach/terms-of-use" className="">Terms of Use</Link></li>
													</ul>
													<h6 className="mb-3 mt-3">Join Us</h6>
													<ul className="footer-links list m-0">
														<li><Link to="/join-us/careers" className="">Careers</Link></li>
														<li><Link to="/join-us/refferal-program" className="">Referral Program</Link></li>
													</ul>
												</Col>
											</Row>
										</Col>
									</Row>
								</Container>
							</NavDropdown>
							<NavDropdown title="Solutions +" id="" renderMenuOnMount={true}>
								<Container fluid="md" className="text-light">
									<Row className="align-items-center">
										<Col lg={4} md={6} className="d-none d-lg-block">
											{/* <img src="../assets/images/menu-solutions.svg" className="menu-img" alt="" /> */}
											<h2>Solutions</h2>
											<h4 className="mb-4">At LIFO Technologies Pvt. Ltd.</h4>
											<p>We help our clients transform their ideas into reality. We use the latest technologies, such as cloud computing, artificial intelligence, and blockchain, to deliver high-quality and secure products. We offer end-to-end services, from design and development to testing and maintenance.</p>
											{/* <Button href="/solutions/crm" variant="primary" className="mt-3">Know More</Button> */}
										</Col>	
										<Col lg={{span: 6, offset: 2}} md={12}>
											<Row>
												<Col lg="5" md="6" xs="6">
													<ul className="footer-links list m-0">
														<li><Link to="/solutions/crm" className="">CRM</Link></li>
														<li><Link to="/solutions/erp" className="">ERP</Link></li>
														<li><Link to="/solutions/human-resources" className="">Human Resources</Link></li>
														<li><Link to="/solutions/supply-chain-management" className="">Supply Chain Management</Link></li>
														<li><Link to="/solutions/fleet-management" className="">Fleet Management</Link></li>
														<li><Link to="/solutions/kiosk-software" className="">Kiosk Software</Link></li>
														<li><Link to="/solutions/operations-management" className="">Operations Management</Link></li>
														<li><Link to="/solutions/asset-management" className="">Asset Management</Link></li>
														<li><Link to="/solutions/project-management" className="">Project Management</Link></li>
														<li><Link to="/solutions/ecommerce" className="">Ecommerce</Link></li>
													</ul>
												</Col>
												<Col lg="7" md="6" xs="6">
													<h6 className="mb-3">Industries</h6>
													<ul className="footer-links list m-0">
														<li><Link to="/industries/oil-gas" className="">Oil &amp; Gas</Link></li>
														<li><Link to="/industries/healthcare" className="">Healthcare</Link></li>
														<li><Link to="/industries/manufacturing" className="">Manufacturing</Link></li>
														<li><Link to="/industries/professional-services" className="">Professional Services</Link></li>
														<li><Link to="/industries/animation-media" className="">Animation & Media</Link></li>
														<li><Link to="/industries/insurance" className="">Insurance</Link></li>
														<li><Link to="/industries/retail" className="">Retail</Link></li>
														<li><Link to="/industries/transportation-logistics" className="">Transportation and Logistics</Link></li>
														<li><Link to="/industries/telecommunications" className="">Telecommunications</Link></li>														
													</ul>
												</Col>
											</Row>
										</Col>
									</Row>
								</Container>
							</NavDropdown>
							<NavDropdown title="Products +" id="" renderMenuOnMount={true}>
								<Container fluid="md" className="text-light">
									<Row className="">
										<Col lg={4} md={6} className="d-none d-lg-block">
											<h2>Products</h2>
											<h4 className="mb-4">At LIFO Technologies Pvt. Ltd.</h4>
											<p>Our products has advanced solution designed to enhance customer interactions and streamline support services. Powered by cutting-edge natural language processing (NLP) technology, AI understands and responds to customer queries with human-like accuracy and efficiency.</p>
											{/* <Button href="/products/lpg-vending-machine" variant="primary" className="mt-3">Know More</Button> */}
										</Col>	
										<Col lg={{span: 6, offset: 2}} md={12}>
											<Row>
												<Col lg="5" md="6" xs="6">
													{/* <ul className="footer-links list m-0">
														<li><Link to="/products/lpg-vending-machine" className="">LPG Vending Machine</Link></li>
													</ul> */}
													<Link to="/products/lpg-vending-machine" className="product-img">
														{/* <img src="../assets/images/products/lpg-vending-machine/lpg-vending-machine-1.png" alt="LPG Vending Machine" className="img-fluid"/> */}
														<img src="assets/images/products/lpg-vending-machine/video-screen.png" alt="lpg-vending-machine" className="img-fluid" />
														<h6>LPG Vending Machine</h6>
													</Link>
												</Col>
											</Row>
										</Col>
									</Row>
								</Container>
							</NavDropdown>						
							<NavDropdown title="Services +" id="" renderMenuOnMount={true}>
								<Container fluid="md" className="text-light">
									<Row className="align-items-center">
										<Col lg={4} md={6} className="d-none d-lg-block">
											{/* <img src="../assets/images/menu-services.svg" className="menu-img" alt="" /> */}
											<h2>Services</h2>
											<h4 className="mb-4">At LIFO Technologies Pvt. Ltd.</h4>
											<p>We are a team of experienced and passionate software developers who can help you turn your ideas into reality. Whether you need a custom web application, a mobile app, a desktop software, or a cloud solutions.</p>
											{/* <Button href="/services/software-development" variant="primary" className="mt-3">Know More</Button> */}
										</Col>	
										<Col lg={{span: 6, offset: 2}} md={12}>
											<Row>
												<Col lg="5" md="6" xs="6">
													<ul className="footer-links list m-0">
														<li><Link to="/services/software-development" className="">Software Development</Link></li>
														<li><Link to="/services/ui-ux-design" className="">UI/UX Design</Link></li>
														<li><Link to="/services/software-testing" className="">Testing and QA</Link></li>
														<li><Link to="/services/application-services" className="">Application Services</Link></li>
														<li><Link to="/services/infrastructure-services" className="">Infrastructure Services</Link></li>
														<li><Link to="/services/data-analytics" className="">Data Analytics</Link></li>
														<li><Link to="/services/cloud-migration" className="">Cloud Migration</Link></li>
													</ul>
												</Col>
												<Col lg="7" md="6" xs="6">
													<ul className="footer-links list m-0">
														<li><Link to="/services/digital-transformation" className="">Digital Transformation</Link></li>
														<li><Link to="/services/managed-it-services" className="">Managed IT Services</Link></li>
														<li><Link to="/services/process-automation" className="">Process Automation</Link></li>
														<li><Link to="/services/data-extraction-migration" className="">Data Extraction & Migration</Link></li>
														<li><Link to="/services/devOps" className="">DevOps</Link></li>
														<li><Link to="/services/cybersecurity" className="">Cybersecurity</Link></li>
														<li><Link to="/services/animation-media-production" className="">Animation &amp; Media Production</Link></li>
													</ul>
												</Col>
											</Row>
										</Col>
									</Row>
								</Container>
							</NavDropdown>
							<NavDropdown title="Technologies +" id="" renderMenuOnMount={true}>
								<Container fluid="md" className="text-light">
									<Row className="align-items-center">
										<Col lg={4} md={6} className="d-none d-lg-block">
											{/* <img src="../assets/images/menu-technologies.svg" className="menu-img" alt="" /> */}
											<h2>Technologies</h2>
											<h4 className="mb-4">At LIFO Technologies Pvt. Ltd.</h4>
											<p>We are passionate about technology and innovation. We believe that software can change the world for the better. We want to empower our clients and users with solutions that are smart, efficient, and reliable. We strive to exceed expectations and deliver excellence in everything we do.</p>
											{/* <Button href="/technologies/cloud-services" variant="primary" className="mt-3">Know More</Button> */}
										</Col>	
										<Col lg={{span: 6, offset: 2}} md={12}>
											<Row>
												<Col lg="5" md="6" xs="6">
													<h6 className="mb-3">Trending</h6>
													<ul className="footer-links list m-0">
														<li><Link to="/technologies/cloud-services" className="">Cloud Services</Link></li>
														<li><Link to="/technologies/artificial-intelligence" className="">Artificial Intelligence</Link></li>
														<li><Link to="/technologies/internet-of-things" className="">Internet of Things</Link></li>
														<li><Link to="/technologies/data-science" className="">Data Science</Link></li>
														<li><Link to="/technologies/big-data" className="">Big Data</Link></li>
														<li><Link to="/technologies/blockchain" className="">Blockchain</Link></li>
														<li><Link to="/technologies/virtual-reality" className="">Virtual Reality</Link></li>
														<li><Link to="/technologies/augmented-reality" className="">Augmented Reality</Link></li>
													</ul>
												</Col>
												<Col lg="7" md="6" xs="6">
													<h6 className="mb-3">Platforms</h6>
													<ul className="footer-links list m-0">
														<li><Link to="/platforms/microsoft" className="">Microsoft</Link></li>
														<li><Link to="/platforms/aws" className="">Amazon Web Services</Link></li>
														<li><Link to="/platforms/google-cloud-platform" className="">Google Cloud Platform</Link></li>
													</ul>
												</Col>
											</Row>
										</Col>
									</Row>
								</Container>
							</NavDropdown>
							{/* <NavDropdown title="Resources +" id="" className="d_small">
								<Container fluid="md" className="text-light">
									<ul className="footer-links list m-0">
										<li><Link to="/" className="">Blog</Link></li>
										<li><Link to="/" className="">Case Studies</Link></li>
									</ul>
								</Container>
							</NavDropdown>
							<Link to="/blog" className="nav-link">Blog</Link>*/}
							<Link to="/contact-us" className="nav-link lets-talk">Get a Quote</Link>				
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	);
};

export default Header;
