import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";

const Clients = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Our Clients</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>About</li>
                      <li>Clients</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={12} md={12}>
                     <h2 className="mb-3">At <strong>LIFO Technologies Pvt. Ltd.,</strong><br/>we are proud to serve a diverse range of clients from various industries and sectors.</h2>
                     <p className="lead"><em>Our clients trust us to deliver innovative and user-friendly software solutions that help them achieve their goals and grow their businesses.</em></p>          
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-4">
                <Row className="text-light">             
                    <Col lg={12} md={12}>
                        <p>Here are some of the clients we have worked with and the projects we have completed for them:</p>                
                    </Col>
                </Row>
                <Row className="text-light client-list g-5">
                  <Col lg={6} md={6} className="">
                    <h2 className="mb-2 mt-4">International Clients</h2>
                    <Row className="">
                      <Col lg={4} xs={6}>
                        <div className="client"><img src="../assets/images/client/international/essar.png" alt="essar logo" className="w-100"/></div>
                      </Col>
                      <Col lg={4} xs={6}>
                        <div className="client"><img src="../assets/images/client/international/airtec.png" alt="airtec logo" className="w-100"/></div>
                      </Col>
                      <Col lg={4} xs={6}>
                        <div className="client"><img src="../assets/images/client/international/stemz.png" alt="stemz logo" className="w-100"/></div>
                      </Col>
                      <Col lg={4} xs={6}>
                        <div className="client"><img src="../assets/images/client/international/allyhealth.png" alt="allyhealth logo" className="w-100"/></div>
                      </Col>
                      <Col lg={4} xs={6}>
                        <div className="client"><img src="../assets/images/client/international/yateem.png" alt="yateem logo" className="w-100"/></div>
                      </Col>
                      <Col lg={4} xs={6}>
                        <div className="client"><img src="../assets/images/client/international/nyb.png" alt="nyb logo" className="w-100"/></div>
                      </Col>
                      {/* <Col lg={4} xs={6}>
                        <div className="client"><img src="../assets/images/client/international/tamilch.png" alt="" className="w-100"/></div>
                      </Col> */}
                      <Col lg={4} xs={6}>
                        <div className="client"><img src="../assets/images/client/domestic/raza-mobile.png" alt="raza mobile logo" className="w-100"/></div>
                      </Col>
                      <Col lg={4} xs={6}>
                        <div className="client"><img src="../assets/images/client/domestic/digitally.png" alt="digitally logo" className="w-100"/></div>
                      </Col>
                      <Col lg={4} xs={6}>
                        <div className="client"><img src="../assets/images/client/domestic/baashyaam.png" alt="Baashyaam logo" className="w-100"/></div>
                      </Col>
                      {/* <Col lg={4} xs={6}>
                        <div className="client"><img src="../assets/images/client/international/spirit.png" alt="spirit logo" className="w-100"/></div>
                      </Col>
                      <Col lg={4} xs={6}>
                        <div className="client"><img src="../assets/images/client/international/5a9a.png" alt="5a9a logo" className="w-100"/></div>
                      </Col>                                         */}
                    </Row>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="border-left">
                      <h2 className="mb-2 mt-4">Domestic Clients</h2>
                      <Row>
                        <Col lg={4} xs={6}>
                          <div className="client"><img src="../assets/images/client/domestic/indian_navy.png" alt="indian_navy logo" className="w-100"/></div>
                        </Col>
                        <Col lg={4} xs={6}>
                          <div className="client"><img src="../assets/images/client/domestic/tamilnadu-government.png" alt="tamilnadu-government logo" className="w-100"/></div>
                        </Col>
                        <Col lg={4} xs={6}>
                          <div className="client"><img src="../assets/images/client/domestic/bpcl.png" alt="bpcl logo" className="w-100"/></div>
                        </Col>
                        <Col lg={4} xs={6}>
                          <div className="client"><img src="../assets/images/client/domestic/tamilnadu-police.png" alt="tamilnadu-police logo" className="w-100"/></div>
                        </Col>
                        <Col lg={4} xs={6}>
                          <div className="client"><img src="../assets/images/client/domestic/defence-expo.png" alt="defence-expo logo" className="w-100"/></div>
                        </Col>
                        <Col lg={4} xs={6}>
                          <div className="client"><img src="../assets/images/client/domestic/hindu-tamil-theesai.png" alt="hindu-tamil-theesai logo" className="w-100"/></div>
                        </Col>
                        <Col lg={4} xs={6}>
                          <div className="client"><img src="../assets/images/client/domestic/brill.png" alt="brill logo" className="w-100"/></div>
                        </Col>                        
                        <Col lg={4} xs={6}>
                          <div className="client"><img src="../assets/images/client/domestic/cookieman.png" alt="cookieman logo" className="w-100"/></div>
                        </Col>
                        <Col lg={4} xs={6}>
                          <div className="client"><img src="../assets/images/client/domestic/newsgate.png" alt="newsgate logo" className="w-100"/></div>
                        </Col>
                        {/* <Col lg={4} xs={6}>
                          <div className="client"><img src="../assets/images/client/domestic/safeguard.png" alt="safeguard logo" className="w-100"/></div>
                        </Col> */}
                      </Row>
                    </div>
                  </Col>               
                </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default Clients;
