import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const InternetOfThings = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Internet Of Things</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Technologies</li>
                      <li>Internet Of Things</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-4">
                    <Col lg={12} md={12}>
                        <p className="lead"><em>Internet of things (IoT) is a network of physical devices that can transfer data to one another without human intervention. IoT devices can include anything with a sensor that is assigned a unique identifier (UID), such as smartphones, appliances, vehicles, or wearable technologies.</em></p>
                        <h2 className="mb-3 mt-5">What IoT can do?</h2>
                        <p className="lead mb-5"><em>IoT can enable various applications that require data collection and communication, such as:</em></p>
                    </Col>
                    <Col lg={6} md={12}>                      
                      <Row>
                          <Col lg={6} md={6}>
                            <div className="nos">01</div>
                            <h4 className="mb-3">Smart Home devices</h4>
                            <p>IoT can help homeowners control and automate devices such as thermostats, security systems, or voice assistants.</p>
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="nos">02</div>
                            <h4 className="mb-3">Wearable Technologies</h4>
                            <p>IoT can help users monitor and improve their health and fitness, such as smartwatches or fitness trackers.</p>          
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="nos">03</div>
                            <h4 className="mb-3">Personal Medical Devices</h4>
                            <p>IoT can help patients and doctors track and manage health conditions, such as pacemakers or glucose monitors.</p>          
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="nos">04</div>
                            <h4 className="mb-3">Autonomous vehicles</h4>
                            <p>IoT can help vehicles navigate and communicate with each other and the environment, such as self-driving cars or drones.</p>          
                          </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={12} className="text-center"> 
                      <img src="../assets/images/pages/iot.svg" alt="iot" className="w-75"/>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">                  
                  <Col lg={12} md={12}>
                    <h2 className="mb-3 mt-4">Benefits of IoT Technology</h2>
                    <p className="lead mb-5"><em>IoT technology offers many benefits for businesses and individuals, such as:</em></p>
                  </Col>
                  <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/line-chart.svg" alt="line-chart" />
                      </div>
                    <h4 className="mb-3">Improved Efficiency</h4>
                    <p>IoT can help automate and optimize processes and tasks, such as energy management, inventory management, or quality control.</p>
                  </Col>
                  <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/mission.svg" alt="mission" />
                      </div>
                    <h4 className="mb-3">Enhanced Accuracy</h4>
                    <p>IoT can help reduce human errors and biases, such as medical diagnosis, fraud detection, or traffic management.</p>          
                  </Col>
                  <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/ai.svg" alt="ai" />
                      </div>
                    <h4 className="mb-3">Better Insights</h4>
                    <p>IoT can help analyze large and complex data sets, such as big data analytics, sentiment analysis, or recommendation systems.</p>          
                  </Col>
                  <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/health.svg" alt="health" />
                      </div>
                    <h4 className="mb-3">Increased Convenience</h4>
                    <p>IoT can help provide more personalized and interactive services, such as smart assistants, smart appliances, or smart entertainment.</p>          
                  </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">                  
                  <Col lg={12} md={12}>
                    <h2 className="mb-3 mt-4">Types of IoT Technology</h2>
                    <p className="lead mb-5"><em>There are different types of IoT technology based on the level of connectivity and functionality they provide:</em></p>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className="nos">01</div>
                    <h4 className="mb-3">Local area network (LAN) IoT</h4>
                    <p>This is the type of IoT technology that connects devices within a limited range, such as a home or an office. LAN IoT uses wireless technologies such as WiFi or Bluetooth to communicate. Examples of LAN IoT include smart speakers, smart lights, or smart locks.</p>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className="nos">02</div>
                    <h4 className="mb-3">Wide area network (WAN) IoT</h4>
                    <p>This is the type of IoT technology that connects devices across a large distance, such as a city or a country. WAN IoT uses cellular or satellite technologies to communicate. Examples of WAN IoT include smart meters, smart cars, or smart cities.</p>          
                  </Col>
                  <Col lg={4} md={6}>
                    <div className="nos">03</div>
                    <h4 className="mb-3">Cloud-based IoT</h4>
                    <p>This is the type of IoT technology that connects devices to the internet and stores data in cloud servers. Cloud-based IoT uses cloud computing platforms and services to process and analyze data. Examples of cloud-based IoT include Google Nest, Amazon Alexa, or Microsoft Azure.</p>          
                  </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you're interested in learning more about our internet of things technology or want to get started with a free trial, please contact us today. We'll be happy to assist you with any questions or concerns you may have.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default InternetOfThings;
