import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const DigitalTransformation = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Digital Transformation</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>Digital Transformation</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={12} md={12}>
                     <p className="lead"><em>Digital transformation is the process of using digital technologies and solutions to create new or modify existing business processes, products, and customer experiences. It can help you improve your operational efficiency, customer satisfaction, and business innovation. It can also help you adapt to the changing market demands and customer expectations.</em></p>         
                     <p className="lead"><em>However, digital transformation is not easy. It requires a clear vision, a strong strategy, a robust implementation, and a continuous optimization. It also requires a cultural change and a mindset shift in your organization. That's why you need a reliable and professional partner to help you with your digital transformation journey.</em></p>         
                  </Col>
              </Row>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3 mt-5">What we do?</h2>
                        <p className="lead"><em>We are a digital transformation service provider that can help you leverage the power of digital technologies and solutions for your business success. We have a team of experienced and skilled digital experts who can help you with:</em></p>        
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h3 className="mb-3">Digital Strategy</h3>
                      <p>We can help you define your digital vision and goals and align them with your business strategy and objectives. We can also help you conduct a digital maturity assessment to identify the gaps and opportunities in your current digital capabilities. We can also help you create a roadmap and plan for your digital transformation journey, including the scope, timeline, budget, and resources.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h3 className="mb-3">Digital Implementation</h3>
                      <p>We can help you implement your digital solutions and technologies using agile and iterative methods. We can also help you integrate your digital solutions with your existing systems and processes using APIs, microservices, and cloud platforms. We can implement any digital solution, such as web applications, mobile applications, cloud services, artificial intelligence, internet of things, etc. We can also help you test and deploy your digital solutions using DevOps tools and practices.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h3 className="mb-3">Digital Optimization</h3>
                      <p>We can help you optimize your digital performance, quality, and security using best practices and standards. We can also help you measure and monitor your digital outcomes and impacts using analytics tools and dashboards. We can also help you improve and innovate your digital solutions based on feedback and data using user research and design thinking methods.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h3 className="mb-3">Consulting &amp; Training</h3>
                      <p>We can help you design and develop your digital architecture and governance using frameworks and models. We can also help you comply with any regulatory and industry standards and best practices using compliance tools and audits. We can also help you train your staff on how to use the digital solutions effectively using workshops and e-learning modules.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mt-4 mb-4">
                      <p className="lead"><em>We have worked with clients from various industries and domains, such as healthcare, education, finance, retail, e-commerce, etc. We have delivered successful digital transformation projects that have helped our clients achieve their business goals and gain a competitive edge.</em></p>
                      <p className="lead"><em>Some of our projects include:</em></p>         
                    </Col>
                    <Col lg={6} md={12}>
                      <Row>
                        <Col lg={6} md={6} className="mb-4">
                        <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/health.svg" alt="healthcare" />
                        </div>
                        <p>Developing a web application for a healthcare provider that allows patients to book appointments online, access their medical records, chat with doctors, and pay bills.</p>
                      </Col>
                        <Col lg={6} md={6} className="mb-4">
                        <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/education.svg" alt="education" />
                        </div>
                        <p>Creating a mobile application for an education provider that enables students to access courses online, interact with instructors, submit assignments, and take quizzes.</p>
                      </Col>
                      <Col lg={6} md={6} className="mb-4">
                        <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/database.svg" alt="database" />
                        </div>
                        <p>Migrating a finance provider's data center to the cloud platform that reduces costs, improves scalability, enhances security, and enables faster data processing.</p>
                      </Col>
                      <Col lg={6} md={6} className="mb-4">
                        <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/ai.svg" alt="ai" />
                        </div>
                        <p>Implementing an artificial intelligence solution for a retail provider that analyzes customer behavior, preferences, and feedback to provide personalized recommendations and offers.</p>
                      </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={12} className="text-center">
                      <img src="../assets/images/pages/thumbsup.svg" alt="thumbsup" className="w-100"/>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our cloud migration services, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your cloud needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default DigitalTransformation;
