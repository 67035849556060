import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const OperationsManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Operations Management</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Solutions</li>
                      <li>Operations Management</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Optimize Your Business Operations with OM Software</h2>
                        <p className="lead"><em>Do you want to optimize your business operations and improve your operational efficiency? Do you want to manage and coordinate your resources, processes, and activities? Do you want to reduce your operational costs, risks, and waste?</em></p>
                        <p className="lead"><em><span className="text-light">If you answered yes to any of these questions,</span> then you need OM software.</em></p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={7} md={12}>
                        <h2 className="mb-3">What is OM Software?</h2>
                        <p className="lead"><em>OM software stands for Operations Management software. It is a technology that helps you manage and optimize your business operations by integrating and automating your various operational functions. OM software allows you to:</em></p>
                        <ul className="custom-list">
                            <li>Manage and access all your operational data in one place</li>
                            <li>Integrate and automate your various operational functions, such as planning, scheduling, execution, monitoring, and control</li>
                            <li>Optimize your resources, processes, and activities</li>
                            <li>Analyze and improve your operational performance and efficiency</li>
                        </ul>
                        <p>OM software is not just a tool for operations managers. It is a comprehensive solution that supports every stakeholder in your business operations, from employees to customers. OM software helps you create a smooth and efficient operational environment that drives your business success.</p>
                    </Col>
                    <Col lg={5} md={12} className="text-center mt-4">
                      <img src="../assets/images/pages/sop.svg" alt="sop" className="w-75"/>
                    </Col>
                    <div className="line space"></div>
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">Why you need OM Software for your business?</h2>
                        <p className="lead"><em>OM software can help you achieve your business goals in many ways. Here are some of the benefits of using OM software:</em></p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Save time and money</h4>
                      <p>OM software helps you save time and money by automating and simplifying your operational processes and reducing errors and paperwork. You can also save money by reducing operational costs, such as labor, materials, energy, and maintenance.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Improve customer satisfaction and loyalty</h4>
                      <p>OM software helps you improve customer satisfaction and loyalty by delivering faster and better service, quality, and reliability. You can also use OM software to enhance your customer communication, feedback, and retention.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Increase productivity and quality</h4>
                      <p>OM software helps you increase productivity and quality by streamlining and standardizing your operational processes and workflows. You can also use OM software to improve your quality control, compliance, and traceability.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Gain competitive advantage and growth</h4>
                      <p>OM software helps you gain competitive advantage and growth by enabling you to adapt faster and better to changing market conditions, customer demands, and industry trends. You can also use OM software to innovate new products, services, and business models.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">How to choose the best OM Software for your business?</h2>
                        <p className="lead"><em>There are many OM software solutions available in the market that cater to different industries, sizes, budgets, and needs. You need to do some research and compare the features, benefits, pricing, reviews, etc. of different OM software solutions before making a decision.</em></p>
                        <p className="lead"><em>Some of the factors that you should consider when choosing an OM software solution are:</em></p>         
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">01</div>
                        <h4 className="mb-3">The size and nature of your business</h4>
                        <p>You need to choose an OM software solution that matches your business size and industry. For example, if you are a small business, you may need a simple and affordable OM software solution that covers the basic functions of operations management. If you are a large enterprise, you may need a more complex and robust OM software solution that supports multiple departments, locations, and features.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">02</div>
                        <h4 className="mb-3">The features and functions that you need</h4>
                        <p>You need to choose an OM software solution that offers the features and functions that you need for your business goals. For example, if you want to optimize your planning function, you may need an OM software solution that has demand forecasting, capacity planning, and inventory management features. If you want to optimize your execution function, you may need an OM software solution that has production scheduling, work order management, and quality assurance features.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">03</div>
                        <h4 className="mb-3">The ease of use and customization</h4>
                        <p>You need to choose an OM software solution that is easy to use and customize according to your needs. You don't want to spend too much time and effort learning how to use the system or hiring developers to customize it for you. You want an OM software solution that has a user-friendly interface, intuitive navigation, and flexible configuration options.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">04</div>
                        <h4 className="mb-3">The security and reliability</h4>
                        <p>You need to choose an OM software solution that is secure and reliable. You don't want to risk losing or compromising your operational data or facing downtime or glitches in the system. You want an OM software solution that has strong encryption, backup, recovery, and disaster recovery features. You also want an OM software solution that has high availability, performance, and scalability.</p>
                    </Col>
                    <Col lg={12} md={12}>
                        <div className="nos">05</div>
                        <h4 className="mb-3">The support and guidance</h4>
                        <p>You need to choose an OM software solution that provides you with support and guidance throughout your OM journey. You don't want to be left alone or frustrated when you encounter any problems or challenges with the system. You want an OM software solution that has a dedicated support team that is available 24/7 via phone, email, chat, or web. You also want an OM software solution that has a comprehensive knowledge base, tutorials, videos, webinars, blogs, forums, and other resources that can help you learn more about the system</p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our Operations Management software, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your animation and media needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default OperationsManagement;
