import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const AnimationMediaProduction = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Animation &amp; Media Production</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>Animation &amp; Media Production</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">We create engaging and captivating animations and media for your business</h2>
                        <p className="lead"><em>Are you looking for a way to create engaging and captivating animations and media for your business or personal needs? Do you want to showcase your products, services, stories, or ideas in a creative and effective way? Do you need to access the latest tools and technologies for animation and media production?</em></p>
                        <p className="lead"><em><span className="text-light">If you answered yes to any of these questions,</span> then you have come to the right place. We are an animation and media production service provider that can help you create stunning animations and media.<br/><br/>We offer a range of animation and media production services that include:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h3 className="mb-3">Animation Production</h3>
                      <p>We can help you produce 2D or 3D animations for various purposes, such as explainer videos, educational videos, promotional videos, etc. We can also help you create character designs, storyboards, scripts, voice-overs, sound effects, etc. for your animations.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h3 className="mb-3">Media Production</h3>
                      <p>We can help you produce live-action or animated media for various platforms, such as websites, social media, TV, cinema, etc. We can also help you create video editing, motion graphics, visual effects, color grading, etc. for your media.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h3 className="mb-3">Consulting &amp; Training</h3>
                      <p>We can help you plan and execute your animation and media projects using project management tools and methodologies. We can also help you comply with any regulatory and industry standards and best practices using compliance tools and audits. We can also help you train your staff on how to use the animation and media tools and techniques effectively using workshops and e-learning modules.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mt-4 mb-4">
                      <p className="lead"><em>We have worked with clients from various industries and domains, such as healthcare, education, finance, retail, e-commerce, etc. We have delivered successful animation and media projects that have helped our clients achieve their goals and impress their audiences.</em></p>
                      <p className="lead"><em>Some of our projects include:</em></p>         
                    </Col>
                    <Col lg={6} md={12}>
                      <Row>
                          <Col lg={6} md={6} className="mb-4">
                            <div class="sicon lg mb-3" data-aos="fade-down">
                              <img src="../assets/images/icons/health.svg" alt="healthcare" />
                            </div>
                            <p>Producing a 2D animation for a healthcare provider that explains their services and benefits to their potential customers.</p>
                          </Col>
                          <Col lg={6} md={6} className="mb-4">
                            <div class="sicon lg mb-3" data-aos="fade-down">
                              <img src="../assets/images/icons/education.svg" alt="education" />
                            </div>
                            <p>Producing a 3D animation for an education provider that illustrates their courses and curriculum to their prospective students.</p>
                          </Col>
                          <Col lg={6} md={6} className="mb-4">
                            <div class="sicon lg mb-3" data-aos="fade-down">
                              <img src="../assets/images/icons/database.svg" alt="database" />
                            </div>
                            <p>Producing a live-action video for a finance provider that showcases their success stories and testimonials to their existing customers.</p>
                          </Col>
                          <Col lg={6} md={6} className="mb-4">
                            <div class="sicon lg mb-3" data-aos="fade-down">
                              <img src="../assets/images/icons/ai.svg" alt="ai" />
                            </div>
                            <p>Producing an animated video for a retail provider that promotes their new products and offers to their target market.</p>
                          </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={12} className="text-center">
                      <img src="../assets/images/pages/thumbsup.svg" alt="thumbsup" className="w-100"/>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our animation and media production services, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your animation and media needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default AnimationMediaProduction;
