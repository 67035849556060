import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const BigData = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Big Data</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Technologies</li>
                      <li>Big Data</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <p className="lead"><em>Big data technology is defined as the technology and software utility that is designed for analysis, processing, and extraction of information from large and complex data sets that are difficult for traditional systems to deal with. Big data technology can help solve problems and make decisions in various domains, such as business, health, education, and social sciences.</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">What Big Data Technology can do</h2>
                      <p className="lead"><em>Big data technology can perform a wide range of tasks that require data collection and management, such as:</em></p>                       
                    </Col>
                    <Col lg={7} md={12}>
                      <Row>
                          <Col lg={6} md={6}>
                            <div className="nos">01</div>
                            <h4 className="mb-3">Data Ingestion</h4>
                            <p>Big data technology can collect and import data from various sources and formats into a common storage system or platform. Examples of data ingestion tools include Apache Kafka, Apache Flume, or Amazon Kinesis.</p>
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="nos">02</div>
                            <h4 className="mb-3">Data Storage</h4>
                            <p>Big data technology can store and organize data in a structured or unstructured way. Examples of data storage systems include relational databases, NoSQL databases, or data warehouses.</p>          
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="nos">03</div>
                            <h4 className="mb-3">Data Processing</h4>
                            <p>Big data technology can transform and manipulate data according to certain rules or logic. Examples of data processing tools include Apache Spark, Apache Hadoop, or Apache Pig.</p>          
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="nos">04</div>
                            <h4 className="mb-3">Data Analysis</h4>
                            <p>Big data technology can apply statistical methods and machine learning algorithms to explore and model data. Examples of data analysis tools include Python, R, or MATLAB.</p>          
                          </Col>
                      </Row>
                    </Col>
                    <Col lg={5} md={12}>
                      <img src="../assets/images/pages/bigdata.svg" alt="bigdata" className="w-100"/>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Benefits of Big Data Technology</h2>
                      <p className="lead"><em>Big data technology offers many benefits for businesses and individuals, such as:</em></p> 
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/line-chart.svg" alt="Improved Efficiency" />
                      </div>
                      <h4 className="mb-3">Improved Efficiency</h4>
                      <p>Big data technology can help automate and optimize processes and tasks that involve data collection, processing, analysis, and reporting, such as marketing campaigns, product development, or quality control.</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/mission.svg" alt="Enhanced Accuracy" />
                      </div>
                      <h4 className="mb-3">Enhanced Accuracy</h4>
                      <p>Big data technology can help reduce human errors and biases that may affect data quality and reliability, such as data entry errors, sampling errors, or confirmation bias.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/ai.svg" alt="Better Insights" />
                      </div>
                      <h4 className="mb-3">Better Insights</h4>
                      <p>Big data technology can help discover hidden patterns and relationships within data that may not be obvious or intuitive, such as customer segments, market opportunities, or causal factors.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/vision.svg" alt="Increased Innovation" />
                      </div>
                      <h4 className="mb-3">Increased Innovation</h4>
                      <p>Big data technology can help generate new ideas and solutions based on data-driven evidence and experimentation, such as product features, business models, or social impact.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Types of Big Data Technology</h2>
                      <p className="lead"><em>There are different types of big data technology based on the level of connectivity and functionality they provide:</em></p> 
                    </Col>
                    <Col lg={4} md={4}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Local area network (LAN) big data</h4>
                      <p>This is the type of big data technology that connects devices within a limited range, such as a home or an office. LAN big data uses wireless technologies such as WiFi or Bluetooth to communicate. Examples of LAN big data include smart speakers, smart lights, or smart locks.</p>
                    </Col>
                    <Col lg={4} md={4}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Wide area network (WAN) big data</h4>
                      <p>This is the type of big data technology that connects devices across a large distance, such as a city or a country. WAN big data uses cellular or satellite technologies to communicate. Examples of WAN big data include smart meters, smart cars, or smart cities.</p>          
                    </Col>
                    <Col lg={4} md={4}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Cloud-based big data</h4>
                      <p>This is the type of big data technology that connects devices to the internet and stores data in cloud servers. Cloud-based big data uses cloud computing platforms and services to process and analyze data. Examples of cloud-based big data include Google Cloud Platform, Amazon Web Services, or Microsoft Azure.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you're interested in learning more about our big data technology or want to get started with a free trial, please contact us today. We'll be happy to assist you with any questions or concerns you may have.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default BigData;
