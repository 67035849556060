import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";

const Partners = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Our Partners</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>About</li>
                      <li>Partners</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={12} md={12}>
                     <h2 className="mb-3">At <strong>LIFO Technologies Pvt. Ltd.,</strong><br/>we are proud to serve a diverse range of clients from various industries and sectors.</h2>
                     <p className="lead"><em>We work closely with IT product providers to create new growth opportunities and bring our customers the benefits of the latest technologies. We have partnerships with leaders in enterprise software products, data management systems and cloud technologies, which enable us to trust the quality of tech products we suggest.</em></p>          
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-5">
                <Row className="text-light">  
                    <Col lg={4} md={4} className="mb-4">
                        <Card className="card-custom partner h-100">
                            <Card.Img variant="top" src="../assets/images/pages/partners/aws.png" alt="aws" className="w-50" />
                            <Card.Body>
                                <Card.Title>AWS Partner Network</Card.Title>
                                <Card.Text>
                                    Today we design systems using AWS best practices like serverless functions, queueing, and elastic load balancers. We participate in the Amazon Partner Network because it gives us access to partner network resources, which means we can build the best systems for our clients.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} md={4} className="mb-4">
                        <Card className="card-custom partner h-100">
                            <Card.Img variant="top" src="../assets/images/pages/partners/microsoft.png" alt="microsoft" className="w-50" />
                            <Card.Body>
                                <Card.Title>Microsoft Partner Network</Card.Title>
                                <Card.Text>
                                    Today we design systems using MPN best practices like serverless functions, queueing, and elastic load balancers. We participate in the Microsoft Partner Network because it gives us access to partner network resources, which means we can build the best systems for our clients.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} md={4} className="mb-4">
                        <Card className="card-custom partner h-100">
                            <Card.Img variant="top" src="../assets/images/pages/partners/google.png" alt="google" className="w-50" />
                            <Card.Body>
                                <Card.Title>Google Partners</Card.Title>
                                <Card.Text>
                                    Today we design systems using GP best practices like serverless functions, queueing, and elastic load balancers. We participate in the Google Partner Network because it gives us access to partner network resources, which means we can build the best systems for our clients.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default Partners;
