import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Terms of Use</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Approach</li>
                      <li>Terms of Use</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={12} md={12}>
                     <h2 className="mb-3">Company Terms and Conditions</h2>
                     <p className="lead"><em>These terms and conditions (“Terms”) govern your access and use of our website, products and services (“Services”) provided by LIFO Technologies Pvt. Ltd. (“we”, “us”, “our”). By using our Services, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, you must not use our Services.</em></p> 
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-4">
                <Row className="text-light">
                    <Col lg={12} md={12}>
                        <h3>License</h3>
                        <p>We grant you a limited, non-exclusive, non-transferable, revocable license to use our Services for your personal or business purposes, subject to these Terms and any other policies or guidelines that we may publish from time to time. You may not copy, modify, distribute, sell, rent, lease, sublicense, or otherwise exploit our Services or any part of them without our prior written consent. You may not use our Services for any illegal, unethical, or harmful activities or in any way that violates the rights of others.</p>
                    </Col>
                    
                    <Col lg={12} md={12} className="mt-4">
                        <h3>User Content</h3>
                        <p>Some of our Services may allow you to create, upload, post, share, or store content such as text, images, videos, audio, or other materials (“User Content”). You are solely responsible for your User Content and the consequences of posting or sharing it. You represent and warrant that you own or have the necessary rights and permissions to use and share your User Content and that your User Content does not infringe or violate any intellectual property rights, privacy rights, or other rights of any third party. You also agree to comply with any applicable laws and regulations regarding your User Content.</p>
                        <p>We do not claim any ownership or control over your User Content. However, by submitting or posting your User Content on or through our Services, you grant us a worldwide, royalty-free, non-exclusive, perpetual, irrevocable license to use, reproduce, modify, adapt, publish, translate, distribute, perform, display, and create derivative works from your User Content in connection with our Services and our business purposes. You also waive any moral rights or other rights that you may have in your User Content.</p>
                        <p>We reserve the right to review, remove, edit, or disable access to any User Content at any time and for any reason in our sole discretion. We are not responsible or liable for the accuracy, quality, legality, or appropriateness of any User Content or for any loss or damage that may result from your use or reliance on any User Content.</p>
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>Feedback</h3>
                        <p>We welcome your feedback, suggestions, and ideas on how to improve our Services (“Feedback”). You can submit your Feedback by contacting us. By providing us with your Feedback, you grant us a worldwide, royalty-free, non-exclusive, perpetual, irrevocable license to use, reproduce, modify, adapt, publish, translate, distribute, perform, display, and create derivative works from your Feedback for any purpose. You also waive any moral rights or other rights that you may have in your Feedback.</p>
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>Third-Party Links</h3>
                        <p>Our Services may contain links to third-party websites, products or services that are not owned or controlled by us. We do not endorse or assume any responsibility for these third-party links or their content, practices, or policies. You access and use these third-party links at your own risk and subject to their own terms and conditions and privacy policies.</p>
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>Disclaimer</h3>
                        <p>Our Services are provided “as is” and “as available” without any warranties of any kind, either express or implied. We disclaim all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, non-infringement, and title. We do not warrant that our Services will be uninterrupted, error-free, secure, accurate, reliable, or timely or that they will meet your expectations or requirements. We do not warrant that any defects in our Services will be corrected or that our Services will be free from viruses or other harmful components. You use our Services at your own risk and discretion.</p>
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>Limitation of Liability</h3>
                        <p>To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, consequential, punitive, or exemplary damages arising from or relating to your access or use of or inability to access or use our Services or any User Content or other materials on or through our Services. This limitation of liability applies even if we have been advised of the possibility of such damages and regardless of the legal theory on which they are based. Some jurisdictions do not allow the exclusion or limitation of certain damages, so some of the above exclusions or limitations may not apply to you.</p>
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>Indemnification</h3>
                        <p>You agree to indemnify, defend, and hold harmless us and our affiliates, subsidiaries, officers, directors, employees, contractors, agents, partners, and licensors from and against any and all claims, demands, liabilities, damages, losses, costs, and expenses (including reasonable attorney fees) arising from or relating to: (a) your access or use of or inability to access or use our Services; (b) your User Content or Feedback; © your violation of these Terms or any applicable laws or regulations; (d) your infringement or violation of any rights of others; or (e) your conduct in connection with our Services.</p>
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>Termination</h3>
                        <p>We may terminate or suspend your access to or use of all or part of our Services at any time and for any reason in our sole discretion. We may also terminate or suspend your account and delete all or part of your User Content without notice and without liability to you. You may terminate your account and stop using our Services at any time by contacting us. Upon termination of these Terms for any reason: (a) the license granted to you in these Terms will end; (b) you must stop using our Services; © you must delete all copies of our Services from your devices; and (d) all provisions of these Terms that by their nature should survive will survive.</p>
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>Governing Law</h3>
                        <p>These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles. Any disputes arising from or relating to these Terms shall be subject to the exclusive jurisdiction and venue of the courts located in Chennai, India. You agree to submit to the personal jurisdiction of such courts and waive any objection to their jurisdiction and venue.</p>
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>Changes</h3>
                        <p>We may revise these Terms from time to time at our sole discretion. We will notify you of any changes by posting the updated Terms on our website or by sending you an email. Your continued use of our Services after the changes constitutes your acceptance of the revised Terms.</p>
                    </Col>
                    <Col lg={12} md={12} className="mt-4">
                        <h3>Contact Us</h3>
                        <p>If you have any questions or concerns about these Terms or our Services, please contact us at:</p>
                        <p><strong>LIFO Technologies Pvt. Ltd.,</strong><br/>12/7, 1st Main Road, SIDCO Nagar,<br/>Villivakkam, Chennai - 600049.<br/>+91 44 4387 6222</p>                  
                    </Col>
                </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Footer />
    </div>
  );
};

export default Terms;
