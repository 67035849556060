import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const DataExtractionMigration = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Data Extraction &amp; Migration</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>Data Extraction &amp; Migration</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Just ensure the quality, security, and integrity of your data.</h2>
                        <p className="lead"><em>Are you looking for a way to extract and migrate your data from various sources and formats? Do you want to consolidate and transform your data for your business needs? Do you need to ensure the quality, security, and integrity of your data?</em></p>
                        <p className="lead"><em>If you answered yes to any of these questions, then you have come to the right place. We are a data extraction and migration service provider that can help you extract and migrate your data. We offer a range of data extraction and migration services that include:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={3} md={6}>
                      <div className="nos">01</div>
                      <h3 className="mb-3">Data Extraction</h3>
                      <p>We can help you extract your data from various sources and formats, such as databases, web pages, documents, PDFs, images, etc. We can also help you clean and validate your data using data quality tools and techniques.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">02</div>
                      <h3 className="mb-3">Data Migration</h3>
                      <p>We can help you migrate your data to the destination of your choice, such as cloud platforms, data warehouses, data lakes, etc. We can also help you transform and enrich your data using data integration tools and techniques.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">03</div>
                      <h3 className="mb-3">Data Testing</h3>
                      <p>We can help you test your data before and after the migration using data testing tools and methods. We can also help you verify and reconcile your data using data comparison tools and techniques.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">04</div>
                      <h3 className="mb-3">Consulting &amp; Training</h3>
                      <p>We can help you plan and implement your data extraction and migration project using project management tools and methodologies. We can also help you comply with any regulatory and industry standards and best practices using compliance tools and audits.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mt-4 mb-4">
                      <p className="lead"><em>We have worked with clients from various industries and domains, such as healthcare, education, finance, retail, e-commerce, etc. We have delivered successful data extraction and migration projects that have helped our clients consolidate and transform their data for their business needs. </em></p>
                      <p className="lead"><em>Some of our projects include:</em></p>         
                    </Col>
                    <Col lg={6} md={12}>
                      <Row>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/health.svg" alt="health" />
                          </div>
                          <p>Extracting and migrating the patient records for a healthcare provider from multiple databases to a cloud platform that enables better analytics and reporting.</p>
                        </Col>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/education.svg" alt="education" />
                          </div>
                          <p>Extracting and migrating the course content for an education provider from various documents and PDFs to a web application that enables online learning and collaboration.</p>
                        </Col>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/database.svg" alt="database" />
                          </div>
                          <p>Extracting and migrating the financial transactions for a finance provider from various web pages to a data warehouse that enables faster processing and analysis.</p>
                        </Col>
                        <Col lg={6} md={6} className="mb-4">
                          <div class="sicon lg mb-3" data-aos="fade-down">
                            <img src="../assets/images/icons/ai.svg" alt="ai" />
                          </div>
                          <p>Extracting and migrating the product catalog for a retail provider from various images and PDFs to a data lake that enables richer search and recommendation.</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={12} className="text-center">
                      <img src="../assets/images/pages/thumbsup.svg" alt="thumbsup" className="w-100"/>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our data extraction and migration services, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your data extraction and migration needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default DataExtractionMigration;
