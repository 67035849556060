import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const VirtualReality = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Virtual Reality</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Technologies</li>
                      <li>Virtual Reality</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <p className="lead"><em>Virtual reality technology is a type of computer technology that creates simulated environments that immerse the user in a three-dimensional (3-D) visual or other sensory experience. Virtual reality technology can enable the user to interact with the virtual environment and objects using various devices, such as headsets, gloves, controllers, or body suits.</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-3">
                      <h2 className="mb-3">What Virtual Reality Technology can do</h2>
                      <p className="lead"><em>Virtual reality technology can enable various applications that require realistic and engaging simulations, such as:</em></p>                       
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/game.svg" alt="game" />
                      </div>
                      <h4 className="mb-3">Gaming</h4>
                      <p>Virtual reality technology can enhance the entertainment and immersion of video games by allowing the user to explore and manipulate the game world as if they were there.</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/education.svg" alt="education" />
                      </div>
                      <h4 className="mb-3">Education</h4>
                      <p>Virtual reality technology can facilitate learning and training by providing interactive and immersive scenarios that simulate real-world situations, such as medical procedures, historical events, or scientific experiments.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/airplane.svg" alt="airplane" />
                      </div>
                      <h4 className="mb-3">Tourism</h4>
                      <p>Virtual reality technology can enable the user to travel and experience different places and cultures without leaving their home, such as museums, landmarks, or natural wonders.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/health.svg" alt="health" />
                      </div>
                      <h4 className="mb-3">Healthcare</h4>
                      <p>Virtual reality technology can assist in diagnosis, treatment, and rehabilitation of various health conditions by providing realistic and controlled simulations that can reduce pain, anxiety, or stress.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Benefits of Virtual Reality Technology</h2>
                      <p className="lead"><em>Virtual reality technology offers many benefits for businesses and individuals, such as:</em></p> 
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/money-bag.svg" alt="money-bag" />
                      </div>
                      <h4 className="mb-3">Cost-effectiveness</h4>
                      <p>Virtual reality technology can reduce the expenses and risks associated with physical activities, such as travel, equipment, or safety measures.</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/access-control.svg" alt="access-control" />
                      </div>
                      <h4 className="mb-3">Accessibility</h4>
                      <p>Virtual reality technology can provide access to information and experiences that may otherwise be unavailable or inaccessible due to distance, time, or disability.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/vision.svg" alt="vision" />
                      </div>
                      <h4 className="mb-3">Creativity</h4>
                      <p>Virtual reality technology can stimulate imagination and innovation by allowing the user to create and explore new worlds and possibilities.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/helping.svg" alt="helping" />
                      </div>
                      <h4 className="mb-3">Empathy</h4>
                      <p>Virtual reality technology can foster understanding and compassion by allowing the user to see and feel different perspectives and emotions.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Types of Virtual Reality Technology</h2>
                      <p className="lead"><em>There are different types of virtual reality technology based on the level of immersion and interaction they provide:</em></p> 
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Non-immersive virtual reality</h4>
                      <p>This is the type of virtual reality technology that provides a partial simulation of a virtual environment on a screen or a projector. The user can interact with the virtual environment using a mouse, a keyboard, or a joystick. Examples of non-immersive virtual reality include flight simulators, racing games, or Google Street View.</p>
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Semi-immersive virtual reality</h4>
                      <p>This is the type of virtual reality technology that provides a more realistic simulation of a virtual environment on a large screen or a surround sound system. The user can interact with the virtual environment using motion sensors, trackers, or controllers. Examples of semi-immersive virtual reality include CAVE systems, IMAX theaters, or VR arcades.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Fully immersive virtual reality</h4>
                      <p>This is the type of virtual reality technology that provides a complete simulation of a virtual environment on a head-mounted display (HMD) or a full-body suit. The user can interact with the virtual environment using eye tracking, gesture recognition, or haptic feedback. Examples of fully immersive virtual reality include Oculus Rift, HTC Vive, or PlayStation VR.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you're interested in learning more about our virtual reality technology or want to get started with a free trial, please contact us today. We'll be happy to assist you with any questions or concerns you may have.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default VirtualReality;
