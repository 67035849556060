import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const Healthcare = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Healthcare</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Industries</li>
                      <li>Healthcare</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-4">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Our Solutions for Healthcare Industries</h2>
                        <p className="lead"><em>The healthcare industry is facing unprecedented challenges and opportunities in the digital age. Healthcare providers and payers need to deliver data-driven, patient-centered care that improves outcomes, reduces costs, and enhances experiences. They also need to adopt innovative technologies that enable them to transform their processes, workflows, and business models.</em></p>
                        <p className="lead"><em>At LIFO, we offer smart and scalable solutions that help healthcare organizations achieve their goals and address their pain points. Our solutions cover various aspects of the healthcare value chain, such as:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Telemedicine Software</h4>
                      <p>We provide e-health solutions that enable providers to deliver online services via mobile apps and web portals. Our telemedicine software allows patients to access quality care anytime, anywhere, and reduces the burden on the healthcare system. It also facilitates remote consultation, diagnosis, treatment, and follow-up, and improves patient satisfaction and loyalty.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">EHR Software</h4>
                      <p>We develop electronic health record (EHR) software that helps providers store, manage, and share patient data securely and efficiently. Our EHR software complies with industry standards and regulations, and integrates with other systems and devices. It also enables providers to access comprehensive patient information, coordinate care across teams and settings, and improve clinical outcomes and quality measures.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Medical Imaging and Virtualization Software</h4>
                      <p>We create medical imaging and virtualization software that helps providers diagnose, treat, and monitor patients using advanced technologies. Our software supports various modalities, such as MRI, CT, ultrasound, and X-ray, and enables 3D visualization and analysis of medical images. It also allows providers to perform minimally invasive procedures, reduce radiation exposure, and enhance patient safety and comfort.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Hospital Management Software</h4>
                      <p>We design hospital management software that helps providers optimize their operations, resources, and finances. Our software automates various tasks, such as scheduling, billing, inventory, reporting, and quality management, and provides real-time insights and alerts. It also helps providers improve their efficiency, productivity, profitability, and compliance.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">05</div>
                      <h4 className="mb-3">Medical Billing Software</h4>
                      <p>We offer medical billing software that helps providers streamline their revenue cycle management and improve their cash flow. Our software handles various processes, such as claims submission, verification, denial management, payment processing, and collections. It also helps providers reduce errors, frauds, and delays, and increase their reimbursement rates and customer satisfaction.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">06</div>
                      <h4 className="mb-3">Medical Diagnosis Software</h4>
                      <p>We build medical diagnosis software that helps providers improve their clinical decision making and accuracy. Our software leverages artificial intelligence (AI) and machine learning (ML) to analyze patient data and symptoms, and provide diagnosis suggestions and recommendations. It also helps providers reduce misdiagnosis, overdiagnosis, underdiagnosis, and unnecessary tests and treatments.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">07</div>
                      <h4 className="mb-3">E-prescription Software</h4>
                      <p>We deliver e-prescription software that helps providers prescribe medications electronically and securely. Our software connects with pharmacies, insurance companies, and patients, and reduces errors, frauds, and delays. It also helps providers improve their adherence to guidelines, protocols, and formularies.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">08</div>
                      <h4 className="mb-3">Health Tracking Applications</h4>
                      <p>We develop health tracking applications that help patients monitor their health conditions and wellness goals. Our applications collect and display various health metrics, such as blood pressure, heart rate, glucose level, sleep quality, and activity level. They also help patients track their medication intake, manage their chronic diseases, and receive personalized feedback and guidance.</p>          
                    </Col>
                    <Col lg={12} md={12} className="mt-5">
                      <p>LIFO Technologies Pvt. Ltd., is a trusted partner for healthcare organizations across the globe. We have extensive experience and expertise in developing customized solutions that meet the specific needs and challenges of each client. We use the latest technologies and best practices to ensure quality, reliability, and scalability of our solutions.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for software company solutions for healthcare industries that can help you transform your health outcomes and experiences contact us today for a free consultation. We would love to hear from you!</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default Healthcare;
