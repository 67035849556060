import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const Manufacturing = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Manufacturing</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Industries</li>
                      <li>Manufacturing</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-4">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Our Solutions for Manufacturing Industries</h2>
                        <p className="lead"><em>The manufacturing industry is undergoing a digital transformation that requires innovative solutions to meet the changing demands of customers, suppliers, and regulators. Manufacturers need to optimize their operations, improve their efficiency, and enhance their competitiveness in the global market.</em></p>
                        <p className="lead"><em>At LIFO, we offer software company solutions for manufacturing industries that help you achieve your business goals and overcome your challenges. Our solutions cover various aspects of the manufacturing value chain, such as:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={4} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Inventory Management</h4>
                      <p>We provide inventory management software that helps you track and control your inventory levels, locations, and movements. Our software enables you to reduce inventory costs, avoid stockouts, and improve customer service.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Bills of Material</h4>
                      <p>We develop bills of material (BOM) software that helps you calculate and manage the costs and components of your products. Our software allows you to create and update BOMs, manage revisions and changes, and integrate with other systems and processes.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Production Scheduling</h4>
                      <p>We create production scheduling software that helps you plan and optimize your production activities, resources, and capacities. Our software enables you to balance demand and supply, reduce lead times and waste, and increase productivity and quality.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Order Management</h4>
                      <p>We design order management software that helps you process and fulfill orders from customers and vendors. Our software automates order entry, confirmation, invoicing, and shipping, and provides real-time visibility and tracking.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div className="nos">05</div>
                      <h4 className="mb-3">Product Lifecycle Management</h4>
                      <p>We build product lifecycle management (PLM) software that helps you manage the entire lifecycle of your products, from design to disposal. Our software supports innovation, collaboration, compliance, and quality across your product portfolio.</p>          
                    </Col>
                    <Col lg={12} md={12} className="mt-5">
                      <p>LIFO Technologies Pvt. Ltd., is a trusted partner for manufacturing industries across the globe. We have extensive experience and expertise in developing customized solutions that meet the specific needs and challenges of each industry segment, such as discrete manufacturing, process manufacturing, and mixed-mode manufacturing. We use the latest technologies and best practices to ensure quality, reliability, and scalability of our solutions.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for software company solutions for manufacturing industries that can help you transform your manufacturing performance and competitiveness contact us today for a free consultation. We would love to hear from you!</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default Manufacturing;
