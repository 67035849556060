import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const BlockChain = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Blockchain</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Technologies</li>
                      <li>Blockchain</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <p className="lead"><em>Big data technology is defined as the technology and software utility that is designed for analysis, processing, and extraction of information from large and complex data sets that are difficult for traditional systems to deal with. Big data technology can help solve problems and make decisions in various domains, such as business, health, education, and social sciences.</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-3">
                      <h2 className="mb-3">What Big Data Technology Can Do</h2>
                      <p className="lead"><em>Blockchain technology can enable various applications that require trust, security, and efficiency, such as:</em></p>                       
                    </Col>
                    <Col lg={7} md={12}>
                      <Row>
                          <Col lg={6} md={6}>
                            <div className="nos">01</div>
                            <h4 className="mb-3">Cryptocurrencies</h4>
                            <p>Blockchain technology can create digital currencies that are decentralized, scarce, and censorship-resistant, such as Bitcoin, Ethereum, or Litecoin.</p>
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="nos">02</div>
                            <h4 className="mb-3">Supply chain management</h4>
                            <p>Blockchain technology can improve the traceability and accountability of products and materials across the supply chain, such as food, pharmaceuticals, or diamonds.</p>          
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="nos">03</div>
                            <h4 className="mb-3">Identity Management</h4>
                            <p>Blockchain technology can provide a secure and verifiable way of storing and sharing personal information, such as identity documents, credentials, or medical records.</p>          
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="nos">04</div>
                            <h4 className="mb-3">Voting Systems</h4>
                            <p>Blockchain technology can enhance the transparency and integrity of voting processes, such as elections, referendums, or polls.</p>          
                          </Col>
                      </Row>
                    </Col>
                    <Col lg={5} md={12} className="text-center">
                      <img src="../assets/images/pages/blockchain.svg" alt="blockchain" className="w-75"/>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Benefits of Blockchain Technology</h2>
                      <p className="lead"><em>Blockchain technology offers many benefits for businesses and individuals, such as:</em></p> 
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/security.svg" alt="security" />
                      </div>
                      <h4 className="mb-3">Immutability</h4>
                      <p>Blockchain technology can prevent data tampering and fraud by using cryptographic hashes and consensus mechanisms to ensure that transactions are valid and irreversible.</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/access-control.svg" alt="access-control" />
                      </div>
                      <h4 className="mb-3">Transparency</h4>
                      <p>Blockchain technology can increase data visibility and auditability by providing a shared and synchronized ledger that is accessible to all authorized participants.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/line-chart.svg" alt="line-chart" />
                      </div>
                      <h4 className="mb-3">Efficiency</h4>
                      <p>Blockchain technology can reduce transaction costs and delays by eliminating intermediaries and streamlining processes.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/vision.svg" alt="vision" />
                      </div>
                      <h4 className="mb-3">Innovation</h4>
                      <p>Blockchain technology can enable new business models and solutions based on data-driven evidence and experimentation, such as decentralized applications (DApps), decentralized autonomous organizations (DAOs), or tokenization.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Types of Blockchain Technology </h2>
                      <p className="lead"><em>There are different types of blockchain technology based on the level of access and governance they provide:</em></p> 
                    </Col>
                    <Col lg={4} md={4}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Public Blockchains</h4>
                      <p>These are open and permissionless networks that anyone can join and participate in. Public blockchains are typically maintained by a large number of nodes that follow a consensus protocol to validate transactions. Examples of public blockchains include Bitcoin, Ethereum, or Litecoin.</p>
                    </Col>
                    <Col lg={4} md={4}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Private Blockchains</h4>
                      <p>These are closed and permissioned networks that only allow authorized participants to join and access. Private blockchains are typically controlled by a single entity or a consortium that sets the rules and permissions for the network. Examples of private blockchains include Hyperledger Fabric, Corda, or Quorum.</p>          
                    </Col>
                    <Col lg={4} md={4}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Hybrid Blockchains</h4>
                      <p>These are networks that combine aspects of public and private blockchains. Hybrid blockchains may allow some transactions to be public while others are private, or they may use different consensus mechanisms for different types of transactions. Examples of hybrid blockchains include Dragonchain, Kadena, or XinFin.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you're interested in learning more about our blockchain technology or want to get started with a free trial, please contact us today. We'll be happy to assist you with any questions or concerns you may have.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default BlockChain;
