import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const DataAnalytics = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Data Analytics</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>Data Analytics</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={8} md={12}>
                     <h2 className="mb-3">Data Analytics Services</h2>
                     <p className="lead"><em>We are a team of expert data analysts who help you unlock powerful insights from your data and drive informed decisions for your business.</em></p>          
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-5">
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                      <h2 className="mb-3">What we do</h2>
                      <p className="lead"><em>We offer a range of data analytics services to help you collect, process, and present your data in the form of actionable insights. Whether you need descriptive, diagnostic, predictive, or prescriptive analytics, we can help you achieve your goals.</em></p>
                      <p className="lead"><em>Our data analytics services include:</em></p>                      
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h3 className="mb-3">Data Collection</h3>
                      <p>We help you gather data from various sources such as databases, applications, sensors, web pages, social media, and more. We use various methods and tools such as web scraping, APIs, ETL, and IoT to collect data efficiently and reliably.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h3 className="mb-3">Data Processing</h3>
                      <p>We help you clean, transform, and enrich your data to make it ready for analysis. We use various techniques and tools such as data quality, data integration, data engineering, and data warehousing to process data effectively and securely.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h3 className="mb-3">Data Analysis</h3>
                      <p>We help you analyze your data using various methods and tools such as statistics, machine learning, artificial intelligence, and natural language processing. We use various types of analytics such as descriptive, diagnostic, predictive, and prescriptive to generate insights that answer your business questions.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h3 className="mb-3">Data Presentation</h3>
                      <p>We help you present your data and insights in the form of dashboards, reports, visualizations, and stories. We use various tools and platforms such as Tableau, Power BI, Cognos, and Salesforce to create engaging and interactive data presentations.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-5">
                      <h2 className="mb-3">Why choose us</h2>
                      <p className="lead"><em>We are not just data analysts, we are solution partners.<br/>We care about your data and your business. We strive to provide data analytics services that are:</em></p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/testing.svg" alt="testing" />
                      </div>
                      <h3 className="mb-3">Comprehensive</h3>
                      <p>We cover all aspects of data analytics from data collection to data presentation. We ensure that your data is optimally collected, processed, analyzed, and presented.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/money-bag.svg" alt="money-bag" />
                      </div>
                      <h3 className="mb-3">Cost-Effective</h3>
                      <p>We cover all aspects of data analytics from data collection to data presentation. We ensure that your data is optimally collected, processed, analyzed, and presented.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/flexibility.svg" alt="flexibility" />
                      </div>
                      <h3 className="mb-3">Flexible</h3>
                      <p>We adapt to your needs and preferences. We can work with any data source, format, volume, or domain. We can work on-premise or on-cloud, as part of your team or independently.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/website.svg" alt="website" />
                      </div>
                      <h3 className="mb-3">Experienced</h3>
                      <p>We have a team of skilled and certified data analysts who have extensive experience in various types of data analytics across different industries and domains. We have successfully completed many data analytics projects for clients worldwide.</p>
                    </Col>
                </Row>
                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>We have a team of skilled and certified data analysts who have extensive experience in various types of data analytics across different industries and domains. We have successfully completed many data analytics projects for clients worldwide.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default DataAnalytics;
