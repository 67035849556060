import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const ERP = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>ERP</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Solutions</li>
                      <li>ERP</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Streamline Your Business Processes with Enterprise Resource Planning</h2>
                        <p className="lead"><em>Do you want to streamline your business processes and improve your operational efficiency?<br/>Do you want to integrate and automate your various business functions, such as accounting, inventory, production, sales, and human resources?<br/>Do you want to reduce your operational costs and increase your profitability?</em></p>
                        <p className="lead"><em><span className="text-light">If you answered yes to any of these questions,</span> then you need ERP.</em></p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={6} md={12}>
                        <h2 className="mb-3">What is ERP?</h2>
                        <p className="lead"><em>ERP stands for Enterprise Resource Planning. It is a technology that helps you manage and optimize your business processes by integrating and automating your various business functions. ERP software allows you to:</em></p>
                        <ul className="custom-list">
                            <li>Manage and access all your business data in one place</li>
                            <li>Integrate and automate your various business functions, such as accounting, inventory, production, sales, and human resources</li>
                            <li>Streamline and optimize your business processes and workflows</li>
                            <li>Reduce errors and duplication of data and tasks</li>
                            <li>Increase visibility and transparency across your organization</li>
                            <li>Generate insights and reports to improve your decision making and performance</li>
                        </ul>
                        <p>ERP software is not just a tool for large enterprises. It is a comprehensive solution that supports businesses of all sizes and industries. ERP software helps you create a unified and efficient system that can handle all your business needs.</p>
                    </Col>
                    <Col lg={6} md={12} className="text-center mt-4">
                      <img src="../assets/images/pages/erp.svg" alt="erp" className="w-75"/>
                    </Col>
                    <div className="line space"></div>
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">Why you need ERP for your business?</h2>
                        <p className="lead"><em>ERP software can help you achieve your business goals in many ways. Here are some of the benefits of using ERP software:</em></p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Increase operational efficiency and productivity</h4>
                      <p>ERP software helps you streamline and optimize your business processes and workflows by integrating and automating your various business functions. You can eliminate manual tasks, reduce errors, save time, and increase output.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Reduce operational costs and increase profitability</h4>
                      <p>ERP software helps you reduce your operational costs by eliminating waste, duplication, and inefficiency. You can also increase your profitability by improving your inventory management, production planning, cost control, and revenue management.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Improve customer satisfaction and loyalty</h4>
                      <p>ERP software helps you improve your customer satisfaction and loyalty by providing faster and better service, delivery, and support. You can also use ERP software to enhance your customer relationship management, marketing, and sales functions.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Gain competitive advantage and growth</h4>
                      <p>ERP software helps you gain competitive advantage and growth by enabling you to adapt to changing market conditions, customer demands, and industry trends. You can also use ERP software to innovate new products, services, and business models.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-4">
                        <h2 className="mb-3">How to choose the best ERP Software for your business?</h2>
                        <p className="lead"><em>There are many ERP software solutions available in the market that cater to different industries, sizes, budgets, and needs. You need to do some research and compare the features, benefits, pricing, reviews, etc. of different ERP software solutions before making a decision.</em></p>
                        <p className="lead"><em>Some of the factors that you should consider when choosing an ERP software solution are:</em></p>         
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">01</div>
                        <h4 className="mb-3">The size and nature of your business</h4>
                        <p>You need to choose an ERP software solution that matches your business size and industry. For example, if you are a small business, you may need a simple and affordable ERP software solution that covers the basic functions of enterprise resource planning. If you are a large enterprise, you may need a more complex and robust ERP software solution that supports multiple departments, locations, and currencies.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">02</div>
                        <h4 className="mb-3">The features and functions that you need</h4>
                        <p>You need to choose an ERP software solution that offers the features and functions that you need for your business goals. For example, if you want to improve your accounting function, you may need an ERP software solution that has financial management, budgeting, reporting, and auditing features. If you want to improve your production function, you may need an ERP software solution that has inventory management, production planning, quality control, and supply chain management features.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">03</div>
                        <h4 className="mb-3">The ease of use and customization</h4>
                        <p>You need to choose an ERP software solution that is easy to use and customize according to your needs. You don't want to spend too much time and effort learning how to use the system or hiring developers to customize it for you. You want an ERP software solution that has a user-friendly interface, intuitive navigation, and flexible configuration options.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">04</div>
                        <h4 className="mb-3">The security and reliability</h4>
                        <p>You need to choose an ERP software solution that is secure and reliable. You don't want to risk losing or compromising your business data or facing downtime or glitches in the system. You want an ERP software solution that has strong encryption, backup, recovery, and disaster recovery features. You also want an ERP software solution that has high availability, performance, and scalability.</p>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="nos">05</div>
                        <h4 className="mb-3">The support and guidance</h4>
                        <p>You need to choose an ERP software solution that provides you with support and guidance throughout your ERP journey. You don't want to be left alone or frustrated when you encounter any problems or challenges with the system. You want an ERP software solution that has a dedicated support team that is available 24/7 via phone, email, chat, or web. You also want an ERP software solution that has a comprehensive knowledge base, tutorials, videos, webinars, blogs, forums, and other resources that can help you learn more about the system</p>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our ERP solutions, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your animation and media needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default ERP;
