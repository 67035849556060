import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const ProcessAutomation = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Process Automation</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>Process Automation</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">We can help you improve quality, consistency, and reliability</h2>
                        <p className="lead"><em>Process automation is the use of automation tools and technologies to perform business processes and workflows without human intervention. It can help you save time, money, and resources. It can also help you improve quality, consistency, and reliability. It can also help you adapt to the changing customer needs and expectations.</em></p>
                        <p className="lead"><em>But process automation is not easy. It requires a thorough analysis, a robust implementation, and a continuous optimization. It also requires a cultural change and a mindset shift in your organization. That's why you need a reliable and professional partner to help you with your process automation journey.</em></p>
                        <p className="lead"><em>We are a process automation service provider that can help you automate your business processes and workflows. We have a team of experienced and skilled process automation experts who can help you with:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h3 className="mb-3">Process Analysis</h3>
                      <p>We can help you understand your current business processes and workflows and find out the opportunities for automation. We can also help you envision your desired future state and set the automation objectives and indicators.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h3 className="mb-3">Managed Cloud</h3>
                      <p>We can help you automate your business processes and workflows using various automation tools and technologies, such as robotic process automation (RPA), business process management (BPM), artificial intelligence (AI), etc. We can also help you connect your automation solutions with your existing systems and applications.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h3 className="mb-3">Process Optimization</h3>
                      <p>We can help you improve your automated business processes and workflows using data analytics and continuous improvement methods. We can also help you track and evaluate your automation results and impacts using dashboards and reports.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h3 className="mb-3">Consulting &amp; Training</h3>
                      <p>We can help you plan and execute your process automation strategy and governance using frameworks and models. We can also help you follow any regulatory and industry standards and best practices using compliance tools and audits. We can also help you educate your staff on how to use the automation solutions effectively using workshops and e-learning modules.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mt-4 mb-4">
                      <p className="lead"><em>We have worked with clients from various industries and domains, such as healthcare, education, finance, retail, e-commerce, etc. We have delivered successful process automation projects that have helped our clients save time, money, and resources.</em></p>
                      <p className="lead"><em>Some of our projects include:</em></p>         
                    </Col>
                    <Col lg={6} md={12}>
                      <Row>
                      <Col lg={6} md={6} className="mb-4">
                        <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/health.svg" alt="healthcare" />
                        </div>
                        <p>Automating the invoice processing for a healthcare provider that reduces manual errors, delays, and costs.</p>
                      </Col>
                      <Col lg={6} md={6} className="mb-4">
                        <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/education.svg" alt="education" />
                        </div>
                        <p>Automating the course registration for an education provider that enables online enrollment and payment for students.</p>
                      </Col>
                      <Col lg={6} md={6} className="mb-4">
                        <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/database.svg" alt="database" />
                        </div>
                        <p>Automating the loan approval for a finance provider that improves customer satisfaction and retention.</p>
                      </Col>
                      <Col lg={6} md={6} className="mb-4">
                        <div class="sicon lg mb-3" data-aos="fade-down">
                          <img src="../assets/images/icons/ai.svg" alt="ai" />
                        </div>
                        <p>Automating the order fulfillment for a retail provider that enhances customer experience and loyalty.</p>
                      </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={12} className="text-center">
                      <img src="../assets/images/pages/thumbsup.svg" alt="thumbsup" className="w-100"/>
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our process automation services, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your process automation needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default ProcessAutomation;
