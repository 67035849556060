import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const TransportationLogistics = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Transportation &amp;<br/>Logistics</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Industries</li>
                      <li>Transportation &amp; Logistics</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-4">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Our Solutions for Transportation and Logistics Industries</h2>
                        <p className="lead"><em>The transportation and logistics industry is facing unprecedented challenges and opportunities in the digital age. Transportation and logistics companies need to deliver faster, cheaper, and safer services that meet the evolving demands of customers, regulators, and partners. They also need to leverage data, analytics, and technology to optimize their operations, improve their efficiency, and enhance their competitiveness.</em></p>
                        <p className="lead"><em>At LIFO, we offer software company solutions for transportation and logistics industries that help you achieve your business goals and overcome your challenges. Our solutions cover various aspects of the transportation and logistics value chain, such as:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Fleet Management Software</h4>
                      <p>We provide fleet management software that helps you track and manage your vehicles and drivers in real-time. Our fleet management software enables you to monitor vehicle location, speed, fuel consumption, maintenance, and safety. It also allows you to plan and optimize routes, assign and dispatch drivers, and communicate with drivers and customers.</p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Warehouse Management Software</h4>
                      <p>We develop warehouse management software that helps you manage your inventory and warehouse operations. Our warehouse management software allows you to automate inventory processes, such as receiving, putaway, picking, packing, and shipping. It also enables you to control inventory levels, locations, and movements, and ensure inventory accuracy and visibility.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Transportation Management Software</h4>
                      <p>We create transportation management software that helps you manage your transportation processes, such as planning, execution, tracking, and settlement. Our transportation management software enables you to compare and select carriers, rates, and modes, book and confirm shipments, track shipment status and performance, and process invoices and payments.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Logistics Marketplace Software</h4>
                      <p>We design logistics marketplace software that helps you offer and sell your transportation and logistics services online. Our logistics marketplace software enables you to create and customize your own branded website or app, connect with multiple shippers and carriers, provide online quotes and bookings, and facilitate online payments and reviews.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">05</div>
                      <h4 className="mb-3">Logistics Analytics Software</h4>
                      <p>We build logistics analytics software that helps you leverage your data to gain insights and make better decisions. Our logistics analytics software enables you to collect and integrate data from various sources, apply advanced analytics techniques such as AI and ML, visualize and explore data using dashboards and charts, and generate actionable recommendations.</p>          
                    </Col>
                    <Col lg={12} md={12} className="mt-5">
                      <p>LIFO Technologies Pvt. Ltd., is a trusted partner for transportation and logistics industries across the globe. We have extensive experience and expertise in developing customized solutions that meet the specific needs and challenges of each industry segment, such as air, rail, road, and sea transport, as well as courier, express, and parcel services. We use the latest technologies and best practices to ensure quality, reliability, and scalability of our solutions.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for software company solutions for transportation and logistics industries that can help you transform your transportation and logistics performance and competitiveness contact us today for a free consultation. We would love to hear from you!</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default TransportationLogistics;
