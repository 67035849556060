import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
const RefferalProgram = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Refferal Program</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Join Us</li>
                      <li>Refferal Program</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={8} md={12}>
                    <p className="lead"><em>Do you know someone who would be a great addition to our team?<br/>If yes, then you can earn a reward by referring them to us!</em></p>                    
                    <p>At LIFO Technologies Pvt. Ltd., we are always looking for talented and passionate people who can help us create amazing software solutions for our clients. We believe that the best way to find such people is through referrals from our existing employees, customers, partners, and friends. That's why we have launched our referral program that allows you to earn cash or other incentives for referring qualified candidates to us.</p>
                  </Col>
              </Row>
            </Container>          
            <Container className="text-light mt-4">
                <Row className="text-light">
                    <Col lg={12} md={12} className="mt-4">
                        <h3>How It Works</h3>
                        <p className="lead"><em>The referral program is simple and easy to follow. Here are the steps:</em></p>
                        <ul className="custom-list py-0">
                            <li>Find a suitable candidate for one of our open positions. You can check our careers page for the current openings and the job descriptions.</li>
                            <li>Send a referral email with your and the candidate's details. You can send to here: <a href="mailto:careers@lifotechnologies.com">careers@lifotechnologies.com</a>.</li>
                            <li>Submit the form and wait for our confirmation email. We will review your referral and let you know if it meets our criteria.</li>
                            <li>If your referral is accepted, we will contact the candidate and invite them to apply for the position. We will also keep you updated on the status of their application.</li>
                            <li>If your referral is hired and completes a probationary period of 90days, you will receive your reward. The reward amount depends on the position and the level of the candidate.</li>                            
                        </ul>
                        <div className="line space"></div>
                        <h3 className="mt-5">Terms of Refferal Program</h3>
                        <p className="lead"><em>The referral program is subject to the following terms and conditions:</em></p>
                        <ul className="custom-list py-0">
                            <li>Find a suitable candidate for one of our open positions. You can check our careers page for the current openings and the job descriptions.</li>
                            <li>You must have a valid email address and bank account to receive your reward.</li>
                            <li>You must not be an employee, contractor, or agent of LIFO Technologies Pvt. Ltd., or any of its affiliates or subsidiaries.</li>
                            <li>You must not refer yourself or anyone who is already employed by or has applied to LIFO Technologies Pvt. Ltd., or any of its affiliates or subsidiaries.</li>
                            <li>You must not refer anyone who is related to you by blood or marriage or who lives in the same household as you.</li>
                            <li>You must not use any fraudulent, deceptive, or unethical methods to refer candidates or to influence their hiring decisions.</li>
                            <li>You must comply with all applicable laws and regulations regarding referrals and rewards.</li>
                            <li>You must agree to our privacy policy and consent to our collection, use, and disclosure of your and the candidate's personal information for the purposes of the program.</li>
                            <li>You must acknowledge that LIFO Technologies Pvt. Ltd., has the sole discretion to accept or reject any referral, to modify or terminate the program at any time, and to determine the eligibility and amount of rewards.</li>
                        </ul>
                    </Col>
                    <div className="line space"></div>
                    <Col lg={12} md={12} className="">
                        <h3>Contact Us</h3>
                        <p>If you have any questions or feedback about the referral program, please contact us at <a href="mailto:info@lifotechnologies.com">info@lifotechnologies.com</a> or <a href="tell:+91 44 4387 6222">+91 44 4387 6222</a>. We would love to hear from you!</p>                        
                    </Col>
                </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Footer />
    </div>
  );
};

export default RefferalProgram;
