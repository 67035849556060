import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
import Values from "../../Components/Values";

const Company = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>About Company</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>About</li>
                      <li>About Company</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container>
              <Row className="text-light">             
                  <Col lg={7} className="">
                    <h2 className="mb-4">One of the Fastest Growing<br/><strong>IT Services Company</strong> in India</h2>
                    <p className="sub-title">We are LIFO Technologies Pvt. Ltd. a software development company that specializes in custom web and mobile applications. We provide innovative and scalable solutions for various industries, such as education, healthcare, finance, and entertainment.</p>                    
                    <p>We help our clients transform their ideas into reality. We use the latest technologies, such as cloud computing, artificial intelligence, and blockchain, to deliver high-quality and secure products. We offer end-to-end services, from design and development to testing and maintenance.</p>
                    <p>We started our company in 2010 with a vision to create software that makes a difference. We saw a need for more user-friendly and accessible applications that can solve real-world problems and improve people's lives. We decided to create LIFO as a platform where we can apply our skills and creativity to build impactful products.</p>
                    <p> We are passionate about technology and innovation. We believe that software can change the world for the better. We want to empower our clients and users with solutions that are smart, efficient, and reliable. We strive to exceed expectations and deliver excellence in everything we do.</p>
                    <p>We are a team of 50+ software engineers, designers, testers, and project managers. We are diverse, talented, and collaborative. We love learning new things, solving challenges, and having fun.</p>
                  </Col>
                  <Col lg={5}>                                                               
                      <img src="../assets/images/pages/company.svg" className="w-100" alt="company" />                    
                  </Col>
              </Row>
              <Row className="text-light mt-5">
                <Col lg={6} md={6}>
                  <div className="">
                    <div class="sicon lg mb-3" data-aos="fade-down">
                      <img src="../assets/images/icons/vision.svg" alt="vision" />
                    </div>
                    <h2 className="mb-3">Our Vision</h2>
                    <p>We envision a world where software solutions enable people to work more efficiently, learn more effectively, and communicate more seamlessly. We aspire to be the leading provider of software products that enhance productivity, creativity, and collaboration across various domains and industries.</p>                 
                  </div>
                  </Col>
                  <Col lg={6} md={6}>
                  <div className="">
                    <div class="sicon lg mb-3" data-aos="fade-down">
                      <img src="../assets/images/icons/mission.svg" alt="mission" />
                    </div>
                    <h2 className="mb-3">Our Mission</h2>
                    <p>Our mission is to design and develop innovative, reliable, and user-friendly software products that meet the needs and expectations of our customers. We strive to deliver high-quality software solutions that empower our customers to achieve their personal and professional goals. We value customer satisfaction, continuous improvement, and social responsibility.</p>                 
                  </div>
                </Col>              
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Values />
          <Facts />
          <Footer />
    </div>
  );
};
export default Company;