import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const ProfessionalServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Professional Services</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Industries</li>
                      <li>Professional Services</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-4">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Our Solutions for Professional Services Industries</h2>
                        <p className="lead"><em>The professional services industry is facing increasing competition and disruption in the digital age. Professional services firms need to deliver value-added, outcome-based, and customer-centric services that differentiate them from their rivals. They also need to leverage innovative technologies that enable them to optimize their operations, improve their efficiency, and enhance their profitability.</em></p>
                        <p className="lead"><em>At LIFO, we offer software company solutions for professional services industries that help you achieve your business goals and overcome your challenges. Our solutions cover various aspects of the professional services value chain, such as:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Customer Relationship Management</h4>
                      <p>We provide customer relationship management (CRM) software that helps you manage your customer interactions, relationships, and loyalty. Our CRM software enables you to capture and analyze customer data, segment and target customers, generate leads and opportunities, manage sales pipelines and forecasts, and deliver personalized and consistent customer experiences.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Professional Services Automation</h4>
                      <p>We develop professional services automation (PSA) software that helps you manage your project lifecycle, from initiation to closure. Our PSA software allows you to plan and schedule projects, allocate and track resources, monitor and control costs, manage risks and issues, and ensure quality and compliance.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Enterprise Resource Planning</h4>
                      <p>We create enterprise resource planning (ERP) software that helps you manage your core business processes, such as finance, accounting, procurement, human resources, and payroll. Our ERP software integrates with other systems and applications, and provides real-time visibility and insights into your business performance.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Business Intelligence</h4>
                      <p>We design business intelligence (BI) software that helps you transform your data into actionable insights and informed decisions. Our BI software supports various data sources, formats, and models, and provides interactive dashboards, reports, and analytics.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">05</div>
                      <h4 className="mb-3">Cloud Computing</h4>
                      <p>We build cloud computing software that helps you access and use our solutions on-demand, over the internet. Our cloud computing software offers various benefits, such as scalability, flexibility, security, reliability, and cost-effectiveness.</p>          
                    </Col>
                    <Col lg={12} md={12} className="mt-5">
                      <p>LIFO Technologies Pvt. Ltd., is is a trusted partner for professional services industries across the globe. We have extensive experience and expertise in developing customized solutions that meet the specific needs and challenges of each industry segment, such as consulting, legal, accounting, engineering, and architecture. We use the latest technologies and best practices to ensure quality, reliability, and scalability of our solutions.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for software company solutions for professional services industries that can help you transform your professional services performance and competitiveness contact us today for a free consultation. We would love to hear from you!</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default ProfessionalServices;
