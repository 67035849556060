import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const OilGas = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Oil &amp; Gas</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Industries</li>
                      <li>Oil &amp; Gas</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-4">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Our Solutions for Oil and Gas Industries</h2>
                        <p className="lead"><em>The oil and gas industry is facing unprecedented challenges and opportunities in the digital age. Oil and gas companies need to explore and produce hydrocarbons in more complex and remote environments, while reducing costs, risks, and environmental impacts. They also need to leverage data, analytics, and technology to optimize their operations, improve their efficiency, and enhance their competitiveness.</em></p>
                        <p className="lead"><em>At LIFO, we offer software company solutions for oil and gas industries that help you achieve your business goals and overcome your challenges. Our solutions cover various aspects of the oil and gas value chain, such as:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Exploration Software</h4>
                      <p>We provide exploration software that helps you discover and evaluate new hydrocarbon resources. Our exploration software enables you to integrate and interpret geological, geophysical, petrophysical, and reservoir data, and create accurate subsurface models and simulations.</p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Drilling Software</h4>
                      <p>We develop drilling software that helps you plan and execute safe and efficient drilling operations. Our drilling software allows you to design and optimize well trajectories, monitor and control drilling parameters, manage drilling equipment and fluids, and prevent drilling hazards and incidents.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Production Software</h4>
                      <p>We create production software that helps you optimize your production performance and recovery. Our production software enables you to monitor and control well and surface facilities, manage production data and workflows, analyze reservoir behavior and performance, and implement enhanced oil recovery techniques.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Asset Management Software</h4>
                      <p>We design asset management software that helps you manage your oil and gas assets throughout their lifecycle. Our asset management software enables you to track and maintain your assets, manage asset integrity and reliability, comply with regulations and standards, and reduce asset downtime and costs.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">05</div>
                      <h4 className="mb-3">Digital Platform Software</h4>
                      <p>We build digital platform software that helps you connect and manage your data, applications, devices, and people across your oil and gas operations. Our digital platform software enables you to collect and store data from various sources, apply AI and ML to generate insights and actions, and create and deploy digital solutions. </p>          
                    </Col>
                    <Col lg={12} md={12} className="mt-5">
                      <p>LIFO Technologies Pvt. Ltd., is a trusted partner for oil and gas industries across the globe. We have extensive experience and expertise in developing customized solutions that meet the specific needs and challenges of each industry segment, such as conventional, unconventional, offshore, and onshore oil and gas. We use the latest technologies and best practices to ensure quality, reliability, and scalability of our solutions.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for software company solutions for oil and gas industries that can help you transform your oil and gas performance and competitiveness contact us today for a free consultation. We would love to hear from you!</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default OilGas;
