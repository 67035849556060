import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const InfrastructureServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Infrastructure Services</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>Infrastructure Services</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={8} md={12}>
                     <h2 className="mb-3">IT infrastructure with speed and agility.</h2>
                     <p className="lead"><em>We are a team of expert infrastructure service providers who help you design, implement, and manage your IT infrastructure with speed and agility.</em></p>          
                  </Col>
              </Row>
            </Container>
            <Container className="text-light mt-5">
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                      <h2 className="mb-3">What we do</h2>
                      <p className="lead"><em>We offer a range of infrastructure services to help you ensure the performance, functionality, and security of your IT infrastructure. Whether you need cloud infrastructure, network infrastructure, workplace infrastructure, or data infrastructure, we can help you achieve your goals.</em></p>
                      <p className="lead"><em>Our infrastructure services include:</em></p>                      
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h3 className="mb-3">Cloud Infrastructure</h3>
                      <p>We help you migrate, optimize, and manage your cloud infrastructure workloads to take full advantage of the cloud computing benefits. We offer various types of cloud services such as infrastructure as a service (IaaS), platform as a service (PaaS), software as a service (SaaS), and serverless computing. We also provide cloud security and cloud migration services to protect and accelerate your cloud journey.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h3 className="mb-3">Network Infrastructure</h3>
                      <p>We help you transform, integrate, and manage your network infrastructure to perform in the world of cloud and edge. We offer various types of network services such as network design, network optimization, network security, network monitoring, and network automation. We also provide network solutions for specific domains such as 5G, SD-WAN, IoT, and Wi-Fi.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h3 className="mb-3">Workplace Infra</h3>
                      <p>We help you elevate your workplace experience and maximize sustainable value and well-being by promoting a healthy, engaged, digitally savvy workforce. We offer various types of workplace services such as digital workplace design, digital workplace management, digital workplace security, digital workplace collaboration, and digital workplace support.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h3 className="mb-3">Data infrastructure</h3>
                      <p>We help you design and tune your data infrastructure to simplify integration, enhance performance, and accelerate return on investment in data technologies. We offer various types of data services such as database platforms, data warehouses, data lakes, data pipelines, data analytics, and data governance.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mb-5">
                      <h2 className="mb-3">Why choose us</h2>
                      <p className="lead"><em>We are not just service providers, we are solution partners. We care about your IT infrastructure and your business. We strive to provide infrastructure services that are:</em></p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/testing.svg" alt="testing" />
                      </div>
                      <h3 className="mb-3">Comprehensive</h3>
                      <p>We cover all aspects of infrastructure services from cloud to network to workplace to data. We ensure that your IT infrastructure is optimally designed, implemented, and managed.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/money-bag.svg" alt="money-bag" />
                      </div>
                      <h3 className="mb-3">Cost-Effective</h3>
                      <p>We use the best practices and tools to optimize the infrastructure services and reduce the cost and risk of IT failures. We help you save time and money by delivering high-quality infrastructure services.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/flexibility.svg" alt="flexibility" />
                      </div>
                      <h3 className="mb-3">Flexible</h3>
                      <p>We adapt to your needs and preferences. We can work with any IT development methodology, technology stack, or domain. We can work on-premise or on-cloud, as part of your team or independently.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/website.svg" alt="website" />
                      </div>
                      <h3 className="mb-3">Experienced</h3>
                      <p>We have a team of skilled and certified infrastructure service providers who have extensive experience in various types of infrastructure services across different industries and domains.</p>          
                    </Col>
                </Row>
                
                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for a skilled and certified infrastructure service providers who can help you achieve your business goals with custom software solutions, contact us today. We would love to hear from you and discuss how we can work together.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default InfrastructureServices;
