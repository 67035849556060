import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const CloudMigration = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Cloud Migration</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>Cloud Migration</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={12} md={12}>
                     <p className="lead"><em>Are you looking for a way to migrate your applications and data to the cloud?<br/>Do you want to enjoy the benefits of cloud computing, such as scalability, flexibility, security, and cost-efficiency?<br/>Do you need to minimize the risks and challenges of cloud migration, such as downtime, data loss, compatibility issues, and compliance requirements?</em></p>          
                  </Col>
              </Row>
            </Container>
            <Container className="text-light">
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                      <p className="lead"><em><span className="text-light">If you answered yes to any of these questions,</span> then you have come to the right place. We are a cloud migration service provider that can help you move your applications and data to the cloud smoothly and successfully.</em></p>
                      <p className="lead"><em>We offer a range of cloud migration services that include:</em></p>                      
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h3 className="mb-3">Cloud Assessment</h3>
                      <p>We can help you evaluate your current IT environment and determine the best cloud strategy and solution for your business needs. We can also help you estimate the costs and benefits of cloud migration and provide a roadmap and timeline for the project.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h3 className="mb-3">Cloud Migration</h3>
                      <p>We can help you migrate your applications and data to the cloud using proven tools and methodologies. We can also help you optimize your cloud performance, security, and reliability. We can migrate your applications and data to any cloud platform, such as AWS, Azure, Google Cloud, etc.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h3 className="mb-3">Cloud Management</h3>
                      <p>We can help you monitor and manage your cloud resources and operations. We can also help you troubleshoot and resolve any issues that may arise during or after the migration. We can also help you update and maintain your cloud applications and data.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h3 className="mb-3">Consulting &amp; Training</h3>
                      <p>We can help you design and implement your cloud architecture and governance. We can also help you comply with any regulatory and industry standards and best practices. We can also help you train your staff on how to use the cloud effectively.</p>          
                    </Col>
                </Row>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12} className="mt-4">
                      <p className="lead"><em>We have worked with clients from various industries and domains, such as healthcare, education, finance, retail, e-commerce, etc.<br/>We have delivered successful cloud migration projects that have helped our clients leverage the power of cloud computing.</em></p>          
                    </Col>
                </Row>
                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are interested in our cloud migration services, please contact us today for a free consultation. We would love to hear from you and discuss how we can help you with your cloud needs.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default CloudMigration;
