import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const AWS = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Amazon Web Services</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Platforms</li>
                      <li>Amazon Web Services</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Amazon Web Services Platform</h2>
                        <p className="lead"><em>Amazon Web Services (AWS) is a cloud computing platform that provides over 200 fully featured services from data centers globally. AWS enables millions of customers—including startups, enterprises, and government agencies—to lower costs, become more agile, and innovate faster. AWS offers a wide range of solutions for various scenarios and workloads, such as analytics, machine learning, serverless computing, storage, and more.</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-3">
                      <h2 className="mb-3">What Amazon Web Services Platform can do</h2>
                      <p className="lead"><em>Amazon Web Services Platform can enable various solutions that require scalability, security, reliability, and performance, such as:</em></p>                       
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Web Hosting</h4>
                      <p>Amazon Web Services Platform can help you host your websites and web applications on a flexible and cost-effective cloud platform, using services such as Amazon EC2, Amazon S3, Amazon CloudFront, and Amazon Lightsail.</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Data Analytics</h4>
                      <p>Amazon Web Services Platform can help you collect, store, process, and analyze large and complex data sets on a scalable and secure cloud platform, using services such as Amazon Kinesis, Amazon Redshift, Amazon EMR, and Amazon Athena.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Machine Learning</h4>
                      <p>Amazon Web Services Platform can help you build, train, and deploy machine learning models on a comprehensive and easy-to-use cloud platform, using services such as Amazon SageMaker, Amazon Comprehend, Amazon Rekognition, and Amazon Lex.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Internet of Things</h4>
                      <p>Amazon Web Services Platform can help you connect and manage your IoT devices and applications on a reliable and secure cloud platform, using services such as AWS IoT Core, AWS IoT Greengrass, AWS IoT Analytics, and AWS IoT Device Management.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Benefits of Amazon Web Services Platform</h2>
                      <p className="lead"><em>Amazon Web Services Platform offers many benefits for businesses and individuals, such as:</em></p> 
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/money-bag.svg" alt="" />
                      </div>
                      <h4 className="mb-3">Cost-effectiveness</h4>
                      <p>Amazon Web Services Platform can help you reduce the expenses and risks associated with maintaining multiple systems and solutions by providing a pay-as-you-go pricing model that only charges you for the resources you use.</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/access-control.svg" alt="" />
                      </div>
                      <h4 className="mb-3">Accessibility</h4>
                      <p>Amazon Web Services Platform can help you access your data and applications from anywhere, anytime, and from any device by providing a global cloud platform that supports hybrid and multi-cloud scenarios.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/vision.svg" alt="" />
                      </div>
                      <h4 className="mb-3">Creativity</h4>
                      <p>Amazon Web Services Platform can help you stimulate imagination and innovation by allowing you to create and explore new solutions using low-code or no-code tools that empower everyone to be a developer.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/helping.svg" alt="" />
                      </div>
                      <h4 className="mb-3">Empathy</h4>
                      <p>Amazon Web Services Platform can help you foster understanding and compassion by allowing you to create inclusive and accessible solutions that support diversity and social impact.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Types of Amazon Web Services Platform</h2>
                      <p className="lead"><em>There are different types of Amazon Web Services Platform based on the level of functionality and complexity they provide:</em></p> 
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Infrastructure as a Service (IaaS)</h4>
                      <p>This is the type of Amazon Web Services Platform that provides basic computing resources such as servers, storage, networking, and databases on demand. The user has full control over the configuration and management of these resources. Examples of IaaS services include Amazon EC2 , Amazon S3 , Amazon VPC , and Amazon RDS .</p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Platform as a Service (PaaS)</h4>
                      <p>This is the type of Amazon Web Services Platform that provides higher-level services such as application development, deployment, and management on top of the underlying infrastructure. The user has less control over the configuration and management of these services. Examples of PaaS services include AWS Elastic Beanstalk, AWS Lambda, AWS Fargate, and AWS Amplify.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Software as a Service (SaaS)</h4>
                      <p>This is the type of Amazon Web Services Platform that provides ready-to-use software applications that run on the cloud platform. The user has no control over the configuration and management of these applications. Examples of SaaS services include Amazon WorkSpaces, Amazon Chime, Amazon Connect, and AWS Marketplace.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you're interested in learning more about our amazon web services platform or want to get started with a free trial, please contact us today. We'll be happy to assist you with any questions or concerns you may have.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default AWS;
