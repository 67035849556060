import { useEffect,useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FontAwesome from 'react-fontawesome';
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import {postContactUs} from "../services/serviceapi";
const ContactUs = () => 
{
  const [first_name,setFirstName] = useState("");
  const [last_name,setLastName] = useState("");
  const [company_name,setCompanyName] = useState("");
  const [email,setEmail] = useState("");
  const [interested_in,setInterestedIn] = useState("");
  const [hearAboutUs,setHearAboutUs] = useState("");
  const [message,setMessage] = useState("");
  const [random,setRandom] = useState(0);
  const [captcha,setCaptcha] = useState("");
  const [submitMessage,setContactSubmit] = useState("");
  const defData = {first_name:'',last_name:'',company_name:'',email:'',interested_in:'',hearAboutUs:'',message:'',captcha:''};
  const [formErrors,formError] = useState(defData);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const onRefresh= (e) => {
    setRandom(Math.random()*8000);
  }

  const onChange = (e) => {
    const eTarget = e.target;
    switch(eTarget.name) 
    {
      case 'first_name':
        setFirstName(eTarget.value)
      break;
      case 'last_name':
        setLastName(eTarget.value)
      break;
      case 'company_name':
        setCompanyName(eTarget.value)
      break;
      case 'email':
        setEmail(eTarget.value)
      break;
      case 'message':
        setMessage(eTarget.value)
      break;
      case 'captcha':
        setCaptcha(eTarget.value)
      break;
      default:
      break;
    }
  }
  const onChangeInterestedIn = (e)=>{
    const eTarget = e.target;
    if(eTarget.value !==''){
      setInterestedIn(eTarget.value);
    }else{
      setInterestedIn('');
    }
  }
  const onChangeHearAboutUs = (e)=>{
    const eTarget = e.target;
    if(eTarget.value !==''){
      setHearAboutUs(eTarget.value);
    }else{
      setHearAboutUs('');
    }
  }
  const onSubmit = (e) => {
    const process = 1;
    const error = {}
    if(first_name ==='')
      error.first_name = 'Enter first name'
    //if(last_name ==='') error.last_name = 'Enter last name'
    if(company_name ==='')
      error.company_name = 'Enter company name'
    if(email==="")
      error.email = 'Enter email'  
    else if( email!=="" && !isValidEmail(email))
      error.email = 'Please enter valid email';
    if(interested_in ==='')
      error.interested_in = 'Please select you are interested in';
    if(captcha ==='')
      error.captcha = 'Enter captcha'
    
    if(Object.keys(error).length === 0)
    {
      setContactSubmit(-1);
      postContactUs(first_name,last_name,company_name,email,interested_in,hearAboutUs,message,captcha,process)
      .then(response => {
        if(response.status=== 'success') {
          // setShowEnquiry(true);
          setFirstName("");
          setLastName("");
          setCompanyName("");
          setEmail("");
          setInterestedIn("");
          setHearAboutUs("");
          setMessage("");
        }
        setCaptcha('');
        onRefresh();
        setContactSubmit(response.message);
      
      })
    }
    formError(error)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
         
          <section className="section inner-banner">
            <Container>
              <Row className="text-light">             
                  <Col lg={9} md={12}>
                    <h2 className="mb-3">We're situated in <br/> <strong>Different Countries</strong></h2>
                    <p className="mb-5 sub-title">With a clear-cut understanding of the corporate world,<br/>we are dedicated to provide our clients with cutting edge solutions and develop innovative business strategies for them.</p>
                    <Row className="text-light">             
                      <Col lg={4} md={6}>
                        <div className="address">
                          <h4><img src="assets/images/country/india.svg" alt="india icon" /> India</h4>
                          <h6>Mr. Nandakumar Mohan,</h6>
                          <p>12/7, 1st Main Road, SIDCO Nagar,
                          Villivakkam, Chennai - 600049.<br/><br/>
                          <Link to="mailto:nandakumar@lifotechnologies.com"><FontAwesome name="envelope"/> nandakumar@lifotechnologies.com</Link><br/>
                          <Link to="call:+91 98420 19111"><FontAwesome name="phone"/> +91 98420 19111</Link>
                          </p>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="address">
                          <h4><img src="assets/images/country/united-states.svg" alt="united-states icon" /> USA</h4>
                          <h6>Mr. Jerris,</h6>
                          <p>267 Kentlands Blvd, Suite 5027,<br/>Gaithersburg, MD 20878, USA<br/><br/>
                          <Link to="mailto:jerris@lifotechnologies.com"><FontAwesome name="envelope"/> jerris@lifotechnologies.com</Link><br/>
                          <Link to="call:(301) 703-3047"><FontAwesome name="phone"/> (301) 703-3047</Link>
                          </p>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="address">
                          <h4><img src="assets/images/country/switzerland.svg" alt="switzerland icon" /> Switzerland</h4>
                          <h6>Mr. Urs Sennhauser,</h6>
                          <p>Postfach 553,<br/>8853 Lachen SZ.<br/><br/>
                          <Link to="mailto:urs@lifotechnologies.com"><FontAwesome name="envelope"/> urs@lifotechnologies.com</Link><br/>
                          <Link to="call:41 786441065"><FontAwesome name="phone"/> +41 786441065</Link>
                          </p>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="address">
                          <h4><img src="assets/images/country/united-kingdom.svg" alt="united-kingdom icon" /> United Kingdom</h4>
                          <h6>Mr. Abhishek Nakhate,</h6>
                          <p>82, Burgundy Drive,<br/>Hempstead, HP27DB<br/><br/>
                          <Link to="mailto:abhishek@lifotechnologies.com"><FontAwesome name="envelope"/> abhishek@lifotechnologies.com</Link><br/>
                          <Link to="call:20 30027947"><FontAwesome name="phone"/> +20 30027947</Link>
                          </p>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="address">
                          <h4><img src="assets/images/country/germany.svg" alt="germany icon" /> Germany</h4>
                          <h6>Mr. Varghese Rajesh,</h6>
                          <p>Knigstrasse 52-54,<br/>47798 Krefeld - Germany.<br/><br/>
                          <Link to="mailto:rajesh@lifotechnologies.com"><FontAwesome name="envelope"/> rajesh@lifotechnologies.com</Link><br/>
                          <Link to="call:+0049 176 47098263"><FontAwesome name="phone"/> +0049 176 47098263</Link>
                          </p>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="address">
                          <h4><img src="assets/images/country/denmark.svg" alt="denmark icon" /> Denmark</h4>
                          <h6>Mr. Kannathasan,</h6>
                          <p>Vigerslevvej 113,<br/>2500 Valby.<br/><br/>
                          <Link to="mailto:kannathasan@lifotechnologies.com"><FontAwesome name="envelope"/> kannathasan@lifotechnologies.com</Link><br/>
                          <Link to="call:+45 52527575"><FontAwesome name="phone"/> +45 52527575</Link>
                          </p>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="address">
                          <h4><img src="assets/images/country/australia.svg" alt="australia icon" /> Australia</h4>
                          <h6>Mr. Madan,</h6>
                          <p>UNE SMART RI<br/>ARMDALE NSW 2350<br/><br/>
                          <Link to="mailto:madhan@lifotechnologies.com"><FontAwesome name="envelope"/> madhan@lifotechnologies.com</Link><br/>
                          <Link to="call:+61 434499831"><FontAwesome name="phone"/> +61 434499831</Link>
                          </p>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="address">
                          <h4><img src="assets/images/country/singapore.svg" alt="singapore icon" /> Singapore</h4>
                          <h6>Mr. Gobi,</h6>
                          <p>Blk 71, Ayer Rajah Crescent,<br/>#07-10, S 139951<br/><br/>
                          <Link to="mailto:gobi@lifotechnologies.com"><FontAwesome name="envelope"/> gobi@lifotechnologies.com</Link><br/>
                          <Link to="call:+65 90667554"><FontAwesome name="phone"/> +65 90667554</Link>
                          </p>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="address">
                          <h4><img src="assets/images/country/bahrain.svg" alt="bahrain icon" /> Bahrain</h4>
                          <h6>Ms. Amatul,</h6>
                          <p>P.O. Box 2738, Office 12, Building 1207, Road 3928, Block 939 East Riffa<br/><br/>
                          <Link to="mailto:amatul@lifotechnologies.com"><FontAwesome name="envelope"/> amatul@lifotechnologies.com</Link><br/>
                          <Link to="call:+973 38444010"><FontAwesome name="phone"/> +973 38444010</Link>
                          </p>
                        </div>
                      </Col>
                    </Row>

                  </Col>
                  <Col lg={3} md={12}>
                    <h2 className="mb-3">How can <br/> <strong>we help you?</strong></h2>
                    <p className="mb-5 sub-title">Thank you for your interest in LIFO.<br/>Please use this form to contact us.</p>
                    <Form className="contact-form">
                      <Form.Group className="mb-3 form-group" controlId="contactForm.FirstName">
                        <Form.Label>First Name <span className="req">*</span></Form.Label>
                        <Form.Control type="text" onChange = {onChange} maxLength="40" name="first_name" value={first_name} placeholder="" required="" />
                        {formErrors.first_name!=='' && formErrors.first_name!==undefined && (<Form.Text className='error text-danger'>{formErrors.first_name}</Form.Text>)}
                        {/* <p className="error">Please complete this required field.</p> */}
                      </Form.Group>

                      <Form.Group className="mb-3 form-group" controlId="contactForm.LastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" onChange = {onChange} maxLength="40" name="last_name" value={last_name} placeholder=""/>
                        {/* {formErrors.last_name!=='' && formErrors.last_name!==undefined && (<Form.Text className='error text-danger'>{formErrors.last_name}</Form.Text>)} */}
                      </Form.Group>

                      <Form.Group className="mb-3 form-group" controlId="contactForm.CompanyName">
                        <Form.Label>Company Name <span className="req">*</span></Form.Label>
                        <Form.Control type="text" onChange = {onChange} maxLength="50" name="company_name" value={company_name} placeholder="" required="" />
                        {formErrors.company_name!=='' && formErrors.company_name!==undefined && (<Form.Text className='error text-danger'>{formErrors.company_name}</Form.Text>)}
                        {/* <p className="error">Please complete this required field.</p> */}
                      </Form.Group>

                      <Form.Group className="mb-3 form-group" controlId="contactForm.Email">
                        <Form.Label>Email Id <span className="req">*</span></Form.Label>
                        <Form.Control type="email" onChange = {onChange} maxLength="40" name="email" value={email} placeholder="" required="" />
                        {formErrors.email!=='' && formErrors.email!==undefined && (<Form.Text className='error text-danger'>{formErrors.email}</Form.Text>)}
                        {/* <p className="error">Please complete this required field.</p> */}
                      </Form.Group>

                      <Form.Group className="mb-3 form-group" controlId="contactForm.Interested">
                        <Form.Label>I'm interested in: <span className="req">*</span></Form.Label>
                        <Form.Select aria-label="I'm interested in"  onChange = {onChangeInterestedIn} value={interested_in}>
                          <option value=''>Please Select</option>
                          <option value="Digitize My Business">Digitize My Business</option>
                          <option value="LPG Vending Machine">LPG Vending Machine</option>
                          <option value="Asset Tracking Solutions">Asset Tracking Solutions</option>
                          <option value="Website Development">Website Development</option>
                          <option value="Application Development">Application Development</option>
                          <option value="AI & IoT Solutions">AI & IoT Solutions</option>
                          <option value="Cloud Migration">Cloud Migration</option>
                          <option value="Cybersecurity">Cybersecurity</option>
                          <option value="Staffing Solution">Staffing Solution</option>
                          <option value="Custom E-Commerce">Custom E-Commerce</option>
                          <option value="Animation (2D/3D)">Animation (2D/3D)</option>
                          <option value="Something else">Something else</option>
                        </Form.Select>
                        {formErrors.interested_in!=='' && formErrors.interested_in!==undefined && (<Form.Text className='error text-danger '>{formErrors.interested_in}</Form.Text>)}
                        {/* <p className="error">Please complete this required field.</p> */}
                      </Form.Group>

                      <Form.Group className="mb-3 form-group" controlId="contactForm.Heared" >
                        <Form.Label>Where did you hear about us?</Form.Label>
                        <Form.Select aria-label="Where did you hear about us?" onChange = {onChangeHearAboutUs} value={hearAboutUs}>
                          <option value="">Please Select</option>
                          <option value="From a colleague or friend">From a colleague or friend</option>
                          <option value="Press article">Press article</option>
                          <option value="Online search">Online search</option>
                          <option value="Advertisement">Advertisement</option>
                          <option value="Event">Event</option>
                          <option value="Something else">Something else</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-3 form-group" controlId="exampleForm.Message" >
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" name="message" value={message} onChange={onChange} rows={4} />
                      </Form.Group>

                      <Row className="captcha-code">             
                        <Col sm={4} xs={4} md={4} lg={7} className="first-child">
                          <Form.Group className="mb-3 form-group" controlId="contactForm.LastName">
                            <Form.Label>Captcha</Form.Label>
                            <p>
                              <img alt="Captcha" src={`https://www.lifotechnologies.com/captcha?rand=${random}`} id='captchaimg' />&nbsp;
                              <svg onClick={onRefresh} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"  fill="none" stroke="#eee" strokeWidth="2" strokeLinecap="round"  strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" /><path d="M20 4v5h-5" /></svg>
                            </p>
                          </Form.Group>
                        </Col>
                        <Col className="last-child">
                          <Form.Group className="mb-3 form-group" controlId="contactForm.LastName">
                            <Form.Label></Form.Label>
                            <Form.Control type="text" onChange={onChange} maxLength="6" name="captcha" value={captcha} placeholder=""/>
                            {formErrors.captcha!=='' && formErrors.captcha!==undefined && (<Form.Text className='error text-danger'>{formErrors.captcha}</Form.Text>)}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Form.Group className="">
                        <div className="d-grid">
                          <Button variant="primary" size="md" onClick={(e)=>onSubmit()} disabled={submitMessage && submitMessage===-1}>Submit</Button>
                        </div>
                      </Form.Group>
                      {submitMessage && submitMessage!==-1 && (<div dangerouslySetInnerHTML={{__html:submitMessage}}></div>)}
                    </Form>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Footer />
    </div>
  );
};

export default ContactUs;
