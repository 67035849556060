import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const Retail = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Retail</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Industries</li>
                      <li>Retail</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-4">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Our Solutions for Retail Industries</h2>
                        <p className="lead"><em>The retail industry is undergoing a digital transformation that requires innovative solutions to meet the changing needs of customers, employees, and partners. Retailers need to deliver personalized, seamless, and omnichannel shopping experiences that drive loyalty and revenue. They also need to leverage data, analytics, and technology to optimize their operations, empower their workforce, and innovate their products and services.</em></p>
                        <p className="lead"><em>At LIFO, we offer software company solutions for retail industries that help you achieve your business goals and overcome your challenges. Our solutions cover various aspects of the retail value chain, such as:</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={6} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">E-commerce Platforms</h4>
                      <p>We provide e-commerce platforms that help you create and manage your online store and sell your products and services across multiple channels and devices. Our e-commerce platforms enable you to design and customize your website or app, integrate with payment gateways and shipping providers, manage inventory and orders, and analyze customer behavior and feedback.</p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Retail Management Software</h4>
                      <p>We develop retail management software that helps you manage your core retail processes, such as merchandising, pricing, promotions, loyalty, customer service, and store operations. Our retail management software allows you to automate workflows, synchronize data, comply with regulations, and generate reports and insights.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Retail Inventory Management Software</h4>
                      <p>We create retail inventory management software that helps you track and control your inventory levels, locations, and movements. Our retail inventory management software enables you to reduce inventory costs, avoid stockouts and overstocks, and improve inventory accuracy and visibility.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Social Media Marketing</h4>
                      <p>We design social media marketing software that helps you promote your brand, products, and services on various social media platforms. Our social media marketing software enables you to create and share engaging content, target and reach your audience, monitor and measure your performance, and interact with your customers and prospects.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">05</div>
                      <h4 className="mb-3">Omnichannel Retail Software</h4>
                      <p>We build omnichannel retail software that helps you provide a consistent and seamless shopping experience across all touchpoints. Our omnichannel retail software enables you to connect your online and offline channels, offer multiple delivery and pickup options, provide unified customer service, and collect and analyze customer data.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">06</div>
                      <h4 className="mb-3">Metrics & Analytics Solutions</h4>
                      <p>We offer metrics & analytics solutions that help you leverage your data to gain insights and make better decisions. Our metrics & analytics solutions enable you to collect and integrate data from various sources, apply advanced analytics techniques such as AI and ML, visualize and explore data using dashboards and charts, and generate actionable recommendations.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="nos">07</div>
                      <h4 className="mb-3">Customer Relationship Management Tools</h4>
                      <p>We deliver customer relationship management (CRM) tools that help you manage your customer interactions, relationships, and loyalty. Our CRM tools enable you to capture and track leads, manage contacts and accounts, schedule appointments and follow-ups, send marketing campaigns and communications, and generate reports and insights.</p>          
                    </Col>
                    <Col lg={12} md={12} className="mt-5">
                      <p>LIFO Technologies Pvt. Ltd., is a trusted partner for retail industries across the globe. We have extensive experience and expertise in developing customized solutions that meet the specific needs and challenges of each industry segment, such as fashion, grocery, electronics, and health and beauty. We use the latest technologies and best practices to ensure quality, reliability, and scalability of our solutions.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for software company solutions for retail industries that can help you transform your retail performance and competitiveness contact us today for a free consultation. We would love to hear from you!</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default Retail;
