import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const Microsoft = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>Microsoft</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Platforms</li>
                      <li>Microsoft</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">              
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                        <h2 className="mb-3">Microsoft Platform</h2>
                        <p className="lead"><em>Microsoft Platform is a set of integrated cloud services and tools that empower everyone at your organization to innovate and collaborate with data and AI. Microsoft Platform includes Microsoft 365, Dynamics 365, Azure, and Power Platform, as well as hundreds of other apps and connectors.</em></p>
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-3">
                      <h2 className="mb-3">What Microsoft Platform can do</h2>
                      <p className="lead"><em>Microsoft Platform can enable various solutions that require productivity, security, intelligence, and efficiency, such as:</em></p>                       
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">01</div>
                      <h4 className="mb-3">Collaboration</h4>
                      <p>Microsoft Platform can help your teams work together seamlessly and securely across devices, locations, and platforms, using tools such as Microsoft Teams, SharePoint, OneDrive, and Outlook.</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">02</div>
                      <h4 className="mb-3">Business Applications</h4>
                      <p>Microsoft Platform can help you manage and optimize your business processes and operations, using tools such as Dynamics 365, Power Apps, Power Automate, and Power Virtual Agents.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">03</div>
                      <h4 className="mb-3">Cloud Computing</h4>
                      <p>Microsoft Platform can help you build, deploy, and scale your applications and services on a trusted and flexible cloud platform, using tools such as Azure App Service, Azure Functions, Azure SQL Database, and Azure DevOps.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="nos">04</div>
                      <h4 className="mb-3">Data and AI</h4>
                      <p>Microsoft Platform can help you analyze and visualize your data, create intelligent insights and actions, and enable conversational AI experiences, using tools such as Power BI, Azure Synapse Analytics, Azure Cognitive Services, and Azure Bot Service.</p>          
                    </Col>
                </Row>
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Benefits of Microsoft Platform</h2>
                      <p className="lead"><em>Microsoft Platform offers many benefits for businesses and individuals, such as:</em></p> 
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/money-bag.svg" alt="" />
                      </div>
                      <h4 className="mb-3">Cost-effectiveness</h4>
                      <p>Microsoft Platform can help you reduce the expenses and risks associated with maintaining multiple systems and solutions by providing a unified and scalable platform that meets your needs.</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/access-control.svg" alt="" />
                      </div>
                      <h4 className="mb-3">Accessibility</h4>
                      <p>Microsoft Platform can help you access your data and applications from anywhere, anytime, and from any device by providing a secure and reliable cloud platform that supports hybrid and multi-cloud scenarios.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/vision.svg" alt="" />
                      </div>
                      <h4 className="mb-3">Creativity</h4>
                      <p>Microsoft Platform can help you stimulate imagination and innovation by allowing you to create and explore new solutions using low-code or no-code tools that empower everyone to be a developer.</p>          
                    </Col>
                    <Col lg={3} md={6}>
                      <div class="sicon lg mb-3" data-aos="fade-down">
                        <img src="../assets/images/icons/helping.svg" alt="" />
                      </div>
                      <h4 className="mb-3">Empathy</h4>
                      <p>Microsoft Platform can help you foster understanding and compassion by allowing you to create inclusive and accessible solutions that support diversity and social impact.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light">
                    <Col lg={12} md={12} className="mb-4">
                      <h2 className="mb-3">Types of Microsoft Platform</h2>
                      <p className="lead"><em>There are different types of Microsoft Platform based on the level of functionality and complexity they provide:</em></p> 
                    </Col>
                    <Col lg={6} md={6}>
                      <div class="mb-3 mt-3" data-aos="fade-down">
                        <img src="../assets/images/pages/partners/microsoft365.png" className="w-50" alt="" />
                      </div>
                      <h4 className="mb-3">Microsoft 365</h4>
                      <p>This is the type of Microsoft Platform that provides cloud-based productivity apps and services for personal and professional use. Microsoft 365 includes apps such as Word, Excel, PowerPoint, Outlook, OneNote, OneDrive, Teams, SharePoint, Yammer, Stream, Sway, Forms, Planner, To Do, Whiteboard, Visio, Project, Publisher, Access, Skype for Business, Exchange Online, SharePoint Online, OneDrive for Business, Power Apps for Office 365, Power Automate for Office 365, Power BI for Office 365, Power Virtual Agents for Office 365.</p>
                    </Col>
                    <Col lg={6} md={6}>
                      <div class="mb-3 mt-3" data-aos="fade-down">
                        <img src="../assets/images/pages/partners/dynamics365.png" className="w-50" alt="" />
                      </div>
                      <h4 className="mb-3">Dynamics 365</h4>
                      <p>This is the type of Microsoft Platform that provides cloud-based business applications and services for various industries and functions. Dynamics 365 includes apps such as Dynamics 365 Sales, Dynamics 365 Marketing, Dynamics 365 Customer Service, Dynamics 365 Field Service, Dynamics 365 Finance, Dynamics 365 Supply Chain Management, Dynamics 365 Commerce, Dynamics 365 Human Resources, Dynamics 365 Project Operations, Dynamics 365 Business Central.</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div class="mb-3 mt-3" data-aos="fade-down">
                        <img src="../assets/images/pages/partners/azure.png" className="w-50" alt="" />
                      </div>
                      <h4 className="mb-3">Azure</h4>
                      <p>This is the type of Microsoft Platform that provides cloud-based computing services and solutions for various scenarios and workloads. Azure includes services such as Azure App Service , Azure Functions , Azure Logic Apps , Azure SQL Database , Azure Cosmos DB , Azure Synapse Analytics , Azure Data Factory , Azure Databricks , Azure Machine Learning , Azure Cognitive Services , Azure Bot Service , Azure IoT Hub , Azure IoT Central , Azure Sphere .</p>          
                    </Col>
                    <Col lg={6} md={6}>
                      <div class="mb-3 mt-3" data-aos="fade-down">
                        <img src="../assets/images/pages/partners/power-platform.png" className="w-50" alt="" />
                      </div>
                      <h4 className="mb-3">Power Platform</h4>
                      <p>This is the type of Microsoft Platform that provides cloud-based low-code or no-code tools for building solutions that analyze data, automate processes, create apps, or enable conversational AI. Power Platform includes tools such as Power BI, Power Apps (including Power Pages ), Power Automate (including Robotic Process Automation ), Power Virtual Agents.</p>          
                    </Col>
                </Row>                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you're interested in learning more about our microsoft platform or want to get started with a free trial, please contact us today. We'll be happy to assist you with any questions or concerns you may have.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default Microsoft;
