import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";
const UIDesign = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
          <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={7} md={8}>
                    <h1>UI/UX Design</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Services</li>
                      <li>UI/UX Design</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container className="text-light">
              <Row className="text-light">             
                  <Col lg={8} md={12}>
                     <h2 className="mb-3">Engaging and User-friendly Interfaces</h2>
                     <p className="lead"><em>We are a team of passionate UI/UX designers who create beautiful and functional digital products that delight users and solve problems.</em></p>          
                  </Col>
              </Row>
            </Container>
            <Container className="mt-5">
                <Row className="text-light mt-4">
                      <h2 className="mb-3">What we do</h2>
                      <p className="lead"><em>We offer a range of UI/UX design services to help you create engaging and user-friendly websites, apps, and interfaces. Whether you need a new design from scratch, a redesign of an existing product, or a usability audit, we can help you achieve your goals.</em></p>
                      <p className="lead"><em>Our UI/UX design process includes:</em></p>
                      <div class="sicon lg">
                          <img src="../assets/images/icons/vision.svg" alt="vision" className="aos-init aos-animate" data-aos="fade-down" />
                      </div>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={12} md={12}>                     
                      <div class="timeline">
                          <div class="outer">
                              <div class="tcard">
                                  <div class="info">
                                      <h3 class="title">Research</h3>
                                      <p>We conduct user research to understand your target audience, their needs, preferences, and pain points. We also analyze your competitors and industry trends to identify opportunities and best practices.</p>
                                  </div>
                              </div>
                              <div class="tcard">
                                  <div class="info">
                                      <h3 class="title">Strategy</h3>
                                      <p>We define the scope, objectives, and requirements of your project. We create user personas, user journeys, and user stories to guide the design decisions and align them with your business goals.</p>
                                  </div>
                              </div>
                              <div class="tcard">
                                  <div class="info">
                                      <h3 class="title">Design</h3>
                                      <p>We create wireframes, mockups, and prototypes to visualize the structure, layout, and functionality of your product. We use the latest tools and technologies to ensure high-quality and responsive design across different devices and platforms.</p>
                                  </div>
                              </div>
                              <div class="tcard">
                                  <div class="info">
                                      <h3 class="title">Testing</h3>
                                      <p>We validate our design solutions with real users through usability testing, feedback sessions, and analytics. We iterate and refine the design based on the results and insights.</p>
                                  </div>
                              </div>
                              <div class="tcard">
                                  <div class="info">
                                      <h3 class="title">Delivery</h3>
                                      <p>We deliver the final design assets and documentation to you or your development team. We also provide support and maintenance to ensure the smooth launch and operation of your product.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </Col>
                </Row>
                <div class="sicon lg">
                    <img src="../assets/images/icons/delivery.svg" alt="delivery" className="aos-init aos-animate" data-aos="fade-down"/>
                </div>
                <div className="line space"></div>
                <Row className="text-light mb-5">
                    <Col lg={12} md={12}>
                      <h2 className="mb-3">Why choose us</h2>
                      <p className="lead"><em>We are not just designers, we are problem solvers. We care about your users and your business. We strive to create design solutions that are:</em></p>          
                    </Col>
                </Row>
                <Row className="text-light">
                    <Col lg={4} md={6}>
                      <div class="sicon lg mb-3"><img src="../assets/images/icons/helping.svg" alt="helping" className="aos-init aos-animate" data-aos="fade-down"/></div>
                      <h3 className="mb-3">User-centric</h3>
                      <p>We put the user at the heart of everything we do. We design for their needs, goals, and emotions. We create intuitive and enjoyable experiences that increase user satisfaction and loyalty.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div class="sicon lg mb-3"><img src="../assets/images/icons/line-chart.svg" alt="line-chart" className="aos-init aos-animate" data-aos="fade-down"/></div>
                      <h3 className="mb-3">Business-oriented</h3>
                      <p>We understand your business challenges and opportunities. We design for your success. We create effective and efficient solutions that increase conversions, retention, and revenue.</p>          
                    </Col>
                    <Col lg={4} md={6}>
                      <div class="sicon lg mb-3"><img src="../assets/images/icons/innovation.svg" alt="innovation" className="aos-init aos-animate" data-aos="fade-down"/></div>
                      <h3 className="mb-3">Innovative</h3>
                      <p>We are always learning and exploring new ideas. We design for the future. We create unique and memorable solutions that stand out from the crowd and give you a competitive edge.</p>          
                    </Col>
                </Row>
                
                <div className="line space"></div>
                <Row className="text-light justify-content-center">             
                  <Col lg={8} md={12} className="text-center">
                     <h2 className="mb-3">Ready to Start?</h2>
                     <p className="lead"><em>If you are looking for a reliable and professional UI/UX Design partner who can help you achieve your business goals with custom software solutions, contact us today. We would love to hear from you and discuss how we can work together.</em></p>          
                     <Button href="/contact-us" variant="primary" className="col" >Contact Us</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>
          <Facts />
          <Footer />
    </div>
  );
};

export default UIDesign;
